import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import Icon from './Icon';

interface Props {
    color?: string;
    icon?: IconDefinition;
    modalId: string;
    handleClick: () => void;
    value: string;
    size?: string;
}

export default function ModalToggleButton(props: Props) {
    return (
        <button
            data-bs-toggle="modal"
            data-bs-target={props.modalId}
            onClick={props.handleClick}
            type="button"
            className={`btn btn-${props.size ?? 'md'} btn-${props.color ?? 'warning'} fw-bold m-1`}>
            {!!props.icon && <Icon icon={props.icon} />} {props.value}
        </button>
    );
}
