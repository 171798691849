import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton } from '../../Shared/components/NavigationButton';
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { reportMapping } from './reportMapping';

export const Reporting = () => {
    const [isProcessing] = useState(false);
    const [selected, setSelected] = useState(() => {
        const value = localStorage.getItem(StorageKeys.REPORT_TYPE);
        return value ? JSON.parse(value) : '0';
    });

    const handleSelect = (e: any) => {
        localStorage.setItem(StorageKeys.REPORT_TYPE, JSON.stringify(e.target.value));
        setSelected(e.target.value);
    }

    return (
        <div className="container app-container">
            <div className="d-flex-jcb">
                <div className="h4 text-dark">Reports</div>
                <div className="d-flex-jce">
                    <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
                </div>
            </div>
            {isProcessing && <Loading loadingText="Processing..." position="center" />}
            <div className="mt-3">
                <div className="col-sm-12">
                    <select value={selected} className="form-select fs-6" aria-label="Default select" onChange={handleSelect}>
                        <option value="0">Select Report</option>
                        <option value="1">Stock Balance Summary - Products</option>
                        <option value="2">Daily Loading Analysis</option>
                        <option value="3">Product Importation</option>
                        <option value="4">Sales By Importer</option>
                        <option value="5">Sales By Tank</option>
                        <option value="6">Overnight Differences</option>
                        <option value="7">Loading Differences</option>
                        <option value="8">Product Movement</option>
                    </select>
                </div>
                <div className="col-sm-12 reporting-container">
                    <div>{reportMapping[selected]}</div>
                </div>
            </div>
            <div className="page-action-button-group">
                <BackButton disabled={isProcessing} />
            </div>
        </div>
    )
}
