import { IProductDetailDto, IPutProductDto } from 'appDtos';
import React from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import HeadingElement from '../../../Shared/components/HeadingElement';
import { SubmitButton } from '../../../Shared/components/NavigationButton';
import { hasValue } from '../../../Shared/components/validators';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

export function EditProduct(props: { product: IProductDetailDto, setMode: (mode: Mode) => void }) {
    const axios = useApi();
    const { setError, errorMessage } = useApiHandleError();
    const {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        formData,
        isProcessing,
        hasError } = useFormProcessor<IPutProductDto>({ ...props.product }, { name: false, unitOfMeasure: false, categoryId: false });

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            await axios.put('Product', { product: formData });
            props.setMode(Mode.DETAIL);
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    return (
        <div className="">
            <HeadingElement text="Edit Product" />
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            <form className="p-3">
                <ControlInputField
                    onChange={handleOnChange}
                    autoComplete="off"
                    onFieldError={handleFieldError}
                    label="Product Name"
                    placeholder="Enter prouct's name."
                    id="name"
                    value={formData.name}
                    name="name"
                    type="text"
                    required={true}
                    validationFunction={hasValue} />
                <ControlInputField
                    onChange={handleOnChange}
                    autoComplete="off"
                    onFieldError={handleFieldError}
                    label="Unit of measure"
                    placeholder="Enter unit of measure."
                    id="unitOfMeasure"
                    value={formData.unitOfMeasure}
                    name="unitOfMeasure"
                    type="text"
                    required={true}
                    validationFunction={hasValue} />
            </form>
            <div className="page-action-button-group">
                <button onClick={() => props.setMode(Mode.DETAIL)} className="btn btn-secondary fw-bold m-1">Cancel</button>
                <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing || hasError} />
            </div>
        </div>
    );
}


