import React from 'react';

import { ChangePasswordRequest, ChangePasswordResponse } from 'appDtos';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { BackButton } from '../../../Shared/components/NavigationButton';
import { hasValue, isValidPassword } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import PasswordRules from './PasswordRules';
import './passwordForgetEmailSubmission.css';

export default function ChangePassword() {
    const axios = useApi();
    const [message, setResponseMessage] = React.useState('');
    const [show, setShow] = React.useState(false);
    const { errorMessage, setError } = useApiHandleError();
    const {
        handleOnChange,
        setIsProcessing,
        handleFieldError,
        hasError,
        formData,
        isProcessing,
    } = useFormProcessor<ChangePasswordRequest>({ currentPassword: '', newPassword: '', confirmPassword: '' });

    React.useEffect(() => setError(''), [formData, setError]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        try {
            const result = await axios.post<ChangePasswordResponse>('Account/ChangePassword', formData);
            if (result?.data) {
                setResponseMessage(result.data.message);
                handleOnChange({ email: '' });
            }
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    }

    const handleEmailChange = (value: any) => {
        if (message) {
            setResponseMessage('');
        }
        if (errorMessage) {
            setError('');
        }
        handleOnChange(value);
    }

    const toggleShow = () => setShow(!show);

    return (
        <div className='forgot-email-form mx-auto'>
            <h4 className="h4 text-warning border-2 border-bottom border-warning">{message ? "Email Submitted!" : "Change Your Password"}</h4>
            <p className="text-light">{message ? "" : "Enter your current password and new password."}</p>
            <ul className="error-message m-3">
                {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            </ul>
            {message ? <Alert type={AlertType.SUCCESS} message={message} />
                :
                <>
                    <form onSubmit={handleSubmit}>
                        <ControlInputField
                            onChange={handleEmailChange}
                            onFieldError={handleFieldError}
                            label="Current Password"
                            placeholder="Enter current password"
                            id="currentPassword"
                            value={formData.currentPassword}
                            name="currentPassword"
                            type="password"
                            required={true}
                            validationFunction={hasValue}
                        />
                        <ControlInputField
                            onChange={handleEmailChange}
                            onFieldError={handleFieldError}
                            label="New Password"
                            placeholder="Enter new password"
                            id="newPassword"
                            value={formData.newPassword}
                            name="newPassword"
                            type={show ? "text" : "password"}
                            required={true}
                            validationFunction={isValidPassword}
                        />
                        <ControlInputField
                            onChange={handleEmailChange}
                            onFieldError={handleFieldError}
                            label="Confirm Password"
                            placeholder="Enter confirm password"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            name="confirmPassword"
                            type={show ? "text" : "password"}
                            required={true}
                            validationFunction={(value: any) => isValidPassword(value) && value === formData.newPassword}
                        />
                        <button onClick={toggleShow} type="button" className="mb-1 mt-0 btn btn-link text-warning fw-bold p-0">{show ? 'Hide' : 'Show'}</button>
                        <button type="submit" disabled={hasError || isProcessing} className="btn btn-md btn-warning fw-bold w-100">
                            {isProcessing && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}  {isProcessing ? 'Processing...' : 'Submit'}
                        </button>
                    </form>
                    <PasswordRules value={formData.newPassword}/>
                    <BackButton disabled={isProcessing} />
                </>
            }
        </div>
    )
}
