import React from "react";

const useIdleDetection = (startTime: number, isAuthenicated: boolean) => {
  const [timer, setTimer] = React.useState(startTime);

  React.useEffect(() => {
    if (isAuthenicated) {
      const myInterval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 60000);

      const resetTimeout = () => {
        setTimer(startTime);
      };

      const events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress"
      ];

      for (let i in events) {
        window.addEventListener(events[i], resetTimeout);
      }

      return () => {
        clearInterval(myInterval);
        for (let i in events) {
          window.removeEventListener(events[i], resetTimeout);
        }
      };
    }
  });
  return timer;
};

export default useIdleDetection;
