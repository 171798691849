import { faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IDistributorDto } from 'appDtos';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Icon from '../../../Shared/components/Icon';
import useApi from '../../../Shared/hooks/useApi';
import useFilters from '../../../Shared/hooks/useFilters';
import { useLoader } from '../../../Shared/hooks/useLoader';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import { DistributorsList } from './DistributorsList';
import { RegisterDistributor } from './RegisterDistributor';

enum FilterOptions {
    LIST = 'List',
    ADD = 'Add',
}

export function DistributorsComponent() {
    const { filters, selectFilter } = useFilters(StorageKeys.AP_DISTRIBUTORS_MENU, { [FilterOptions.LIST]: true, [FilterOptions.ADD]: false });
    const { setIsLoading, renderLoader } = useLoader();
    const ref = React.useRef(false);
    const axios = useApi();
    const [distributors, setDistributors] = React.useState<IDistributorDto[]>([]);
    // const [fetchData, setFetchData] = React.useState(false);

    React.useEffect(() => {
        if (!ref.current) {
            const fetchDistributors = async () => {
                setIsLoading(true);
                try {
                    const result = await axios.get<IDistributorDto[]>(`Distributor`);
                    setDistributors(result.data);
                } catch (error) {

                }
                setIsLoading(false);
            };
            fetchDistributors();
        }
        return () => { ref.current = true; };
    }, [axios, setIsLoading]);

    const isList = filters[FilterOptions.LIST];
    const isForm = filters[FilterOptions.ADD];

    return (
        <div className="p-2 card shadow-sm">
            <HeadingElement text="Distributors Management" />
            <ul className="nav">
                <li className="nav-item">
                    <button disabled={isList} onClick={() => selectFilter(FilterOptions.LIST)} className="btn btn-md m-1 fw-bold btn-link" aria-current="page"><Icon icon={faList} /> {FilterOptions.LIST}</button>
                </li>
                <li className="nav-item">
                    <button disabled={isForm} onClick={() => selectFilter(FilterOptions.ADD)} className="btn btn-md m-1 fw-bold btn-link"><Icon icon={faPlus} /> {FilterOptions.ADD}</button>
                </li>
            </ul>
            {isList && <DistributorsList distributors={distributors} renderLoader={renderLoader} />}
            {isForm && <RegisterDistributor />}
        </div>
    );
}
