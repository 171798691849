import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProductDetailDto } from 'appDtos';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Loading from '../../../Shared/components/Loading';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useMode } from '../../../Shared/hooks/useMode';
import { Field } from '../Distributors/DistributorDetail';
import { EditProduct } from './EditProduct';

export function ProductDetail({ id }: { id: string; }) {
    const [product, setProduct] = React.useState<IProductDetailDto>({} as IProductDetailDto);
    const axios = useApi();
    const { mode, updateMode } = useMode();

    React.useEffect(() => {
        const fetchDetail = async () => {
            if (id) {
                const response = await axios.get<IProductDetailDto>(`Product/${id}`);
                setProduct(response.data);
            }
        };
        fetchDetail();

    }, [id, axios, mode]);

    if (!product) {
        return <Loading position="center" />
    }

    return mode === Mode.DETAIL ? (
        <div>
            <HeadingElement text="Product Detail" />
            <div className="p-2">
                <Field title="Name" value={product.name} />
                <Field title="Unit Of Measure" value={product.unitOfMeasure} />
            </div>
            <button onClick={() => updateMode(Mode.EDIT)} className="btn btn-warning fw-bold mt-4"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faEdit} /></span> Edit Product</button>
        </div>
    ) : <EditProduct product={product} setMode={updateMode} />;
}
