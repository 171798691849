import React from 'react';
import { faKey, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import './NavBar.css';

export function NavBar() {
    const { isAuthenticated, setUpAuth, getUser, isInRole, rCodes } = useAuth();
    const goTo = useNavigate();

    const handleLogout = () => {
        setUpAuth();
        goTo('/');
        window.location.reload();
    };

    const user = getUser();

    // const handleClick = () => {
    //     const imageUrl = require("../assets/images/TransferOrderProcessFlow.png");
    //     window.open(imageUrl, "_blank");
    // };

    return (
        <nav id="top" className="navbar navbar-expand-lg navbar-dark bg-warning border-bottom border-dark border-5 noPrint">
            <div className="container">
                <a href="/" className="navbar-brand text-dark">LPRC</a>
                {isAuthenticated() && <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>}
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    {isAuthenticated() &&
                        <div className="navbar-text">
                            <Link to={`/profile/${user?.userId}`}>
                                <button className="btn btn-dark text-warning btn-md text-uppercase fw-bold mx-2"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faUser} /></span>  {user?.fullName}</button>
                            </Link>
                            {isInRole(rCodes?.R500) && <Link to={'/admin-portal'}>
                                <button className="btn btn-dark text-warning btn-md text-uppercase fw-bold mx-2"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faKey} /></span> Admin Portal</button>
                            </Link>}
                            <button onClick={handleLogout} className="btn btn-dark text-warning btn-md text-uppercase fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faSignOut} /></span> Log Out</button>
                            {/* <div className="dropdown">
                                <a className="btn btn-secondary dropdown-toggle m-2 btn btn-dark text-warning btn-md text-uppercase fw-bold" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    Info Links
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><a className="dropdown-item text-dark" target="_blank" href={require("../assets/images/TransferOrderProcessFlow.png")}>Transfer Order Process Flow</a></li>
                                    <li><a className="dropdown-item text-dark" target="_blank" href={require("../assets/images/StockTransactionProcessFlow.png")}>Stock Transaction Flow</a></li>
                                </ul>
                            </div> */}
                        </div>}
                </div>
            </div>
        </nav>
    )
}
