import React from 'react';
import { ModalContext } from '../contexts/ModalContext';

export const ModalComponent = () => {
    const { data, setModalData } = React.useContext(ModalContext);

    const removeElement = () => setModalData({ element: <></> });

    return (
        <div className="modal fade" id="sharedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <button onClick={removeElement} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {data.element}
                    </div>
                    <div className="modal-footer">
                        <button onClick={removeElement} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}