import React from 'react';

export interface StringKeyBooleanValue{
    [key: string]: boolean 
}

export default function useFormProcessor<TFormData>(defaultFormData: TFormData = {} as TFormData, defaultFieldError: StringKeyBooleanValue = {}) {
    const [formData, setFormData] = React.useState<TFormData>(defaultFormData);
    const [fieldErrors, setFieldErrors] = React.useState<StringKeyBooleanValue>(defaultFieldError);
    const [isProcessing, setIsProcessing] = React.useState(false);

    const handleOnChange = React.useCallback((fieldValue: { [key: string]: any }) => {
        setFormData({ ...formData, ...fieldValue });
    }, [formData]);

    const handleFieldError = React.useCallback((fieldValue: { [key: string]: boolean }) => {
        setFieldErrors({ ...fieldErrors, ...fieldValue });
    }, [fieldErrors]);

    const hasError = Object.values(fieldErrors).some(v => v);

    const reset = () => setFormData(defaultFormData);

    return {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        reset,
        formData,
        isProcessing,
        hasError,
    };
}
