export const CANCELLED_STAGE = -1;

export enum Mode {
  DETAIL,
  EDIT,
}

export enum Action {
  COMMENT = 'Comment',
  APPROVE = 'Approve',
  REVIEW = 'Review',
  CANCEL = "Cancel",
  EDIT_COMMENT = "Edit Comment",
  DELETE_COMMENT = "Delete Comment",
}

export enum EntityType {
  Unknown = 0,
  TransferOrder = 1,
  DeliveryOrder = 2,
  StockTransfer = 3,
  StockUpdate = 4,
}

export enum reportType {
  StockBalanceSummary = 'Stock Balance Summary by Products',
  DailyLoadingAnalysis = 'Daily Loading Analysis',
  ProductImportation = 'Product Importation',
  SalesByImporter = 'Sales By Importer',
  SalesByTank = 'Sales By Tank',
  OvernightDifferences = 'Overnight Differences',
  LoadingDifferences = 'Loading Differences',
  ProductMovement = 'Product Movement',
}