import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeliveryOrderInfo, TransferOrderInfo } from 'appDtos';
import { displayQuantity } from '../../Shared/utils/quantityUtil';

export function SelectedTransferOrders(props: { hasInventory: boolean; transferOrders: TransferOrderInfo[]; removeTransferOrder: (id: number) => any; inventoryMap: { [x: number]: string; } }) {

  if (!props.transferOrders.length) {
    return null;
  }

  return (
    <>
      <div className="h6 p-2 bg-dark text-light">Selected Transfer Orders</div>
      <div className="table-responsive">
        <table className="table table-sm table-responsive table-hover table-striped align-middle">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Transfer #</th>
              <th scope="col">Quantity</th>
              {props.hasInventory && <th scope="col">Storage Tank</th>}
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {props.transferOrders?.length ? props.transferOrders.map((t, i) =>
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{t.transferOrderNumber}</td>
                <td>{displayQuantity(t.quantity)}</td>
                {props.hasInventory && <td>{props.inventoryMap[t.inventoryId]}</td>}
                <td className="table-cell-right"><button onClick={() => props.removeTransferOrder(t.transferOrderId)} className="btn btn-danger btn-sm fw-bold"><span className="mr-2"><FontAwesomeIcon size='sm' icon={faTrash} /></span> Remove</button></td>
              </tr>) : <tr><td colSpan={4}>No transfer order has been selected.</td></tr>}
          </tbody>
        </table>
      </div>
    </>
  );
}

export function SelectedDeliveryOrders(props: { deliveryOrders: DeliveryOrderInfo[]; removeDeliveryOrder: (id: number) => any; }) {
  return (
    <>
      <div className="h6 p-2 bg-dark text-light">Selected Delivery Orders</div>
      <div className="table-responsive">
        <table className="table table-sm table-responsive table-hover table-striped align-middle">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Delivery Order #</th>
              <th scope="col">Product</th>
              <th scope="col">Priority</th>
              <th scope="col">Quantity</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {props.deliveryOrders?.length ? props.deliveryOrders.map((d, i) =>
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{d.deliveryOrderNumber}</td>
                <td>{d.product}</td>
                <td>{d.priority}</td>
                <td>{displayQuantity(d.quantity)}</td>
                <td className="table-cell-right"><button onClick={() => props.removeDeliveryOrder(d.deliveryOrderId)} className="btn btn-danger btn-sm fw-bold"><span className="mr-2"><FontAwesomeIcon size='sm' icon={faTrash} /></span> Remove</button></td>
              </tr>) : <tr><td colSpan={5}>No delivery order has been selected.</td></tr>}
          </tbody>
        </table>
      </div>
    </>
  );
}
