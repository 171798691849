import React from 'react'
import { CompartmentDto, IDeliveryOrderDto } from 'appDtos'
import { Link } from 'react-router-dom';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';

interface DeliveryOrderSectionProps {
    deliveryOrders: IDeliveryOrderDto[];
    showingTransfer?: boolean;
}

export default function DeliveryOrderTable({ deliveryOrders, showingTransfer }: DeliveryOrderSectionProps) {
    return (
        <div className="table-responsive">
            {deliveryOrders.length > 0 ?
                <table className="table table-responsive-sm w-100">
                    <thead>
                        <tr>
                            <th scope="col">DO #</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Priority</th>
                            <th scope="col" title='This is the quantity from this transfer order'>Quantity</th>
                            {Boolean(showingTransfer) && <th scope="col">Status</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {deliveryOrders.map((d) => (
                            <tr key={d.id}>
                                <td className="do-table-cell">
                                    <Link to={`/delivery-order-detail/${d.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{d.deliveryOrderNumber ? d.deliveryOrderNumber : d.id}</Link>
                                </td>
                                <td className="do-table-cell">{formatDate(d.submittedDate)}</td>
                                <td className="do-table-cell">{d.priority}</td>
                                <td className="do-table-cell">{displayQuantity(d.quantity)}</td>
                                {Boolean(showingTransfer) && <td className="do-table-cell">{d.status}</td>}                            </tr>
                        ))}
                    </tbody>
                </table> : <div className="m-3">No delivery orders have been created for this order.</div>}
        </div>
    );
}


interface CompartmentSectionProps {
    compartments: CompartmentDto[];
}

export function CompartmentTable({ compartments }: CompartmentSectionProps) {
    return (
        <div className="table-responsive">
            <table className="table table-responsive-sm">
                <thead>
                    <tr>
                        <th scope="col">Compartment #</th>
                        <th scope="col">Bay Number</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Destination</th>
                    </tr>
                </thead>
                <tbody>
                    {compartments.map((d) => (
                        <tr key={d.id}>
                            <td className="do-table-cell">{d.compartmentNumber}</td>
                            <td className={`do-table-cell ${Number(d.bayNumber) > 0 ? '' : 'text-danger'}`}>{Number(d.bayNumber) > 0 ? d.bayNumber : 'Pending'}</td>
                            <td className="do-table-cell">{displayQuantity(Number(d.quantity))}</td>
                            <td className="do-table-cell">{d.destination}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
