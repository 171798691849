import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import useFilters from '../../../Shared/hooks/useFilters';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import { CreateStorageTank } from './CreateStorageTank';
import { StorageTankList } from './StorageTankList';

export enum StorageTankMenu {
    LIST = 'Storage Tank List',
    ADD_NEW_TANK = 'Add New Storage Tank',
}

export function StorageTankComponent() {
    const { filters, selectFilter } = useFilters(StorageKeys.AP_TANKS_MENU, { [StorageTankMenu.LIST]: true, [StorageTankMenu.ADD_NEW_TANK]: false });

    return (
        <div className="p-2 card shadow-sm">
            <HeadingElement text="Storage Tank Management" />
            <ul className="nav">
                <li className="nav-item">
                    <button onClick={() => selectFilter(StorageTankMenu.LIST)} className="nav-link btn btn-sm btn-link active" aria-current="page">{StorageTankMenu.LIST}</button>
                </li>
                <li className="nav-item">
                    <button onClick={() => selectFilter(StorageTankMenu.ADD_NEW_TANK)} className="nav-link btn btn-sm btn-link ">{StorageTankMenu.ADD_NEW_TANK}</button>
                </li>
            </ul>
            {filters[StorageTankMenu.LIST] && <StorageTankList />}
            {filters[StorageTankMenu.ADD_NEW_TANK] && <CreateStorageTank/>}
        </div>
    );
}
