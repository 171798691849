import { IUserPermissionDto } from 'appDtos';
import React, { useContext } from 'react';
import { UserDetailContext } from '../../../Shared/contexts/UserDetailProvider';
import useApi from '../../../Shared/hooks/useApi';

export interface UpdateUserPermission {
  name: string;
  isSelected: boolean;
  id: string;
}

export function UserPermissionManagement(props: { userId?: string, permissions: IUserPermissionDto[]; cancel: () => void}) {
  const [permissionResult, setResult] = React.useState<UpdateUserPermission[]>([]);
  const axios = useApi();
  const ref = React.useRef(false);
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const {triggerRefresh} = useContext(UserDetailContext);
  const [showSavedText, setShowSavedText] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) {
      const fetch = async () => {
        const result = await axios.get<IUserPermissionDto[]>(`Administration/UserPermissions/${props.userId}`);
        const permissionIds = props.permissions.map(r => r.id);
        setResult(result.data.map(d => ({ ...d, isSelected: permissionIds.includes(d.id) })));
      };
      fetch();
    }

    return () => { ref.current = true; };
  }, [axios, props.permissions, props.userId]);

  const handleLoading = () =>{
    setTimeout(() => {
      setIsLoading(false);
      setChangeDetected(false);
      setShowSavedText(true);
    }, 2000);
  }


  const handleOnChange = (permission: UpdateUserPermission) => {
    if (!changeDetected) {
      setChangeDetected(true);
      setShowSavedText(false);
    }
    setResult(permissionResult.map(r => r.name === permission.name ? { ...r, isSelected: !r.isSelected } : r));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await axios.post('Administration/UpdateUserPermission', { userId: props.userId, userPermissions: permissionResult });
      triggerRefresh();
      handleLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const btnText = showSavedText ? 'Saved': 'Save Changes';
  const cancelBtnText = btnText === 'Saved' ? 'Done': 'Cancel'

  return (
    <div>
      <div className="list-group">
        {permissionResult?.map(permission => (
          <label className="list-group-item mr-2" key={permission.name}>
            <input onChange={() => handleOnChange(permission)} className="form-check-input me-1 mr-3" type="checkbox" checked={permission.isSelected} value="" />
            {permission.name}
          </label>
        ))}
      </div>
      <button onClick={props.cancel} type="button" className="btn btn-dark btn-sm fw-bold">{cancelBtnText}</button>
      <button onClick={handleSubmit} type="button" className={`btn btn-${showSavedText ? 'success' : 'warning'} fw-bold btn-sm m-2`} disabled={!changeDetected}>
        {isLoading && <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>}  {isLoading ? 'Saving...' : btnText}
      </button>
    </div>
  );
}
