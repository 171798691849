import React from 'react';

export interface SearchComponentProps {
    handleOnChange: (e: any) => void;
    handleSubmit: (e: any) => void;
    isLoading: boolean;
    placeholder: string;
}

export default function SearchComponent(props: SearchComponentProps) {
    return (
        <div className="input-group">
            <input onChange={props.handleOnChange} type="text" className="form-control" placeholder={props.placeholder} aria-describedby="button-addon2" />
            <button onClick={props.handleSubmit} disabled={props.isLoading} className="btn btn-dark" type="button" id="button-addon2">Search</button>
        </div>
    );
}
