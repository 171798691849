import { faCheck, faComment, faDashboard, faEdit, faFolderPlus, faList, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CommentDto, DocumentDto, ILoadingRequestDetailDto } from 'appDtos';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { CommentModal } from '../../Shared/components/CommentModal';
import { DocumentSectionTable } from '../../Shared/components/DocumentSection';
import HeadingElement from '../../Shared/components/HeadingElement';
import { CommentComponent, HistoryComponent } from '../../Shared/components/History';
import Loading from '../../Shared/components/Loading';
import ModalToggleButton from '../../Shared/components/ModalToggleButton';
import { BackButton, NavigationButton } from '../../Shared/components/NavigationButton';
import { Action, EntityType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import { useDocumentActions } from '../../Shared/hooks/useDocumentActions';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import DeliveryOrderTable from '../deliveryOrders/DeliveryOrderTable';
import { LoadingRequestProgress } from './OrderTracking';
import { TransferOrderReviewModal } from './TransferOrderReviewModal';
import "./orderDetail.css";

export function OrderDetail() {
  const [detail, setLoadingRequestDetail] = React.useState<ILoadingRequestDetailDto>();
  const axios = useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef(false);
  const { id } = useParams();
  const path = 'TransferOrder';
  const { isInRole, hasPermission, rCodes, pCodes, getUser } = useAuth();
  const { viewDocument, handleDelete } = useDocumentActions(EntityType.TransferOrder, getUser()?.userId);
  const [action, setAction] = React.useState('');
  const [comment, setComment] = React.useState<CommentDto | undefined>();
  const { errorMessage, setError } = useApiHandleError();

  React.useEffect(() => {
    if (!ref.current) {
      setIsLoading(true);
      const fetch = async () => {
        try {
          if (id && !detail) {
            const result = await axios.get<ILoadingRequestDetailDto>(`${path}/${id}`);
            if (result.data) {
              setLoadingRequestDetail(result.data);
            }
          }
        } catch (error) {
          setError(error);
        }
        setIsLoading(false);
      };
      fetch();
    }

    return () => {
      ref.current = true;
    };

  }, [id, axios, detail, setError]);

  const handleDeleteDocument = async (doc: DocumentDto) => await handleDelete(doc, detail?.id);

  const handleviewDocument = (doc: DocumentDto) => viewDocument(doc, detail?.id);

  const handleCommentAction = ({ comment, action }: { comment: CommentDto, action: string }) => {
    setAction(action);
    setComment(comment);
  }

  const totalDeliveryOrders = detail?.deliveryOrders.reduce((q, e) => q + Number(e.quantity), 0) ?? 0;
  const availableQuantity = (detail?.quantity ?? 0) - totalDeliveryOrders;

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Transfer Order Detail</div>
        <div className="d-flex-jce">
          {(detail?.setting?.isEditable ?? false) && <NavigationButton link={`/edit-transfer-order/${detail?.id}`} icon={faEdit} label="Edit Transfer Order" />}
          {isInRole(rCodes?.R100) && hasPermission(pCodes?.P200) && <NavigationButton link="/create-transfer-order" icon={faFolderPlus} label="Create Transfer Order" />}
          <NavigationButton link="/transfer-orders/list" icon={faList} label="Transfer Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isLoading && <Loading loadingText="Loading transfer order detail." position="center" />}
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      {!!(detail) && <div className="mb-5 mt-2">
        <HeadingElement text="Tracking" />
        <LoadingRequestProgress status={detail.status} />
        <HeadingElement text="Transfer Order Information" />
        <div className="d-flex-jcs">
          <Property title="Transfer Order #" value={detail.transferOrderNumber} column="col-md-3" />
          <Property title="Created Date" value={formatDate(detail.createdDate)} column="col-md-3" />
          <Property title="Created By" value={detail.createdBy} column="col-md-3" />
          <Property title="Current Status" value={detail.status.value} column="col-md-3" />
          <Property title="Importer" value={<a href={`/importers/list/#/${detail.importer?.id}`} className="text-decoration-none">{detail.importer?.name}</a>} column="col-md-3" />
          <Property title="Distributor" value={detail.distributor?.name} column="col-md-3" />
        </div>
        <HeadingElement text="Product/Quantity" />
        <div className="d-flex-jcs">
          <Property title="Product" value={detail.product?.name} column="col-md-3" />
          <Property title="Transfer Quantity" value={displayQuantity(detail.quantity)} column="col-md-3" />
          <Property title="Total Delivery Order Quantity" value={displayQuantity(totalDeliveryOrders)} column="col-md-3" />
          <Property title="Available Quantity" value={displayQuantity(availableQuantity)} column="col-md-3" />
        </div>
        <HeadingElement text="Delivery Orders" />
        <DeliveryOrderTable deliveryOrders={detail.deliveryOrders} showingTransfer />
        <HeadingElement text="Documents" />
        <DocumentSectionTable canUpload={detail?.setting?.canManageDocuments} entityType={EntityType.TransferOrder} documents={detail.documents} id={detail.id} handleDelete={handleDeleteDocument} handleDocumentView={handleviewDocument} />
        <div className="history-comment-section border-bottom">
          <div className="history-contents">
            <HeadingElement text="History" />
            <HistoryComponent reviews={detail.reviews} />
          </div>
          <div className="comment-contents">
            <HeadingElement text="Comments" />
            <CommentComponent id={detail.id} comments={detail.comments} handleCommentAction={handleCommentAction} />
          </div>
        </div>
      </div>}
      <div className="page-action-button-group">
        <BackButton disabled={isLoading} />
        {detail?.setting.canCancel && <ModalToggleButton modalId="#TransferOrderReviewModal" handleClick={() => setAction(Action.CANCEL)} value="Cancel" icon={faXmark} color="danger" />}
        {!!detail && <ModalToggleButton modalId="#CommentModal" handleClick={() => setAction(Action.COMMENT)} value="Add Comment" icon={faComment} />}
        {detail?.setting.canReview && <ModalToggleButton modalId="#TransferOrderReviewModal" handleClick={() => setAction(Action.REVIEW)} value="Add Review" icon={faMagnifyingGlass} />}
        {detail?.setting.canApprove && <ModalToggleButton modalId="#TransferOrderReviewModal" handleClick={() => setAction(Action.APPROVE)} value="Approve" icon={faCheck} />}
      </div>
      {/* {!(!isLoading && !!detail) && <NotFound />} */}
      <TransferOrderReviewModal action={action} id={detail?.id} />
      <CommentModal action={action} id={detail?.id} comment={comment} path={path} />
    </div>)
}

export function NotFound() {
  return (
    <div className="card">
      <div className="card-body text-center">
        <h5 className="card-title display-6">Transfer Order not found.</h5>
      </div>
    </div>);
}

export function Property({ title, value, column }: { title: string, value: any, column: string }) {
  return (
    <div className={`p-1 ${column}`}>
      <p className="mb-0">{title}</p>
      <p className="fw-bold mb-1">{value}</p>
    </div>);
}
