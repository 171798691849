import React from 'react'
import qs from 'qs';
import { format, parseISO } from 'date-fns';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import Pagination from '../../Shared/components/Pagination';
import useApi from '../../Shared/hooks/useApi';
import useAppFilters from '../../Shared/hooks/useAppFilters';
import useAuth from '../../Shared/hooks/useAuth';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faChevronDown, faChevronUp, faDashboard, faFolderPlus, faGasPump, faHourglassEnd, faMagnifyingGlass, faShip, faTruck, faX, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterKeyValue, FilterRequest, PaginatedDeliveryOrderResponse, IDeliveryOrderDto } from 'appDtos';
import { Link } from 'react-router-dom';
import { NavigationButton } from '../../Shared/components/NavigationButton';
import { NoResultsFound } from '../../Shared/components/NoResultsFound';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { scrollToTop } from '../../Shared/utils/windowUtil';
import { DatePickerComponent, DateRange } from '../../Shared/components/DatePickerComponent';
import "../orders/OrderList";

export default function DeliveryOrdersList() {
  const axios = useApi();
  const [results, setDeliveryOrders] = React.useState<IDeliveryOrderDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, pages: 0 });
  const { errorMessage, setError } = useApiHandleError();
  const { filters } = useAppFilters();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const [deliveryOrderFilterRequest, setDeliveryOrderFilterRequest] = React.useState<FilterRequest>(() => {
    if (window?.location?.hash) {
      const values = qs.parse(window.location.hash.replace('#/?', ''));
      const stringValues = ['deliveryOrderNumber', 'createdFrom', 'createdTo']
      const data = Object.entries(values).reduce((r, [k, v]) => ({ ...r, [k]: stringValues.includes(k) ? v : Number(v) }), {});
      return data;
    }
    return {};
  });
  const [dateRange, setDateRange] = React.useState<{ from: Date | null, to: Date | null }>(() => {
    return {
      from: deliveryOrderFilterRequest.createdFrom ? parseISO(deliveryOrderFilterRequest.createdFrom as string) : null,
      to: deliveryOrderFilterRequest.createdTo ? parseISO(deliveryOrderFilterRequest.createdTo as string) : null
    };
  });
  const [search, setSearch] = React.useState(deliveryOrderFilterRequest.deliveryOrderNumber ?? '');

  const handlePaginate = (pageNumber: number) => setDeliveryOrderFilterRequest({ ...deliveryOrderFilterRequest, pageNumber });

  const handleDateChange = (range: DateRange) => {
    const update = { ...deliveryOrderFilterRequest };
    const { from, to } = range;
    from ? update.createdFrom = format(from, 'yyyy-MM-dd') : delete update.createdFrom;
    to ? update.createdTo = format(to, 'yyyy-MM-dd') : delete update.createdTo;
    setDateRange({ ...dateRange, ...range });
    setDeliveryOrderFilterRequest(update);
  }

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const result = await axios.post<PaginatedDeliveryOrderResponse>(`DeliveryOrder/Search`, deliveryOrderFilterRequest);
        const { results, pages, currentPage } = result.data;
        setDeliveryOrders(results);
        setPageInfo({ pages, currentPage });
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetch();
  }, [axios, setError, deliveryOrderFilterRequest]);

  const handleFilterChange = (filter: { [key: string]: number | string }) => {
    const [[key, value]] = Object.entries(filter);

    let update = { ...deliveryOrderFilterRequest };

    if (deliveryOrderFilterRequest[key] === value) {
      delete update[key];
      setDeliveryOrderFilterRequest(update);
    } else {
      update = { ...update, ...filter }
      setDeliveryOrderFilterRequest(update)
    }
  }

  React.useEffect(() => {
    const url = Object.entries(deliveryOrderFilterRequest).reduce((r, [k, v]) => r ? `${r}&${k}=${v}` : `/delivery-orders/list/#/?${k}=${v}`, '');
    scrollToTop();
    if (url) {
      return window.history.replaceState(null, "LPRC", url);
    }
    return window.history.replaceState(null, "LPRC", "/delivery-orders/list");
  }, [deliveryOrderFilterRequest])

  const hasMultipleFilters = React.useMemo(() => Object.values(deliveryOrderFilterRequest).length > 1, [deliveryOrderFilterRequest]);
  const handleClearAll = () => {
    setDeliveryOrderFilterRequest({});
    setSearch('');
    setDeliveryOrders([]);
    setDateRange({ from: null, to: null });
  }

  const hasSearchTerm = !!search;
  const handleSearch = () => setDeliveryOrderFilterRequest({
    ...deliveryOrderFilterRequest,
    deliveryOrderNumber: search
  })
  const handleSearchClear = () => {
    delete deliveryOrderFilterRequest.deliveryOrderNumber;
    setSearch('');
    setDeliveryOrderFilterRequest({ ...deliveryOrderFilterRequest });
  }
  const canAccess = ((isInRole(rCodes?.R300) && hasPermission(pCodes?.P300)) || (isInRole(rCodes?.R1100) && hasPermission(pCodes?.P300)));

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Delivery Orders</div>
        <div className="d-flex-jce">
          {canAccess && <NavigationButton link="/create-delivery-order" icon={faFolderPlus} label="Create Delivery Order" />}
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      <div className="d-flex-jcs">
        <div className="filters">
          <div className="input-group mb-2">
            <input onChange={(e: any) => setSearch(e.target.value)} value={search} type="text" className="form-control" placeholder="Enter delivery order number" aria-label="Search input" aria-describedby="search" />
            {hasSearchTerm && <button onClick={handleSearchClear} type="button" className="input-group-text fw-bold" id="search"><Icon icon={faX} /></button>}
            <button disabled={!hasSearchTerm} onClick={handleSearch} type="button" className="input-group-text fw-bold bg-warning" id="search"><Icon icon={faMagnifyingGlass} /></button>
          </div>
          <div className="filter-section-title">
            <h5 className="m-0">Filter</h5>
            {hasMultipleFilters && <button aria-label="filter-collaspe-button" type="button" onClick={handleClearAll} className="btn btn-sm btn-link p-0 m-0 text-dark fw-bold">Clear All Filters</button>}
          </div>
          <div className="mb-1 shadow">
            <label htmlFor="date-range" className="form-label fw-bold border p-2 w-100 border-warning"><Icon icon={faCalendar} /> Created Date</label>
            <DatePickerComponent setDate={handleDateChange} dateRange={dateRange} />
          </div>
          {Boolean(filters) ? <div>
            <FilterSet icon={faDashboard} filterKey="stage" title="Stages" keyValues={filters?.deliveryOrderFilter?.stages ?? []} value={deliveryOrderFilterRequest.stage} onChange={handleFilterChange} />
            <FilterSet icon={faHourglassEnd} filterKey="priority" title="Priorities" keyValues={filters?.deliveryOrderFilter?.priorities ?? []} value={deliveryOrderFilterRequest.priority} onChange={handleFilterChange} />
            <FilterSet icon={faGasPump} filterKey="productId" title="Products" keyValues={filters?.entityFilters?.products ?? []} value={deliveryOrderFilterRequest.productId} onChange={handleFilterChange} />
            <FilterSet icon={faShip} filterKey="importerId" title="Importers" keyValues={filters?.entityFilters?.importers ?? []} value={deliveryOrderFilterRequest.importerId} onChange={handleFilterChange} />
            <FilterSet icon={faTruck} filterKey="distributorId" title="Distributors" keyValues={filters?.entityFilters?.distributors ?? []} value={deliveryOrderFilterRequest.distributorId} onChange={handleFilterChange} />
          </div> : <Loading />}
        </div>
        <div id="resultList" className={isLoading ? "results-list pt-5" : "results-list"}>
          {isLoading && <Loading position="center" />}
          {!isLoading && results.length > 0 && results.map(order => (
            <div className="card p-4 mb-2 shadow-lg border-warning" key={order.id}>
              <div className="d-flex-jcs border-bottom mb-1">
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Delivery Order #</div>
                  <Link to={`/delivery-order-detail/${order.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{order.deliveryOrderNumber}</Link>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Created Date</div>
                  <div className="h6 mb-0">{formatDate(order.submittedDate)}</div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Created By</div>
                  <div className="h6 mb-0">{order.submittedBy}</div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Status</div>
                  <div className="h6 mb-0">{order.status}</div>
                </div>
              </div>

              <div className="d-flex-jcs mt-2">
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Product</div>
                  <div className="h6 mb-0 text-truncate">{order.product}</div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Quantity</div>
                  <div className="h6 mb-0 text-truncate">{displayQuantity(order.quantity)} Gallons</div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12">
                  <div className="mb-0 field-heading">Priority</div>
                  <div className="h6 mb-0 text-truncate">{order.priority}</div>
                </div>
              </div>
            </div>))}
          {(!isLoading && results.length === 0 && !errorMessage) && <NoResultsFound message="No results were found." />}
          {errorMessage && <NoResultsFound message={errorMessage} />}
          {!isLoading && <div className="d-flex justify-content-start mt-4">
            {results.length > 0 && <button onClick={() => scrollToTop()} className="btn btn-warning btn-sm fw-bold" disabled={results.length < 10}><span><FontAwesomeIcon size='sm' icon={faArrowAltCircleUp} /></span> Go to top</button>}
            <Pagination {...pageInfo} paginate={handlePaginate} />
          </div>}
        </div>
      </div>
    </div>
  );
}

interface FilterSetProps {
  icon: IconDefinition;
  title: string;
  keyValues: FilterKeyValue[];
  filterKey: string;
  value?: number | string;
  onChange: (value: any) => void
}

export function FilterSet(props: FilterSetProps) {
  const [collaspe, setCollaspe] = React.useState(false);

  return (
    <div className="mb-1 shadow">
      <div className="filter-set-header border border-warning">
        <div className="h6 mb-0"><Icon icon={props.icon} /> {props.title}</div>
        <button aria-label="filter-collaspe-button" type="button" onClick={() => setCollaspe(!collaspe)} className="btn btn-link p-0 m-0"><Icon color="black" icon={collaspe ? faChevronUp : faChevronDown} /></button>
      </div>
      <div className={collaspe ? "d-none" : "filter-set"}>
        <ul className="list-group">
          {props.keyValues.map((s: any) => (
            <li key={s.key} title={s.value} className="list-group-item text-truncate filter-set-item">
              <input
                onChange={() => props.onChange({ [props.filterKey]: s.key })}
                className="form-check-input me-1"
                type="checkbox"
                value={s.key}
                aria-label={s.value}
                checked={props.value === s.key}
              />
              {s.value}
            </li>))
          }
        </ul>
      </div>
    </div>
  );
}

export function Field({ title, value, col }: { title: string, value: string | undefined, col?: string }) {
  return (
    <div className={col ? col : "col-md-3"}>
      <div className="mb-0 field-heading">{title}</div>
      <div className="h6 mb-0">{value}</div>
    </div>
  );
}
