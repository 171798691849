import { ICreateDistributorRequest } from 'appDtos';
import React, { useState } from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

export function RegisterDistributor() {
    const axios = useApi();
    const [success, setSuccess] = useState(false);
    const { setError, errorMessage } = useApiHandleError();
    const {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        formData,
        isProcessing,
        hasError,
        reset
    } = useFormProcessor<ICreateDistributorRequest>();

    React.useEffect(() => setError(undefined), [formData, setError]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            await axios.post('Distributor', formData);

            handleOnChange({});
            setSuccess(true);
            reset();

        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };
    
    return (
        <>
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {success && <Alert type={AlertType.SUCCESS} message="Distributor successfully added." />}
            <form className="p-3">
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Distributor Name"
                    placeholder="Enter name."
                    id="name"
                    value={formData.name}
                    name="name"
                    focusOnMount={true}
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Tax Identification Number"
                    placeholder="Enter Tax Identification Number."
                    id="taxIdentificationNumber"
                    value={formData.taxIdentificationNumber}
                    name="taxIdentificationNumber"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Full Address"
                    placeholder="Enter full address."
                    id="address"
                    value={formData.address}
                    name="address"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Primary Phone Number"
                    placeholder="Enter primary phone number."
                    id="primaryPhone"
                    value={formData.primaryPhone}
                    name="primaryPhone"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Secondary Phone Number"
                    placeholder="Enter secondary phone number."
                    id="primaryPhone"
                    value={formData.secondaryPhone}
                    name="secondaryPhone"
                    type="text"
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Primary Email"
                    placeholder="Enter primary email."
                    id="primaryEmail"
                    value={formData.primaryEmail?.trim()}
                    name="primaryEmail"
                    type="text"
                    required={true}
                    validationFunction={isValidEmail}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Secondary Email"
                    placeholder="Enter secondary email."
                    id="secondaryEmail"
                    value={formData.secondaryEmail?.trim()}
                    name="secondaryEmail"
                    type="text"
                    validationFunction={(v: any) => v ? isValidEmail(v) : true}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Contact Person"
                    placeholder="Enter contact person."
                    id="contactPerson"
                    value={formData.contactPerson}
                    name="contactPerson"
                    type="text"
                />
                <button onClick={handleSubmit} type="button" disabled={isProcessing || hasError} className="btn btn-warning fw-bold">Submit</button>
            </form>
        </>
    );
}
