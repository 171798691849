import { faCancel, faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import Modal from 'react-modal';
import Icon from './Icon';

const customStyles = {
    content: {
        top: '42%',
        left: '50%',
        right: '30%',
        bottom: 'auto',
        marginRight: '-20%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparant',
    }
};

interface PromptProps {
    ok: any,
    cancel?: any,
    title?: string,
    text: string,
}

interface PromptContextType {
    openModal: (props: PromptProps) => void;
    closeModal: () => void;
    isOpen: boolean;
}

Modal.setAppElement('#root');

export const PromptContext = React.createContext<PromptContextType>({
    openModal: (props: PromptProps) => { },
    closeModal: () => { },
    isOpen: false
});

export const PromptProvider = ({ children }: any) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [promptProps, setPromptProps] = React.useState<PromptProps>({ ok: () => { }, title: 'Please Confirm!', text: '' });


    function openModal(props: PromptProps) {
        setIsOpen(true);
        setPromptProps(props);
    }

    function closeModal() {
        setIsOpen(false);
        promptProps.cancel && promptProps.cancel();
    }

    return (
        <PromptContext.Provider value={{ openModal, closeModal, isOpen }}>
            {children}
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Prompt Modal"
                    shouldCloseOnOverlayClick={false}
                >
                    <div className="card-body">
                        <h5 className="card-title border-bottom border-3 sw-100">{promptProps.title}</h5>
                        <p className="card-text">{promptProps.text}</p>
                        <div className="text-end">
                            <button onClick={closeModal} className="btn btn-secondary  btn-md fw-bold m-1"><Icon icon={faCancel} /> Cancel</button>
                            <button onClick={promptProps.ok} className="btn btn-warning btn-md fw-bold m-1"><Icon icon={faCheck} /> Ok</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </PromptContext.Provider>
    );
}

export const usePrompt = () => {
    const { openModal, closeModal, isOpen } = useContext(PromptContext);
    return {
        openModal, closeModal, isOpen
    }
}