import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { Title } from '../../../Shared/components/Title';
import PageNotFound from './PageNotFound';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import useErrorHandlier from '../../../Shared/hooks/useErrorHandlier';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';

interface formData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  password?: string;
  confirmedPassword?: string;
  adminCode?: string;
}

export default function AdminRegister() {
  const goTo = useNavigate();
  const { tempCode } = useParams();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [complete, setComplete] = React.useState<boolean>(false);
  const axios = useApi();
  const ref = React.useRef(false);
  const  {errorMessage, setError} = useErrorHandlier();
  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    formData,
    isProcessing,
    hasError } = useFormProcessor<formData>({}, { firstName: true, lastName: true, email: true, password: true, confirmedPassword: true });

    React.useEffect(() => { localStorage.removeItem(StorageKeys.APP_TOKEN); }, []);

    React.useEffect(() => {
    if (!ref.current) {
      const confirmCode = async () => {
        setIsProcessing(true);
        if (tempCode) {
          try {
            const result = await axios.get(`/Account/Confirm/${tempCode}`);
            setIsValid(result.data);
            setComplete(true)
          } catch (error) {
            goTo('/fsdjf');
           }
        }
        setIsProcessing(false);
      };
      confirmCode();
    }
    return () => { ref.current = true };
  }, [tempCode, axios, setIsProcessing, goTo]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const result = await axios.post('Account/Register-Admin', { admin: { ...formData, adminCode: tempCode } });
      if (result?.data) {
        goTo('/');
      }
    } catch (error: any) {
      setError(error)
    }
    setIsProcessing(false);
  }

  if (isValid && isProcessing) {
    return <div className="display-6">Checking...</div>;
  }

  return complete && isValid ? (
    <div className='login-form w-25 mx-auto mt-5'>
      <Title text="Admin Reqistration" />
        <div className="text-danger">{errorMessage}</div>
      <form className="p-3" onSubmit={handleSubmit}>
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="First Name"
          placeholder="Enter user's first name."
          id="firstName"
          value={formData.firstName}
          name="firstName"
          focusOnMount={true}
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Last Name"
          placeholder="Enter user's last name."
          id="lastName"
          value={formData.lastName}
          name="lastName"
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Email Address"
          placeholder="Enter user's primary email address."
          id="email"
          value={formData.email}
          name="email"
          type="text"
          required={true}
          validationFunction={isValidEmail}
        />
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Password"
          placeholder="Enter password"
          id="password"
          value={formData.password}
          name="password"
          type="password"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Confirm password"
          placeholder="Re-enter user's password."
          id="confirmedPassword"
          value={formData.confirmedPassword}
          name="confirmedPassword"
          type="password"
          required={true}
          validationFunction={(value: string | number | undefined) => value === formData.password}
        />
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Primary Phone"
          placeholder="Enter user's primary phone if provided."
          id="phone"
          value={formData.phone}
          name="phone"
          type="text"
        />
        <button type="submit" disabled={isProcessing || hasError} className="btn btn-primary">Submit</button>
      </form>
    </div>
  ) : <PageNotFound />;
}
