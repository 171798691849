import { faBucket, faCheck, faComment, faDashboard, faEdit, faFolderPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CommentDto, DocumentDto, IStockTransferDetailDto } from 'appDtos';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { CommentModal } from '../../Shared/components/CommentModal';
import { ConfirmModal } from '../../Shared/components/ConfirmModal';
import { DocumentSectionTable } from '../../Shared/components/DocumentSection';
import HeadingElement from '../../Shared/components/HeadingElement';
import { CommentComponent } from '../../Shared/components/History';
import Loading from '../../Shared/components/Loading';
import ModalToggleButton from '../../Shared/components/ModalToggleButton';
import { BackButton, NavigationButton } from '../../Shared/components/NavigationButton';
import { Action, EntityType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import { useDocumentActions } from '../../Shared/hooks/useDocumentActions';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import "./stockTransferDetail.css";

export function StockTransferDetail() {
  const [detail, setStockingTransferDetail] = React.useState<IStockTransferDetailDto>();
  const axios = useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const goTo = useNavigate();
  const { id } = useParams();
  const path = 'StockTransfer';
  const { isInRole, hasPermission, rCodes, pCodes, getUser } = useAuth();
  const { viewDocument, handleDelete } = useDocumentActions(EntityType.StockTransfer, getUser()?.userId);
  const { errorMessage, setError } = useApiHandleError();
  const [action, setAction] = React.useState('');
  const [comment, setComment] = React.useState<CommentDto | undefined>();

  React.useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      if (id && !detail) {
        try {
          const result = await axios.get<IStockTransferDetailDto>(`${path}/${id}`);
          setStockingTransferDetail(result.data);
        } catch (error) {
          setError(error);
        }
      }
      setIsLoading(false);
    };
    fetch();
  }, [id, axios, detail, setError]);

  const handleDeleteDocument = async (doc: DocumentDto) => handleDelete(doc, detail?.id);

  const handleviewDocument = async (doc: DocumentDto) => viewDocument(doc, detail?.id);

  const showEditButton = detail?.isEditable;
  const confirmBodyElement = (
    <>
      <div className="h6">{`Are you sure you want to ${action.toUpperCase()} this stock transfer request?`}</div>
      <p className="text-danger"><strong>Note</strong>: This action CAN NOT be reverted.</p>
    </>
  );

  const handleClick = async () => {
    try {
      const map: { [k: string]: number } = { [Action.REVIEW]: 10, [Action.APPROVE]: 20, [Action.CANCEL]: 30 };
      await axios.post(`${path}/StatusUpdate`, { id, status: map[action] });
      if (action !== 'Cancel') {
        window.location.reload();
      } else {
        return goTo('/stock-transfer/list');
      }
    } catch (error: any) {
      setError(error);
    }
  }

  const handleCommentAction = ({ comment, action }: { comment: CommentDto, action: string }) => {
    setAction(action);
    setComment(comment);
  }

  return (<div className="container app-container">
    <div className="d-flex-jcb page-heading">
      <div className="h4 text-dark">Stock Transfer Detail</div>
      <div className="d-flex-jce">
        {showEditButton && <NavigationButton link={`/edit-stock-transfer/${detail.id}`} icon={faEdit} label="Edit Stock Transfer" />}
        {isInRole(rCodes?.R100) && hasPermission(pCodes?.P100) && <NavigationButton link="/submit-stock-transfer" icon={faFolderPlus} label="Submit Stock Transfer" />}
        <NavigationButton link="/stock-transfer/list" icon={faBucket} label="Stock Transfers" />
        <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
      </div>
    </div>
    {isLoading && <Loading position="center" />}
    {Boolean(errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
    {!!detail && <>
      <div className="mb-5">
        <HeadingElement text="Transfer Information" />
        <div className="d-flex-jcs">
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Transaction #</p>
            <p className="fw-bold">{detail.id}</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Reference Number</p>
            <p className="fw-bold">{detail.referenceNumber ?? 'N/A'}</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Current Status</p>
            <p className="fw-bold">{detail.status}</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Submitted Date</p>
            <p className="fw-bold mb-0">{formatDate(detail.submittedDate)}</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Submitted By</p>
            <p className="fw-bold">{detail.submitter}</p>
          </div>
          <div className="col-md-3 col-sm-12">
            <p className="mb-0">Quantity</p>
            <p className="fw-bold mb-1">{displayQuantity(detail.quantity)}</p>
          </div>
        </div>
        <HeadingElement text="Inventory" />
        <div className="d-flex-jce mb-0">
          <div className="col">
            <div className="mb-0 h6">Origin</div>
            <div className="mb-0 text-truncate">{detail.fromInventory.importerName}</div>
            <div className="mb-0 text-truncate">Tank# {detail.fromInventory.storageTankNumber}</div>
            <div className="mb-0 text-truncate">{detail.fromInventory.productName}</div>
          </div>
          <div className="col">
            <div className="mb-0 h6">Destination</div>
            <div className="mb-0 text-truncate">{detail.toInventory.importerName}</div>
            <div className="mb-0 text-truncate">Tank# {detail.toInventory.storageTankNumber}</div>
            <div className="mb-0 text-truncate">{detail.toInventory.productName}</div>
          </div>
        </div>
        <HeadingElement text="Documents" />
        <DocumentSectionTable canUpload={detail.canManageDocuments ?? false} entityType={EntityType.StockTransfer} documents={detail.documents} id={detail.id} handleDelete={handleDeleteDocument} handleDocumentView={handleviewDocument} />
        <HeadingElement text="Comments" />
        <CommentComponent id={detail.id} comments={detail.comments} handleCommentAction={handleCommentAction} />
        <div className="page-action-button-group">
          <BackButton disabled={isLoading} />
          {(detail?.status === 'New' || detail?.status === 'Reviewed') && <ModalToggleButton modalId="#CommentModal" handleClick={() => setAction(Action.COMMENT)} value="Add Comment" icon={faComment} />}
          {detail?.canCancel && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.CANCEL)} value="Cancel" icon={faXmark} color="danger" />}
          {detail?.isReviewable && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.REVIEW)} value="Review" icon={faCheck} />}
          {detail?.isApprovable && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.APPROVE)} value="Approve" icon={faCheck} />}
          <ConfirmModal body={confirmBodyElement} func={handleClick} />
          <CommentModal action={action} id={detail?.id} comment={comment} path={path} />
        </div>
      </div>
    </>}
  </div>);
}
