import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider } from './Shared/contexts/AuthProvider';
import { ErrorBoundary } from './ErrorBoundry';
import { ModalProvider } from './Shared/contexts/ModalContext';
import { ModalComponent } from './Shared/components/Modal';
import { PromptProvider } from './Shared/components/PromptModal';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <ModalProvider>
          <PromptProvider>
          <App />
          </PromptProvider>
          <ModalComponent />
        </ModalProvider>
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
