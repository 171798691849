import { DocumentDto } from "appDtos";
import useApi from "./useApi";

export const useDocumentActions = (entityType: number, userId: string | undefined) => {
    const axios = useApi();
  
    const handleDelete = async (doc: DocumentDto, entityId?: number) => {
      try {
        // await axios.delete(`${path}/${entityId}/documents/${document.id}`);
        await axios.delete(`Document/${doc.id}/EntityType/${entityType}/Entity/${entityId}`);
        window.location.reload();
      } catch (error) {
        console.log(error)
      }
    }
  
    const viewDocument = async(doc: DocumentDto, entityId?: number) => {
      try {
        const res = await axios.get(`Document/${doc.id}/EntityType/${entityType}/Entity/${entityId}`);
        window.open(res.data);
      } catch (error) {
        console.log(error)
      }
    }
  
    return {
      handleDelete,
      viewDocument
    }
  }