import React from 'react';
import { DeliveryOrderInfo, Inventory, InventoryForDeliveryOrderDto, InventoryInfo, TransferOrderInfo } from 'appDtos';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { displayQuantity } from '../../Shared/utils/quantityUtil';

export function TransferOrderInputForm(props: { hasInventory: boolean, inventories: InventoryForDeliveryOrderDto[], index: number, isSelected: boolean; transferOrder: TransferOrderInfo; handleSelection: (info: TransferOrderInfo) => any; }) {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [quantity, setQuantity] = React.useState<string>('');
  const [inventoryId, setInventoryId] = React.useState<string>('');
  const handleAdd = () => {
    if (Number(quantity) > Number(props.transferOrder?.quantity)) {
      return setErrorMessage('Quantity can not be more than the transfer order available quantity.');
    }
    if (props.hasInventory && !Number(inventoryId)) {
      return setErrorMessage('Inventory is required.');
    }
    props.handleSelection({
      isSelected: true,
      transferOrderId: props.transferOrder.transferOrderId,
      transferOrderNumber: props.transferOrder.transferOrderNumber,
      product: props.transferOrder.product,
      date: props.transferOrder.date,
      importer: props.transferOrder.importer,
      distributor: props.transferOrder.distributor,
      quantity: Number(quantity),
      inventoryId: Number(inventoryId),
    });
    //setQuantity('');
  };

  const handleQuantityChange = (e: any) => {
    setErrorMessage('');
    const value = e.target.value.replace(/\D/g, '');
    setQuantity(value);
  };

  const handleInventoryChange = (e: any) => {
    setInventoryId(e.target.value);
  };

  let className = "";
  if (props.isSelected) className = 'selected-option-row';
  if (errorMessage) className = 'error-option-row';

  return (
    <tr className={className} key={props.transferOrder.transferOrderId}>
      <th scope="row">{props.index + 1}.</th>
      <td><Link to={`/transfer-order-detail/${props.transferOrder.transferOrderId}`} className="btn btn-link btn-md text-decoration-none">{props.transferOrder.transferOrderNumber}</Link></td>
      <td title={props.transferOrder.product}>{props.transferOrder.product}</td>
      <td title={props.transferOrder.importer}>{props.transferOrder.importer}</td>
      <td title={props.transferOrder.distributor}>{props.transferOrder.distributor}</td>
      <td title={props.transferOrder.date}>{props.transferOrder.date}</td>
      <td>{displayQuantity(props.transferOrder.quantity)}</td>
      {props.hasInventory && <td> <select className="form-select" aria-label="Default select update type" onChange={handleInventoryChange}>
        <option selected>Select inventory</option>
        {props.inventories.map((v, i) => <option value={v.id} key={i}>{v.storageTank}</option>)}
      </select></td>}
      <td><input onChange={handleQuantityChange} value={quantity} type="text" aria-label="quantity" className="form-control" placeholder='Enter quantity' /></td>
      <td className="table-cell-right">
        <button disabled={!Number(quantity) || (props.hasInventory && !Number(inventoryId))} onClick={handleAdd} type="button" className="add-button btn btn-warning fw-bold btn-sm"><span><FontAwesomeIcon size='lg' icon={faPlus} /></span> Add</button>
      </td>
    </tr>
  );
}

export function StockRequestInputForm({ handleSelection, inventory, isSelected, quantity }: { quantity?: number, isSelected: boolean; inventory: Inventory; handleSelection: (info: InventoryInfo) => any; }) {
  const handleQuantityChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, '');
    handleSelection({
      inventoryId: inventory.inventoryId,
      quantity: Number(value),
    });
  };

  let className = "";
  if (isSelected) className = 'selected-option-row';
  return (
    <tr className={className} key={inventory.inventoryId}>
      <td>{inventory.storageTankNumber}</td>
      <td>{inventory.productName}</td>
      <td><input onChange={handleQuantityChange} value={displayQuantity(quantity)} type="text" aria-label="quantity" className="form-control" placeholder='Enter quantity' /></td>
    </tr>
  );
}

export function DeliveryOrderInputForm(props: { index: number; deliveryOrder: DeliveryOrderInfo; handleSelection: (info: DeliveryOrderInfo) => any; }) {
  const handleAdd = () => {
    props.handleSelection({
      isSelected: true,
      deliveryOrderId: props.deliveryOrder.deliveryOrderId,
      deliveryOrderNumber: props.deliveryOrder.deliveryOrderNumber,
      product: props.deliveryOrder.product,
      priority: props.deliveryOrder.priority,
      quantity: props.deliveryOrder.quantity,
    });
  };

  return (
    <tr key={props.deliveryOrder.deliveryOrderNumber}>
      <th scope="row">{props.index + 1}.</th>
      <td><Link to={`/delivery-order-detail/${props.deliveryOrder.deliveryOrderId}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{props.deliveryOrder.deliveryOrderNumber}</Link></td>
      <td title={props.deliveryOrder.product}>{props.deliveryOrder.product}</td>
      <td>{props.deliveryOrder.priority}</td>
      <td>{displayQuantity(props.deliveryOrder.quantity)}</td>
      <td className="table-cell-right">
        <button onClick={handleAdd} type="button" className="add-button btn btn-warning fw-bold btn-sm"><span><FontAwesomeIcon size='lg' icon={faPlus} /></span> Add</button>
      </td>
    </tr>
  );
}
