import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Unauthorized() {
  const goTo = useNavigate();
  return (
    <div className="container app-container text-center pt-5">
      <h5 className="card-title display-4 text-danger">401 Unauthorized!</h5>
      <h6 className="card-subtitle mb-2 text-muted h4">Your authorization failed.</h6>
      <p className="card-text">Please click the below button to go back.</p>
      <button onClick={() => goTo('/')} className="btn btn-secondary btn-lg">Go Back</button>
    </div >
  )
}
