import { AxiosError } from 'axios';
import React from 'react';

export interface StringKeyBooleanValue {
    [key: string]: boolean
}

export default function useErrorHandlier() {
    const [error, setError] = React.useState<AxiosError | undefined>();


    const errorMessage = React.useMemo(() => {
        let message = error ? 'Something went wrong. Please try again later.': '';
        switch (error?.response?.status) {
            case 400:
                message = error.message;
                break;
            case 401:
                message = 'Invalid credentials.';
                break;
            case 500:
                message = 'Unexpected error occured.';
                break;
            default:
                message = 'Something went wrong. Please try again later.';
                break;
        }

        return message;
    }, [error])


    return {
        setError,
        errorMessage,
    };
}
