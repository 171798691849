import React from 'react';
import { faCancel, faCheck } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';


export function ChangePriorityModal(props: { priority: number, handleChange: (priorityValue: number) => void; }) {
  const [value, setValue] = React.useState(props.priority);
  
  const handleValueChange = (e: any) => {
    setValue(Number(e.target.value));
  }

  return (
    <div className="modal fade" id="ChangePriorityModal" tabIndex={-2} aria-labelledby="ChangePriorityModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ChangePriorityModal">Change Priority</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-check">
              <input onChange={handleValueChange} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="0" checked={value === 0} />
              <label className="form-check-label" htmlFor="exampleRadios1">
                Low
              </label>
            </div>
            <div className="form-check">
              <input onChange={handleValueChange} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="1" checked={value === 1} />
              <label className="form-check-label" htmlFor="exampleRadios2">
                Medium
              </label>
            </div>
            <div className="form-check">
              <input onChange={handleValueChange} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="2" checked={value === 2} />
              <label className="form-check-label" htmlFor="exampleRadios3">
                High
              </label>
            </div>
            <p className="p-2">NOTE: The new priority will only be reflected after delivery order is approved.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary fw-bold" data-bs-dismiss="modal"><Icon icon={faCancel}/> Cancel</button>
            <button type="button" onClick={() => props.handleChange(value)} data-bs-dismiss="modal" className="btn btn-warning fw-bold"><Icon icon={faCheck} /> Ok</button>
          </div>
        </div>
      </div>
    </div>
  );
}
