import React from 'react';



export function NoResultsFound({message}: {message:string}) {
  return (
      <div className="card-body text-center">
        <h5 className="card-title h5">{message}</h5>
    </div>);
}
