import React from "react";
import useAuth from "./useAuth";

export const useApiHandleError = (currentPage?: string) => {
    const [error, setError] = React.useState<any>();
    const [errorMessage, setErrorMessage] = React.useState('');
    const { setUpAuth } = useAuth();

    const getMessage = (error: any) => {
        if (error.response?.data?.message) return error.response.data.message;
        if (error.response?.data?.errors) return Object.values(error.response.data.errors)[0];
        return null;
    }

    React.useEffect(() => {
        if (error) {
            console.log(error);
            if (error.response?.status === 401 || error.response?.status === 403) {
                if (currentPage !== 'login') {
                    setUpAuth();
                    window.location.reload();
                }else{
                    setErrorMessage(getMessage(error) ?? 'Invalid Credentials');
                }
            } else if (error.response?.status === 400) {
                setErrorMessage(getMessage(error) ?? 'Bad request.');
            } else {
                setErrorMessage(getMessage(error) ?? 'Unexpected error.');
            }
        } else {
            setErrorMessage('');
        }
    }, [error, setUpAuth, currentPage]);

    return { errorMessage, setError };
}