import React from 'react'
import { UsersComponent } from './users/UsersComponent';
import { ProductsComponent } from './products/ProductsComponent';
import useFilters from '../../Shared/hooks/useFilters';
import { IUserDto } from 'appDtos';
import { ImportersComponent } from './Importers/ImportersComponent';
import { DistributorsComponent } from './Distributors/DistributorsComponent';
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { StorageTankComponent } from './storageTanks/StorageTankComponent';
import './Portal.css';

export interface CachedSearchResult {
    data?: IUserDto[];
    fromCache?: boolean;
}

enum FilterOptions {
    USERS = 'Users',
    PRODUCTS = 'Products',
    IMPORTERS = 'Importers',
    DISTRIBUTORS = 'Distributors',
    TANKS = 'Storage Tanks',
}

export default function Portal() {
    const goTo = useNavigate();
    const { filters, selectFilter } = useFilters(StorageKeys.AP_MENU, { [FilterOptions.USERS]: true, [FilterOptions.PRODUCTS]: false, [FilterOptions.IMPORTERS]: false, [FilterOptions.DISTRIBUTORS]: false, [FilterOptions.TANKS]: false });

    const activeClass = "list-group-item list-group-item-action fw-bold admin-portal-menu bg-warning";
    const inActiveClass = "list-group-item list-group-item-action admin-portal-menu";

    return (
        <div className="container app-container">
            <div className="d-flex justify-content-between page-heading">
                <div className="h4 text-dark">Admin Portal</div>
                <button onClick={() => goTo('/')} className="btn btn-warning fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faDashboard} /></span> Dashboard</button>
            </div>
            <div className="row">
                <div className="col-md-3 col-sm-12">
                    <ul className="list-group">
                        <li onClick={() => selectFilter(FilterOptions.USERS)} className={filters[FilterOptions.USERS] ? activeClass : inActiveClass}  >{FilterOptions.USERS}</li>
                        <li onClick={() => selectFilter(FilterOptions.PRODUCTS)} className={filters[FilterOptions.PRODUCTS] ? activeClass : inActiveClass}>{FilterOptions.PRODUCTS}</li>
                        <li onClick={() => selectFilter(FilterOptions.IMPORTERS)} className={filters[FilterOptions.IMPORTERS] ? activeClass : inActiveClass}>{FilterOptions.IMPORTERS}</li>
                        <li onClick={() => selectFilter(FilterOptions.DISTRIBUTORS)} className={filters[FilterOptions.DISTRIBUTORS] ? activeClass : inActiveClass}>{FilterOptions.DISTRIBUTORS}</li>
                        <li onClick={() => selectFilter(FilterOptions.TANKS)} className={filters[FilterOptions.TANKS] ? activeClass : inActiveClass}>{FilterOptions.TANKS}</li>
                    </ul>
                </div>
                <div className="col-md-9 col-sm-12">
                    {filters[FilterOptions.USERS] && <UsersComponent />}
                    {filters[FilterOptions.PRODUCTS] && <ProductsComponent />}
                    {filters[FilterOptions.IMPORTERS] && <ImportersComponent />}
                    {filters[FilterOptions.DISTRIBUTORS] && <DistributorsComponent />}
                    {filters[FilterOptions.TANKS] && <StorageTankComponent />}
                </div>
            </div>
        </div>
    );
}
