import { IDistributorDto } from 'appDtos';
import React from 'react';
import { ModalContext } from '../../../Shared/contexts/ModalContext';
import { DistributorDetail } from './DistributorDetail';

export function DistributorsList({ distributors, renderLoader }: { distributors: IDistributorDto[], renderLoader: () => JSX.Element | null }) {
    const { setModalData } = React.useContext(ModalContext);
    const setDistributorDetail = (id: any) => {
        setModalData({ element: <DistributorDetail id={id} /> });
    };

    return (
        <div className="results-container">
            {renderLoader()}
            {distributors.map(p => (
                <div className="d-flex justify-content-between align-items-center search-results-card" key={p.id}>
                    <div className="fw-bold mb-0">{p.name}</div>
                    <button onClick={() => setDistributorDetail(p.id)} type="button" className="btn btn-warning fw-bold btn-sm" data-bs-toggle="modal" data-bs-target="#sharedModal">Manage</button>
                </div>
            ))}
        </div>
    );
}
