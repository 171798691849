export const displayQuantity = (value: any) => {
    const qty = Number(value);
    if (isNaN(qty) || qty === 0) {
      return '0';
    }
  
    if (qty < 0) {
      return `(${(qty * -1).toLocaleString()})`;
    }
  
    return qty.toLocaleString();
  }