import React from 'react'
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

export enum AlertType {
    SUCCESS = 'success',
    DANGER = 'danger',
}

export interface AlertProps {
    type: string;
    message: string;
}

export default function Alert(props: AlertProps) {
    return (
        <div className={`alert alert-${props.type} d-flex align-items-center border border-${props.type}`} role="alert">
            <div>
                <Icon icon={props.type === AlertType.SUCCESS ? faCheckCircle : faExclamationTriangle} />
            </div>
            <div className="mx-2 fw-bold">
                {props.message}
            </div>
        </div>
    );
}
