import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import Icon from '../components/Icon';

export default function useChangeDetector() {
    const [hasChanged, setHasChange] = React.useState(false);
    const [original, setOriginal] = React.useState<any>();

    const checkChanges = React.useCallback((trackedValues: any) => {
        if (original) {
            setHasChange(JSON.stringify(trackedValues) !== original);
        }
    },[original]);

    const setInitialValues = (initialValues: any) => {
        setOriginal(JSON.stringify(initialValues));
    }

    const renderNoChangeComponnent = () => {
        if (original && !hasChanged) {
            return <div className="m-2"><Icon icon={faInfoCircle} color="blue"/> No changes has been made.</div>
        }
        return null;
    }

    return { hasChanged, checkChanges, setInitialValues, renderNoChangeComponnent }
}
