import { faPrint, faRefresh, faRotateBack } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { SingleDatePickerComponent } from '../../Shared/components/DatePicker';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import { reportType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { useMetric } from '../../Shared/hooks/useMetric';
import { usePrinterRef } from '../../Shared/hooks/usePrinterRef';
import { setMidnight } from '../../Shared/utils/date';
import { PrintingVersion } from './components/PrintingVersion';
import Toggle from './components/Toggle';
import { useReportExporter } from './components/useReportExporter';

interface Report {
    transferOrderNumber: string;
    importer: string;
    product: string;
    distributor: string;
    openingBalance: number;
    totalSales: number;
    closingBalance: number;
}

interface ByTransfer {
    transferOrderNumber: string;
    importer: string;
    distributor: string;
    product: string;
    pms: Balances;
    jet: Balances;
    ago: Balances;
}

interface ByImporter {
    transferOrderNumber?: string;
    importer: string;
    distributor: string;
    pms: Balances;
    jet: Balances;
    ago: Balances;
}

interface Balances {
    openingBalance: number;
    totalSales: number;
    closingBalance: number;
}

enum CODE {
    pms = 'pms',
    ago = 'ago',
    jet = 'jet',
}

export const DailyLoadingAnalysis = () => {
    const API = useApi();
    const { toggleUnit, isMetric, toMetricTonString } = useMetric('__tranAvaBal');
    const { errorMessage, setError } = useApiHandleError();
    const [loading, setLoading] = useState(false);
    const [groupBy, setGroupBy] = useState('1');
    const [refresh, setRefresh] = useState(false);
    const { componentRef, handlePrint } = usePrinterRef();
    const [date, setDate] = useState<Date | null>(new Date());
    const [detail, setDetail] = useState<{ importer: string, distributor: string } | null>(null);
    const { tableRef, Button } = useReportExporter({ sheet: reportType.DailyLoadingAnalysis });
    const [reports, setReports] = useState<{ byTransfers: ByTransfer[], byImporters: ByImporter[] } | null>(() => {
        const report = localStorage.getItem(StorageKeys.DAILY_LOADING);
        return report ? JSON.parse(report) : null;
    });

    const map: { [key: string]: string } = useMemo(() => ({
        "Premium Motor Spirit (PMS)": CODE.pms,
        "Automotive Gas Oil (AGO)": CODE.ago,
        "Jet Fuel": CODE.jet,
    }), []);

    const update = (bal1: Balances, bal2: Balances) => ({
        openingBalance: bal1.openingBalance + bal2.openingBalance,
        totalSales: bal1.totalSales + bal2.totalSales,
        closingBalance: bal1.closingBalance + bal2.closingBalance,
    });

    const set = useCallback((lookup: string, report: Report) => lookup === map[report.product] ? ({
        openingBalance: report.openingBalance,
        totalSales: report.totalSales,
        closingBalance: report.closingBalance,
    }) : ({
        openingBalance: 0,
        totalSales: 0,
        closingBalance: 0,
    }), [map]);

    const groupByImporter = useCallback((values: ByTransfer[]) => {
        const data: { [key: string]: ByImporter } = {};
        for (const value of values) {
            const k = `${value.importer}${value.distributor}`;
            const { importer, distributor, ago, pms, jet } = value;
            if (data[k]) {
                data[k].ago = update(data[k].ago, ago);
                data[k].pms = update(data[k].pms, pms);
                data[k].jet = update(data[k].jet, jet);
            } else {
                data[k] = { importer, distributor, ago, pms, jet };
            }
        }
        return Object.values(data);
    }, [])

    useEffect(() => {
        (async () => {
            if (!reports || refresh) {
                setLoading(true);
                errorMessage && setError('');
                try {
                    const res = await API.post<Report[]>('/Report/TransfersAvailableBalance', { date: setMidnight(date) });
                    const toMetricTonStringted = res.data.map(d => {
                        const updated = {
                            transferOrderNumber: d.transferOrderNumber,
                            importer: d.importer,
                            product: d.product,
                            distributor: d.distributor,
                            pms: set(CODE.pms, d),
                            ago: set(CODE.ago, d),
                            jet: set(CODE.jet, d),
                        };
                        return updated
                    });
                    const converted = { byTransfers: toMetricTonStringted, byImporters: groupByImporter(toMetricTonStringted) }
                    localStorage.setItem(StorageKeys.DAILY_LOADING, JSON.stringify(converted));
                    setReports(converted);
                } catch (error) {
                    setError(error)
                }
                setRefresh(false);
                setLoading(false);
            }
        })()
    }, [reports, refresh, API, setError, errorMessage, groupByImporter, map, set, date]);


    const handleDateChange = (date: Date | null) => {
        setDate(date);
        setRefresh(true);
    }

    const currentReport = (groupBy === '2' ? reports?.byTransfers : reports?.byImporters) ?? [];

    const { ago, pms, jet } = currentReport.reduce((r, e) => {
        r.ago.openingBalance += e.ago.openingBalance;
        r.ago.totalSales += e.ago.totalSales;
        r.ago.closingBalance += e.ago.closingBalance;

        r.pms.openingBalance += e.pms.openingBalance;
        r.pms.totalSales += e.pms.totalSales;
        r.pms.closingBalance += e.pms.closingBalance;

        r.jet.openingBalance += e.jet.openingBalance;
        r.jet.totalSales += e.jet.totalSales;
        r.jet.closingBalance += e.jet.closingBalance;
        return r;
    },
        {
            pms: { openingBalance: 0, totalSales: 0, closingBalance: 0 },
            jet: { openingBalance: 0, totalSales: 0, closingBalance: 0 },
            ago: { openingBalance: 0, totalSales: 0, closingBalance: 0 }
        } as { [key: string]: Balances });

    const reportTop = detail && reports?.byImporters.find(r => r.importer === detail?.importer && r.distributor === detail.distributor);
    const reportDetail = detail && reports?.byTransfers.filter(r => r.importer === detail?.importer && r.distributor === detail.distributor);

    const renderComponent = () => {
        return (
            <div className="p-0 loading-analysis table-responsive">
                {!Boolean(detail) && <table className="table table-sm m-0 border" ref={tableRef}>
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" colSpan={groupBy === '2' ? 4 : 3}></th>
                            <th scope="col" colSpan={3} className="border-start border-end text-center">PMS</th>
                            <th scope="col" colSpan={3} className="border-start border-end text-center">AGO</th>
                            <th scope="col" colSpan={3} className="border-start border-end text-center">JET</th>
                            {groupBy === '1' && <th scope="col" className="border-start border-end text-center noPrint"></th>}
                        </tr>
                        <tr>
                            <th className="fixed-width">#</th>
                            {groupBy === '2' && <th scope="col">TO#</th>}
                            <th scope="col" className="w-50">Importer</th>
                            <th scope="col" className="w-50">Distributor</th>
                            <th scope="col" className="border-start">Opening</th>
                            <th scope="col">Sales</th>
                            <th scope="col" className="border-end">Closing</th>
                            <th scope="col" className="border-start">Opening</th>
                            <th scope="col">Sales</th>
                            <th scope="col" className="border-end">Closing</th>
                            <th scope="col" className="border-start">Opening</th>
                            <th scope="col">Sales</th>
                            <th scope="col" className="border-end">Closing</th>
                            {groupBy === '1' && <th scope="col" className="border-start noPrint"></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {currentReport.map((t, i) => <tr key={i}>
                            <td className="fixed-width">{i + 1}.</td>
                            {groupBy === '2' && <td>{t.transferOrderNumber}</td>}
                            <td className="w-50">{t.importer}</td>
                            <td className="w-50">{t.distributor}</td>
                            <td className="border-start">{toMetricTonString(t.pms.openingBalance)}</td>
                            <td>{toMetricTonString(t.pms.totalSales)}</td>
                            <td className="border-end">{toMetricTonString(t.pms.closingBalance)}</td>
                            <td className="border-start">{toMetricTonString(t.ago.openingBalance)}</td>
                            <td>{toMetricTonString(t.ago.totalSales)}</td>
                            <td className="border-end">{toMetricTonString(t.ago.closingBalance)}</td>
                            <td className="border-start">{toMetricTonString(t.jet.openingBalance)}</td>
                            <td>{toMetricTonString(t.jet.totalSales)}</td>
                            <td className="border-end">{toMetricTonString(t.jet.closingBalance)}</td>
                            {groupBy === '1' && <td onClick={() => setDetail({ importer: t.importer, distributor: t.distributor })} className="border-end noPrint"><button className='btn btn-link btn-sm m-0 p-0'>Detail</button></td>}
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="col-1"></td>
                            <td className="fw-bold">Total</td>
                            {groupBy === '2' && <td></td>}
                            <td></td>
                            <td className="fw-bold border-start">{toMetricTonString(pms.openingBalance)}</td>
                            <td className="fw-bold">{toMetricTonString(pms.totalSales)}</td>
                            <td className="fw-bold border-end">{toMetricTonString(pms.closingBalance)}</td>
                            <td className="fw-bold border-start">{toMetricTonString(ago.openingBalance)}</td>
                            <td className="fw-bold">{toMetricTonString(ago.totalSales)}</td>
                            <td className="fw-bold border-end">{toMetricTonString(ago.closingBalance)}</td>
                            <td className="fw-bold border-start">{toMetricTonString(jet.openingBalance)}</td>
                            <td className="fw-bold">{toMetricTonString(jet.totalSales)}</td>
                            <td className="fw-bold border-end">{toMetricTonString(jet.closingBalance)}</td>
                        </tr>
                    </tfoot>
                </table>}
                {Boolean(detail) &&
                    <>
                        <div className='mt-3'>Summary</div>
                        <table className="table table-sm table-responsive m-0 border">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">PMS</th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">AGO</th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">JET</th>
                                </tr>
                                <tr>
                                    <th scope="col" className="w-50">Importer</th>
                                    <th scope="col" className="w-50">Distributor</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="w-50">{reportTop?.importer}</td>
                                    <td className="w-50">{reportTop?.distributor}</td>
                                    <td className="border-start">{toMetricTonString(reportTop?.pms.openingBalance)}</td>
                                    <td>{toMetricTonString(reportTop?.pms.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(reportTop?.pms.closingBalance)}</td>
                                    <td className="border-start">{toMetricTonString(reportTop?.ago.openingBalance)}</td>
                                    <td>{toMetricTonString(reportTop?.ago.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(reportTop?.ago.closingBalance)}</td>
                                    <td className="border-start">{toMetricTonString(reportTop?.jet.openingBalance)}</td>
                                    <td>{toMetricTonString(reportTop?.jet.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(reportTop?.jet.closingBalance)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='mt-3'>Detail</div>
                        <table className="table table-sm table-responsive m-0 border">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" colSpan={1}></th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">PMS</th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">AGO</th>
                                    <th scope="col" colSpan={3} className="border-start border-end text-center">JET</th>
                                </tr>
                                <tr>
                                    <th scope="col" className="w-50">Transfer#</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                    <th scope="col" className="border-start">Opening</th>
                                    <th scope="col">Sales</th>
                                    <th scope="col" className="border-end">Closing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportDetail?.map((t, i) => <tr key={i} className='align-start'>
                                    <td className="fixed-width">{t.transferOrderNumber}.</td>
                                    <td className="border-start">{toMetricTonString(t.pms.openingBalance)}</td>
                                    <td>{toMetricTonString(t.pms.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(t.pms.closingBalance)}</td>
                                    <td className="border-start">{toMetricTonString(t.ago.openingBalance)}</td>
                                    <td>{toMetricTonString(t.ago.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(t.ago.closingBalance)}</td>
                                    <td className="border-start">{toMetricTonString(t.jet.openingBalance)}</td>
                                    <td>{toMetricTonString(t.jet.totalSales)}</td>
                                    <td className="border-end">{toMetricTonString(t.jet.closingBalance)}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </>}
            </div>
        );
    }

    const renderPrintVersion = () => {
        const element = renderComponent();
        const props = [
            `${reportType.DailyLoadingAnalysis} Report`,
            detail ? 'Detail View' : groupBy === "1" ? 'Importer/Distributor View' : 'Transfer Orders View',
            date ? `Date: ${date.toLocaleDateString()}` : '',
        ];
        return <PrintingVersion
            headerProps={props}
            element={element} ref={componentRef}
            shouldRender={Boolean(reports)} />;
    }

    return (
        <>
            <div className="d-flex-jcb py-2 border my-3 noPrint">
                <Toggle checked={isMetric} label="Metric tonnes" handlier={toggleUnit} />
                <div className="d-flex-jcb">
                    <div className="d-flex-jcb">
                        {!!detail && <button
                            onClick={() => setDetail(null)}
                            className="btn btn-outline-dark fw-bold m-1 btn-sm">
                            <Icon icon={faRotateBack} color="dark" /> Back to summary
                        </button>}
                        <button
                            onClick={handlePrint}
                            className="btn btn-outline-dark fw-bold m-1 btn-sm">
                            <Icon icon={faPrint} color="dark" /> Print
                        </button>
                        {Button}
                        <button
                            onClick={() => setRefresh(true)}
                            className="btn btn-dark fw-bold m-1 btn-sm">
                            <Icon icon={faRefresh} color="white" /> Refresh
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex-jcb py-2 border my-3 noPrint">
                {!detail && <>
                    <div className="d-flex-jcb">
                        <div className="form-check form-check-inline pl-3">
                            <label className="fw-bold form-check-label" htmlFor="inlineRadio1">View By</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input onChange={(e) => setGroupBy(e.target.value)} checked={groupBy === "1"} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" />
                            <label className="form-check-label" htmlFor="inlineRadio1">Importer/Distributor</label>
                        </div>
                    </div>
                    <div className="form-check form-check-inline">
                        <input onChange={(e) => setGroupBy(e.target.value)} checked={groupBy === "2"} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Transfer Orders</label>
                    </div>
                </>}
                <div className="form-check form-check-inline">
                    <SingleDatePickerComponent placeholder="Select date" date={date} setDate={handleDateChange} />
                </div>
            </div>
            {Boolean(errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {loading ? <Loading /> : renderComponent()}
            {renderPrintVersion()}
        </>
    );
}

