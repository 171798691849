import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImporterDetailDto, IImporterDto, Inventory } from 'appDtos';
import { forwardRef, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/components/Loading';
import useApi from '../../Shared/hooks/useApi';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { scrollToTop } from '../../Shared/utils/windowUtil';
import { Field } from '../Administration/Distributors/DistributorDetail';
import React from 'react';
import { useReactToPrint } from 'react-to-print';

export function ImportersEntity() {
    const [showVeryDetail, setShowVeryDetail] = React.useState(false);
    const goTo = useNavigate()
    const [importers, setImporters] = React.useState<IImporterDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const [importer, setImporter] = React.useState<IImporterDetailDto>();
    const [selectedId, setSelectedId] = React.useState<number | undefined>(getInitialState);
    const [isLoadingImporter, setIsLoadingImporter] = React.useState(false);
    const [isLoadingImporters, setIsLoadingImporters] = React.useState(false);
    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => (componentRef as any)?.current,
    });

    useEffect(() => {
        if (!ref.current) {
            const fetchImporters = async () => {
                setIsLoadingImporters(true);
                const result = await axios.get<IImporterDto[]>(`Importer`);
                setImporters(result.data);
                setIsLoadingImporters(false);
            };
            fetchImporters();
        }
        return () => { ref.current = true; };
    }, [axios]);

    React.useEffect(() => {
        if (selectedId) {
            const fetchDetail = async () => {
                setIsLoadingImporter(true);
                const response = await axios.get<IImporterDetailDto>(`Importer/${selectedId}`);
                setImporter(response.data);
                window.history.replaceState(null, "LPRC", `/importers/list/#/${selectedId}`);
                setIsLoadingImporter(false);
            }
            fetchDetail();
        } else {
            window.history.replaceState(null, "LPRC", `/importers/list`);
        };
    }, [selectedId, axios]);

    const handleSelect = (id: number | undefined) => {
        setSelectedId(id);
    };

    React.useEffect(() => {
        setTimeout(() => scrollToTop(selectedId?.toString()), 1000);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="noPrint container app-container">
                <div className="d-flex justify-content-between">
                    <div className="h3 text-dark">Importers</div>
                    <button onClick={() => goTo('/')} className="btn btn-warning fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faDashboard} /></span> Dashboard</button>
                </div>
                {isLoadingImporters ? <Loading position="center" /> :
                    <div className="d-flex-jcs entity-content mt-3">
                        <div className="list-group col entity-list-items">
                            {importers.sort(compareImporter).map(i => (
                                <button type="button" id={i.id.toString()} onClick={() => handleSelect(i.id)} className={`list-group-item list-group-item-action ${i.id === selectedId ? 'bg-warning fw-bold' : ''}`} key={i.id}>{i.name}</button>
                            ))}
                        </div>
                        <div className={`entity-detail ${selectedId ? 'col-lg-8 col-md-8 -col-sm-12' : 'd-none'}`}>
                            <div className="card card-body">
                                <div className="heading">
                                    <div className="h5 text-dark mb-0">Importer Detail</div>
                                    <div>
                                        {Boolean(importer) && <>
                                            <button onClick={handlePrint} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">Print</button>
                                            <button onClick={() => setShowVeryDetail(!showVeryDetail)} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">{showVeryDetail ? 'Summary Only' : 'Show Detail'}</button>
                                        </>}
                                        <button onClick={() => setSelectedId(0)} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">Close</button>
                                    </div>
                                </div>
                                {isLoadingImporter ? <Loading position="center" size="3rem" /> :
                                    <>
                                        <div className="border rounded d-flex-jcs">
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Name" value={importer?.name ?? ''} />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Tax ID" value={importer?.taxIdentificationNumber ?? ''} />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Address" value={importer?.address ?? ''} />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Primary Phone" value={importer?.primaryPhone ?? ''} />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Primary Email" value={importer?.primaryEmail ?? ''} />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                                                <Field title="Status" value={importer?.isActive ? 'Active' : 'Inactive'} className={importer?.isActive ? "text-success" : "text-danger"} />
                                            </div>
                                        </div>
                                        <div className="h6 mt-2">Inventory Information</div>
                                        {importer?.inventories?.sort(compare).map(i =>
                                            <div key={i.inventoryId} className="card mb-2">
                                                <div className="card-body">
                                                    <div className="fw-bold">Tank# {i.storageTank.number} - {i.product.name}</div>
                                                    <div className="d-flex justify-content-between">
                                                        <div>Stock Balance</div><div>{displayQuantity(i.stockBalance)}</div>
                                                    </div>
                                                    {showVeryDetail && <>
                                                        <div className="fw-bold border-bottom mt-3">Additions</div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Incoming Transfers</div><div>{displayQuantity(i.pendingIncomingStockTransfersTotal)}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Incoming Shipments</div><div>{displayQuantity(i.pendingShipmentsTotal)}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Overages</div><div>{displayQuantity(i.pendingOveragesTotal)}</div>
                                                        </div>
                                                        <div className="fw-bold border-bottom mt-3">Reductions</div>

                                                        <div className="d-flex justify-content-between">
                                                            <div>Shortages</div><div>({displayQuantity(i.pendingShortagesTotal)})</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Outgoing Transfers</div><div>({displayQuantity(i.pendingOutGoingStockTransfersTotal)})</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div>Pending Delivery Orders</div><div>({displayQuantity(i.pendingTrackingOrdersTotal)})</div>
                                                        </div>
                                                    </>}
                                                    <div className="d-flex justify-content-between border-1 border-top border-bottom mt-3">
                                                        <div>Adjusted Balance</div><div className="fw-bold">{displayQuantity(i.adjustedStockBalance)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>}
            </div>
            <ComponentToPrint ref={componentRef} importer={importer} showVeryDetail={showVeryDetail} />
        </>
    );
}


type ComponentToPrintProps = {
    importer?: IImporterDetailDto;
    showVeryDetail: boolean;
}

export const ComponentToPrint = forwardRef<HTMLDivElement, ComponentToPrintProps>((props, ref) => {
    return props.importer ? <div ref={ref} className="noScreen bg-light p-4">
        <div className="heading">
            <div className="h5 text-dark mb-0">LPRC Petroleum Products Management System</div>
            <hr className="mt-0" />
            <div className="fw-bold">Importer Detail</div>
        </div>
        <div className="border rounded d-flex-jcs">
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Name" value={props.importer?.name ?? ''} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Tax ID" value={props.importer?.taxIdentificationNumber ?? ''} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Address" value={props.importer?.address ?? ''} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Primary Phone" value={props.importer?.primaryPhone ?? ''} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Primary Email" value={props.importer?.primaryEmail ?? ''} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <Field title="Status" value={props.importer?.isActive ? 'Active' : 'Inactive'} />
            </div>
        </div>
        <div className="fw-bold mt-4">Inventory Information</div>
        {props.importer?.inventories?.sort(compare).map(i =>
            <div key={i.inventoryId} className="card mb-2">
                <div className="card-body">
                    <div className="">Tank# {i.storageTank.number} - {i.product.name}</div>
                    <div className="fs-6 d-flex justify-content-between">
                        <div>Stock Balance</div><div>{displayQuantity(i.stockBalance)}</div>
                    </div>
                    {props.showVeryDetail && <>
                        <div className="border-bottom mt-3">Additions</div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Incoming Transfers</div><div>{displayQuantity(i.pendingIncomingStockTransfersTotal)}</div>
                        </div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Incoming Shipments</div><div>{displayQuantity(i.pendingShipmentsTotal)}</div>
                        </div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Overages</div><div>{displayQuantity(i.pendingOveragesTotal)}</div>
                        </div>
                        <div className="border-bottom mt-3">Reductions</div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Shortages</div><div>({displayQuantity(i.pendingShortagesTotal)})</div>
                        </div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Outgoing Transfers</div><div>({displayQuantity(i.pendingOutGoingStockTransfersTotal)})</div>
                        </div>
                        <div className="fs-6 d-flex justify-content-between">
                            <div>Pending Delivery Orders</div><div>({displayQuantity(i.pendingTrackingOrdersTotal)})</div>
                        </div>
                    </>}
                    <div className="d-flex justify-content-between border-1 border-top border-bottom mt-3">
                        <div className="">Adjusted Balance</div><div className="fw-bold">{displayQuantity(i.adjustedStockBalance)}</div>
                    </div>
                </div>
            </div>
        )}
    </div> : null;
})


export function getInitialState() {
    if (window?.location?.hash) {
        const id = Number(window.location.hash.replace('#/', ''));
        return id ? id : 0;
    }
    return 0;
};

function compare(a: Inventory, b: Inventory) {
    if (a.product.name < b.product.name) {
        return -1;
    }
    if (a.product.name > b.product.name) {
        return 1;
    }
    return 0;
}

export function compareImporter(a: IImporterDto, b: IImporterDto) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}