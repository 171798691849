import React, { useEffect, useRef, useState } from 'react';
import './ControlInputField.css';
import RequiredText from './RequiredText';

export interface NameValuePair {
    [name: string]: any
}
export interface ControlInputFieldProps {
    onChange: (e: any) => void;
    label: string;
    placeholder: string;
    defaultVaule?: string;
    type: string;
    value: string | number | undefined;
    id: string;
    name: string;
    autoComplete?: string;
    focusOnMount?: boolean;
    required?: boolean;
    validationFunction?: (value: any) => boolean;
    onFieldError?: (nameValuePair: NameValuePair) => any
}

const ControlInputField = (props: ControlInputFieldProps) => {
    const fieldRef = useRef<any>();
    const [validationError, setValidatioError] = useState('');

    useEffect(() => {
        if (props.focusOnMount && fieldRef?.current) {
            fieldRef.current.focus();
        }
    }, [props.focusOnMount]);

    useEffect(() => {
        if (props.onFieldError) {
            let hasError = false;

            if (props.validationFunction && !props.validationFunction(props.value)) {
                hasError = true;
            }

            props.onFieldError({ [props.name]: hasError });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, validationError]);

    const handleChange = (e:any)=>{
        setValidatioError('');
        props.onChange({ [props.name]: e.target.value ? e.target.value : null });
    }

    const handleOnBlur = () => {
        if (props?.validationFunction && !props.validationFunction(props.value)) {
            setValidatioError(`${props.label} is invalid.`);
        } else {
            setValidatioError('');
        }
    }

    const classNames = validationError ? "form-control border-danger" : "form-control";

    return (
        <div className="mb-2">
            <label
                htmlFor={props.id}
                className="form-label fw-bold"
            >
                {props.label} {props.required && <RequiredText />}
            </label>
            <input
                onChange={handleChange}
                type={props.type}
                value={props.value ?? props.defaultVaule ?? ''}
                name={props.name}
                className={classNames}
                autoComplete={props.autoComplete ?? "off"}
                placeholder={props.placeholder}
                id={props.id}
                aria-describedby={`${props.label} help`}
                ref={fieldRef}
                required={props.required ?? false}
                onBlur={handleOnBlur}
            />
            <small id={props.id} className="form-text text-danger">{validationError}</small>
        </div>
    )
}

export default ControlInputField
