import React from 'react'
import useAuth from '../../Shared/hooks/useAuth';
import Login from '../Auth/Components/Login';
import { ILoginResponse } from 'appDtos';
import { AppDashboard } from './Dashboard';
import './Home.css';

export default function Home() {
    const { setUpAuth, isAuthenticated } = useAuth();
    
    const handleLoginSubmit = (loginResponse?: ILoginResponse) => {
        setUpAuth(loginResponse);
    }

    if (isAuthenticated()) {        
        return <AppDashboard />;
    }

    return (
        <div className="container-fluid home-container">
            <h1 className="home-app-name">Petroleum Products Management System</h1>
            <div className="home-horizontal-separator"></div>
            <Login setUpAuth={handleLoginSubmit} />
        </div>
    );
}
