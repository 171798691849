import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImporterDetailDto } from 'appDtos';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Loading from '../../../Shared/components/Loading';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useMode } from '../../../Shared/hooks/useMode';
import { formatDate } from '../../../Shared/utils/date';
import { EditImporter } from './EditImporter';


export function ImporterDetail({ id }: { id: string; }) {
    const [importer, setImporter] = React.useState<IImporterDetailDto>({} as IImporterDetailDto);
    const axios = useApi();
    const { mode, updateMode } = useMode();

    React.useEffect(() => {
        const fetchDetail = async () => {
            if (id) {
                const response = await axios.get<IImporterDetailDto>(`Importer/${id}`);
                setImporter(response.data);
            }
        };
        fetchDetail();
    }, [id, axios, mode]);

    if (!importer.id) {
        return <Loading position="center" />
    }

    return mode === Mode.DETAIL ? (
        <div>
            <HeadingElement text="Importer Detail" />
            <div className="p-2">
                <Field title="Name" value={importer.name} />
                <Field title="Tax ID" value={importer.taxIdentificationNumber} />
                <Field title="Registration Date" value={importer.registrationDate? formatDate(importer.registrationDate) :'NA'} />
                <Field title="Address" value={importer.address} />
                <Field title="Primary Phone" value={importer.primaryPhone} />
                <Field title="Primary Email" value={importer.primaryEmail} />
                <Field title="Secondary Phone" value={importer.secondaryPhone  ?? 'NA'} />
                <Field title="Secondary Email" value={importer.secondaryEmail  ?? 'NA'} />
                <Field title="Contact Person" value={importer.contactPerson  ?? 'NA'} />
                <Field title="Status" value={importer.isActive ? 'Active' : 'Inactive'} className={importer.isActive ? "text-success" : "text-danger"} />
            </div>
            <HeadingElement text="Inventory Information" />
            <div className="list-group">
                {importer.inventories.length > 0 ? importer.inventories?.map(i =>
                    <div key={i.inventoryId} className="list-group-item list-group-item" aria-current="true">
                        <div className="fw-bold">
                            Tank #{i.storageTank.number}
                        </div>
                        <p className="mb-1">{i.product.name}</p>
                    </div>):  <p className="mb-1">No inventory information has been added for this importer.</p>}
            </div>
            <button onClick={() => updateMode(Mode.EDIT)} className="btn btn-warning fw-bold mt-4"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faEdit} /></span> Edit Importer</button>
        </div>
    ) : <EditImporter importer={importer} setMode={updateMode} />;
}


function Field({ title, value, className }: { title: string, value: string, className?: string }) {
    return (
        <div className="mb-3">
            <div className="">{title}</div>
            <div className={className ? `fw-bold ${className}`: 'fw-bold'}>{value}</div>
        </div>
    )
}

