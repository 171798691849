import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import Icon from '../../../Shared/components/Icon';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

export const useReportExporter = ({ sheet }: { sheet: string }) => {
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'PPMS Report',
        sheet
    });

    const Button = (
        <button
            className="btn btn-outline-dark fw-bold m-1 btn-sm"
            onClick={onDownload}>
            <Icon icon={faFileExport} color='dark' />
             Export
        </button>
    );

    return {
        tableRef,
        Button
    }
}