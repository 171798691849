import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { IDeliveryOrderDto, ILoadingRequestDto } from 'appDtos';
import { Link } from 'react-router-dom';
import ModalToggleButton from '../../Shared/components/ModalToggleButton';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';

interface TransferOrderTableProps {
    transferOrders: ILoadingRequestDto[];
    setTransferOrderId: (id: number) => void;
    showInventoryUpdate: boolean
}

export default function TransferOrderTable({ transferOrders, showInventoryUpdate, setTransferOrderId }: TransferOrderTableProps) {

    return (
        <div className="table-responsive">
            <table className="table table-responsive table-sm">
                <thead>
                    <tr>
                        <th scope="col">Transfer Order #</th>
                        <th scope="col">Importer</th>
                        <th scope="col">Distributor</th>
                        <th scope="col" title='This is the quantity from this delivery order'>Quantity</th>
                        <th scope="col">Status</th>
                        <th colSpan={showInventoryUpdate ? 2 : 1} scope="col">Inventory</th>
                    </tr>
                </thead>
                <tbody>
                    {transferOrders.map((t) => (
                        <tr key={t.id}>
                            <td className="do-table-cell"><Link to={`/transfer-order-detail/${t.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{t.transferOrderNumber}</Link></td>
                            <td className="do-table-cell">{t.importer}</td>
                            <td className="do-table-cell">{t.distributor}</td>
                            <td className="do-table-cell">{displayQuantity(t.quantity)}</td>
                            <td className="do-table-cell">{t.status}</td>
                            <td className={`do-table-cell ${t.inventoryInfo === 'Pending' ? 'text-danger fw-bold': ''}`}>{t.inventoryInfo}</td>
                            {showInventoryUpdate && <td className="table-cell-right"><ModalToggleButton size='sm' modalId="#InventoryUpdateModal" handleClick={() => setTransferOrderId(t.id)} value="Update Tank" icon={faEdit} /></td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

interface DeliveryOrderTableProps {
    deliveryOrders: IDeliveryOrderDto[];
}

export function DeliveryOrderTable({ deliveryOrders }: DeliveryOrderTableProps) {
    return (
        <div className="table-responsive">
            <table className="table table-responsive-sm w-100">
                <thead>
                    <tr>
                        <th scope="col">Delivery Order #</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Created By</th>
                        <th scope="col" title='This is the quantity from this delivery order'>Quantity</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {deliveryOrders.map((d) => (
                        <tr key={d.id}>
                            <td className="do-table-cell">
                                <Link to={`/transfer-order-detail/${d.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{d.deliveryOrderNumber}</Link>
                            </td>
                            <td className="do-table-cell">{formatDate(d.submittedDate)}</td>
                            <td className="do-table-cell">{d.submittedBy}</td>
                            <td className="do-table-cell">{displayQuantity(d.quantity)}</td>
                            <td className="do-table-cell">{d.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
