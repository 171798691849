import { IRoleDto } from 'appDtos';
import React, { useContext } from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import { UserDetailContext } from '../../../Shared/contexts/UserDetailProvider';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import { useLoader } from '../../../Shared/hooks/useLoader';

export interface UpdateUserRole {
  name?: string,
  isSelected: boolean,
  hasPermissions: boolean
}

interface Props {
  userId?: string;
  isInternal: boolean;
  roles: IRoleDto[];
  cancel: () => void;
  setHasPermissions: (v: boolean) => void;
}

export function RolesManagement({ userId, roles, cancel, setHasPermissions}: Props) {
  const [rolesResult, setRolesResult] = React.useState<UpdateUserRole[]>([]);
  const axios = useApi();
  const ref = React.useRef(false);
  const [changeDetected, setChangeDetected] = React.useState(false);
  const { triggerRefresh } = useContext(UserDetailContext);
  const [showSavedText, setShowSavedText] = React.useState(false);
  const { isLoading, renderLoader, setIsLoading } = useLoader();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { errorMessage, setError } = useApiHandleError();
  
  React.useEffect(() => {
    if (!ref.current) {
      const fetchRoles = async () => {
        setIsLoading(true);
        try {
          const result = await axios.get<IRoleDto[]>(`Role/User/${userId}`);
          const roleNames = roles.map(r => r.id);
          setRolesResult(result.data.map(d => ({ ...d, name: d.name, isSelected: roleNames.includes(d.id) })));
        } catch (error: any) {
          setError(error);
        }
        setIsLoading(false);
      }
      fetchRoles();
    }

    return () => { ref.current = true; };
  }, [axios, roles, userId, setError, setIsLoading]);

  React.useEffect(() => {
    if (rolesResult?.length) {
      setHasPermissions(rolesResult.find(r => r.isSelected)?.hasPermissions ?? false);
    }
  }, [rolesResult, setHasPermissions]);

  const handleOnChange = (role: UpdateUserRole) => {
    if (!changeDetected) {
      setChangeDetected(true);
      setShowSavedText(false);
    }
    setRolesResult(rolesResult.map(r => ({ ...r, isSelected: r.name === role.name && !role.isSelected })));
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    setError('');
    try {
      await axios.post('/Administration/UpdateUserRole', { userId, userRoles: rolesResult });
      triggerRefresh();
      setChangeDetected(false);
      setShowSavedText(true);
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  };

  const btnText = showSavedText ? 'Saved' : 'Save Changes';
  const cancelBtnText = btnText === 'Saved' ? 'Done': 'Cancel'

  return (
    <div>
      {isLoading && renderLoader({text: 'Loading roles...', position: 'left'})}
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      <div className="list-group">
        {!isLoading && rolesResult?.map(role => (
          <label className="list-group-item mr-2" key={role.name}>
            <input 
              onChange={() => handleOnChange(role)} 
              className="form-check-input me-1 mr-3" 
              type="checkbox" 
              checked={role.isSelected} 
            />
            {role.name}
          </label>
        ))}
      </div>
      <button onClick={cancel} type="button" className="btn btn-dark btn-sm fw-bold">{cancelBtnText}</button>
      <button onClick={handleSubmit} type="button" className={`btn btn-${showSavedText ? 'success' : 'warning'} fw-bold btn-sm m-2`} disabled={!changeDetected}>
        {isProcessing && <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>}  {isProcessing ? 'Saving...' : btnText}
      </button>
    </div>
  );
}
