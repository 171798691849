import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DateRange {
    from: Date | null;
    to: Date | null;
}

export interface StringDateRange {
    from: string | null;
    to: string | null;
}

interface DatePickerProps {
    dateRange: DateRange;
    setDate: (dateRange: DateRange) => void;
    placeholder: string;
}

export function DateRangePickerComponent({ placeholder, dateRange, setDate }: DatePickerProps) {
    const CustomInput = forwardRef((props: any, ref: any) => {
        return (
            <input
                className="form-control form-control-sm"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                ref={ref}
                placeholder={placeholder}
                aria-label=".form-control-lg example"
            >
            </input>
        )

    });

    const onStartDateChange = (date: Date | null) => {
        setDate({ ...dateRange, from: date })
    };
    const onEndDateChange = (date: Date | null) => {
        setDate({ ...dateRange, to: date })
    };

    return (
        <div className="d-flex justify-content-start">
            <div className="d-flex justify-content-between align-items-center">
                <div className="mx-1 fw-bold">From:</div>
                <DatePicker
                    isClearable={Boolean(dateRange.from)}
                    selected={dateRange.from}
                    onChange={onStartDateChange}
                    selectsStart
                    customInput={<CustomInput />}
                    startDate={dateRange.from}
                    maxDate={dateRange.to ?? new Date()}
                    placeholderText="Select start date." />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="mx-1 fw-bold">To:</div>
                <DatePicker
                    isClearable={Boolean(dateRange.to)}
                    selected={dateRange.to}
                    customInput={<CustomInput />}
                    onChange={onEndDateChange}
                    selectsEnd
                    startDate={dateRange.from}
                    endDate={dateRange.to}
                    minDate={dateRange.from}
                    maxDate={new Date()}
                    placeholderText="Select end date." />
            </div>
        </div>
    );
}

export function SingleDatePickerComponent({ placeholder, date, setDate }: { placeholder: string, date: Date | null, setDate: (date: Date | null) => void }) {
    const CustomInput = forwardRef((props: any, ref: any) => {
        return (
            <input
                className="form-control form-control-sm"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                ref={ref}
                placeholder={placeholder}
                aria-label=".form-control-lg example"
            >
            </input>
        )

    });

    return <DatePicker
        isClearable={Boolean(date)}
        customInput={<CustomInput />}
        selected={date}
        maxDate={new Date()}
        onChange={(date: Date | null) => setDate(date)}
    />
}
