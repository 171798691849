import { IStorageTankDto } from 'appDtos';
import React from 'react';
import { ModalContext } from '../../../Shared/contexts/ModalContext';
import useApi from '../../../Shared/hooks/useApi';
import { useLoader } from '../../../Shared/hooks/useLoader';
import { StorageTankDetail } from "./StorageTankDetail";


export function StorageTankList() {
    const [tanks, setStorageTanks] = React.useState<IStorageTankDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const { setModalData } = React.useContext(ModalContext);
    const { setIsLoading, renderLoader } = useLoader();

    const setStorageTankDetail = (id: any) => {
        setModalData({ element: <StorageTankDetail id={id} /> });
    };

    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                setIsLoading(true);
                try {
                    const result = await axios.get<IStorageTankDto[]>(`StorageTank`);
                    setStorageTanks(result.data);
                } catch (error) {

                }
                setIsLoading(false);
            };
            fetch();
        }
        return () => { ref.current = true; };
    }, [axios, setIsLoading]);

    return (
        <div className="results-container">
            {renderLoader()}
            {tanks.map(t => (
                <div className="d-flex justify-content-between align-items-center search-results-card" key={t.id}>
                    <div className="fw-bold mb-0">Tank #{t.number} {t.productName}</div>
                    <button onClick={() => setStorageTankDetail(t.id)} type="button" className="btn btn-warning fw-bold btn-sm" data-bs-toggle="modal" data-bs-target="#sharedModal">Manage</button>
                </div>
            ))}
        </div>
    );
}
