import React from 'react';
import { faBucket, faDashboard } from '@fortawesome/free-solid-svg-icons';
import { EditStockTransferRequest, Inventory, StockTransferEditInfoDto } from 'appDtos';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../Shared/hooks/useApi';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import RequiredText from '../../Shared/components/RequiredText';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import Loading from '../../Shared/components/Loading';
import useChangeDetector from '../../Shared/hooks/useChangeDetector';
import { SingleDatePickerComponent } from '../../Shared/components/DatePicker';
import "./updateStockTransfer.css";

interface ImporterInventoryInfoData {
  id: number;
  name: string;
  inventories: Inventory[];
}

export function EditStockTransfer() {
  const goTo = useNavigate();
  const axios = useApi();
  const [importers, setImporters] = React.useState<ImporterInventoryInfoData[]>([]);
  const ref = React.useRef(false);
  const [fromImporterId, setFromImporterId] = React.useState(0);
  const [toImporterId, setToImporterId] = React.useState(0);
  const [formError, setFormError] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const { id } = useParams();
  const { hasChanged, setInitialValues, checkChanges, renderNoChangeComponnent } = useChangeDetector();
  const path = 'stockTransfer';
  const {
    handleOnChange,
    setIsProcessing,
    isProcessing,
    formData,
  } = useFormProcessor<EditStockTransferRequest>({
    stockTransferId: 0,
    createdDate: null,
    fromInventoryId: 0,
    toInventoryId: 0,
    quantity: 0,
    referenceNumber: '',
  });

  React.useEffect(() => {
    checkChanges({
      fromInventoryId: formData.fromInventoryId,
      toInventoryId: formData.toInventoryId,
      referenceNumber: formData.referenceNumber,
      quantity: formData.quantity,
    });
  }, [formData, checkChanges])

  React.useEffect(() => {
    if (!ref.current && !formData.stockTransferId) {
      const fetch = async () => {
        setIsProcessing(true);
        try {
          const result = await axios.get<StockTransferEditInfoDto>(`${path}/${id}/GetEditInfo`);
          if (result?.data) {
            const { importers, stockTransfer } = result?.data;
            const values = {
              createdDate: new Date(stockTransfer.submittedDate),
              fromInventoryId: stockTransfer.fromInventory.inventoryId,
              toInventoryId: stockTransfer.toInventory.inventoryId,
              referenceNumber: stockTransfer.referenceNumber,
              quantity: stockTransfer.quantity,
            };
            handleOnChange({ id: stockTransfer.id, ...values });
            setImporters(importers.map(({ id, name, inventories }) => ({ id, name, inventories })));
            setInitialValues(values);
          }
        } catch (error) {
          setError(error);
        }
        setIsProcessing(false);
      }

      fetch();
    }

    return () => {
      ref.current = true;
    };
  }, [axios, setIsProcessing, importers, setInitialValues, setError, formData.stockTransferId, handleOnChange, id]);

  React.useEffect(() => {
    setFormError('');
  }, [formData]);

  React.useEffect(() => {
    if (importers.length && formData.fromInventoryId && formData.toInventoryId) {
      setFromImporterId(importers.find(i => i.inventories.some(inv => inv.inventoryId === formData.fromInventoryId))?.id ?? 0);
      setToImporterId(importers.find(i => i.inventories.some(inv => inv.inventoryId === formData.toInventoryId))?.id ?? 0);
    }
  }, [importers, formData.fromInventoryId, formData.toInventoryId]);

  const getInventory = (importerId: number, inventoryId: number) => importers
    .find(i => i.id === importerId)?.inventories
    .find(i => i.inventoryId === inventoryId);

  const handleSubmit = async () => {
    //Check required fields.
    const isValid = !!Number(formData.fromInventoryId) &&
      !!Number(formData.toInventoryId) &&
      !!Number(formData.quantity);
    if (!isValid) return setFormError('Please enter all required fields.');
    // Check invalid tranfer setup
    if (formData.fromInventoryId === formData.toInventoryId) return setFormError('Origin and destination inventories can not be the same.');
    const fromInventory = getInventory(fromImporterId, formData.fromInventoryId);
    const toInventory = getInventory(toImporterId, formData.toInventoryId);
    // Check inventory was selected
    if (!fromInventory || !toInventory) return setFormError('Please enter origin and destination inventory.');
    // Check same product required.
    if (fromInventory?.productName !== toInventory?.productName) return setFormError('Origin and destination inventories product must be the same.');

    setIsProcessing(true);

    try {
      await axios.put(path, { ...formData, createdDate: (formData.createdDate as Date).toISOString() });
      return goTo('/stock-transfer/list');
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const handleReferenceNumberChange = (e: any) => handleOnChange({ referenceNumber: e.target.value });
  const handleQuantityChange = (e: any) => handleOnChange({ quantity: Number(e.target.value) });
  //Importer select
  const handleFromImporterChange = (e: any) => setFromImporterId(Number(e.target.value));
  const handleToImporterChange = (e: any) => setToImporterId(Number(e.target.value));
  // Inventory select
  const handleFromInventorySelect = (e: any) => handleOnChange({ fromInventoryId: Number(e.target.value) });
  const handleToInventorySelect = (e: any) => handleOnChange({ toInventoryId: Number(e.target.value) });
  const handleDateChange = (date: any) => handleOnChange({ createdDate: date });

  const filterInventories = (importerId: number) => importers
    .find(i => i.id === importerId)
    ?.inventories ?? [];

  const resetInventory = () => {
    handleOnChange({ ...formData, fromInventoryId: 0, toInventoryId: 0 });
    setFromImporterId(0);
    setToImporterId(0);
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Edit Stock Transfer Request</div>
        <div className="d-flex-jce">
          <NavigationButton link="/stock-transfer/list" icon={faBucket} label="Stock Transfer Requests" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Processing..." position="center" />}
      {(!!formError || !!errorMessage) && <Alert type={AlertType.DANGER} message={formError || errorMessage} />}
      {!isProcessing && <>
        <div className="col mx-1 my-2">
          <label
            htmlFor="createdDate"
            className="form-label fw-bold"
          >
            Submitted Date <RequiredText />
          </label>
          <br />
          <SingleDatePickerComponent placeholder="Select Date" date={formData.createdDate} setDate={handleDateChange} />
        </div>
        <div className="col mx-1 my-2">
          <label
            htmlFor="referenceNumber"
            className="form-label fw-bold"
          >
            Reference Number
          </label>
          <input
            onChange={handleReferenceNumberChange}
            type="text"
            value={formData.referenceNumber}
            autoComplete="off"
            name="referenceNumber"
            className="form-control"
            placeholder="Enter reference number"
            id="referenceNumber"
            aria-describedby="Reference Number field"
          />
        </div>
        <div className="col">
          <label
            htmlFor="quantity"
            className="form-label fw-bold"
          >
            Quantity <RequiredText />
          </label>
          <input
            onChange={handleQuantityChange}
            type="text"
            value={formData.quantity}
            name="quantity"
            className="form-control"
            placeholder="Enter quantity"
            id="quantity"
            aria-describedby="quantity field"
            required
          />
        </div>
        <div className="form-section-heading">Origin</div>
        <div className="col my-2">
          <div className="form-label fw-bold">Importer <RequiredText /></div>
          <select className="form-select" aria-label="Default From Importer" value={fromImporterId} onChange={handleFromImporterChange}>
            <option value={0}>Select Importer</option>
            {importers.map((i) => <option value={i.id} key={i.id}>{i.name}</option>)}
          </select>
        </div>
        <div className="col my-2">
          <div className="form-label fw-bold">Inventory <RequiredText /></div>
          <select className="form-select" aria-label="Default From Importer Inventory" value={formData.fromInventoryId} onChange={handleFromInventorySelect}>
            <option value={0}>Select Inventory</option>
            {filterInventories(fromImporterId).map((i) => <option value={i.inventoryId} key={i.inventoryId}>{`Tank# ${i.storageTankNumber} ${i.productName}`}</option>)}
          </select>
        </div>
        <div className="form-section-heading">Destination</div>
        <div className="col my-2">
          <div className="form-label fw-bold">Importer <RequiredText /></div>
          <select className="form-select" aria-label="Default To Importer" value={toImporterId} onChange={handleToImporterChange}>
            <option value={0}>Select Importer</option>
            {importers.map((i) => <option value={i.id} key={i.id}>{i.name}</option>)}
          </select>
        </div>
        <div className="col my-2">
          <div className="form-label fw-bold">Inventory <RequiredText /></div>
          <select className="form-select" aria-label="Default To Importer Inventory" value={formData.toInventoryId} onChange={handleToInventorySelect}>
            <option value={0}>Select Inventory</option>
            {filterInventories(toImporterId).map((i) => <option value={i.inventoryId} key={i.inventoryId}>{`Tank# ${i.storageTankNumber} ${i.productName}`}</option>)}
          </select>
        </div>
        {renderNoChangeComponnent()}
        <button className="btn btn-warning btn-sm fw-bold" onClick={resetInventory}>Reset Inventory</button>
        <div className="page-action-button-group">
          <BackButton disabled={isProcessing} />
          {hasChanged && <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing} />}
        </div>
      </>}
    </div>
  );
}
