import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IStorageTankDetailDto } from 'appDtos';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Loading from '../../../Shared/components/Loading';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useMode } from '../../../Shared/hooks/useMode';
import { Field } from '../Distributors/DistributorDetail';
import { EditStorageTank } from './EditStorageTank';


export function StorageTankDetail({ id }: { id: string; }) {
    const [storageTank, setStorageTank] = React.useState<IStorageTankDetailDto>({} as IStorageTankDetailDto);
    const axios = useApi();
    const {mode, updateMode} = useMode();

    React.useEffect(() => {
            const fetch = async () => {
                if (id) {
                    const response = await axios.get<IStorageTankDetailDto>(`StorageTank/${id}`);
                    setStorageTank(response.data);
                }
            };
            fetch();
    }, [id, axios, mode]);

    if (!storageTank) {
        return <Loading position="center" />
    }

    return mode === Mode.DETAIL ? (
        <div>
        <HeadingElement text="Storage Tank Detail" />
        <div className="p-2">
            <Field title="Storage Tank Number" value={`${storageTank?.number}`} />
            <Field title="Product" value={storageTank?.product?.name} />
        </div>
        <button onClick={() => updateMode(Mode.EDIT)} className="btn btn-warning fw-bold mt-4"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faEdit} /></span> Edit Storage Tank</button>
    </div>
    ) : <EditStorageTank storageTank={storageTank} setMode={updateMode}/>;
}
