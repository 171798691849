import { faDashboard, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDistributorDetailDto, IDistributorDto } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/components/Loading';
import useApi from '../../Shared/hooks/useApi';
import { Field } from '../Administration/Distributors/DistributorDetail';
import { getInitialState } from './ImportersEntity';

export function DistributorsEntity() {
    const goTo = useNavigate()
    const [distributors, setDistributors] = React.useState<IDistributorDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const [showDetail, setShowDetail] = React.useState(false);
    const [distributor, setDistributor] = React.useState<IDistributorDetailDto>();
    const [selectedId, setSelectedId] = React.useState<number | undefined>(getInitialState);
    const [isLoadingDistributor, setIsLoadingDistributor] = React.useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = React.useState(false);

    React.useEffect(() => {
        if (!ref.current) {
            const fetchDistributors = async () => {
                setIsLoadingDetail(true);
                const result = await axios.get<IDistributorDto[]>(`Distributor`);
                setDistributors(result.data);
                setIsLoadingDetail(false);
            };
            fetchDistributors();
        }
        return () => { ref.current = true; };
    }, [axios]);


    React.useEffect(() => {
        if (selectedId) {
            const fetchDetail = async () => {
                setIsLoadingDistributor(true);
                setShowDetail(true);
                const response = await axios.get<IDistributorDetailDto>(`Distributor/${selectedId}`);
                setDistributor(response.data);
                window.history.replaceState(null, "LPRC", `/distributors/list/#/${selectedId}`);
                setIsLoadingDistributor(false);
            }
            fetchDetail();
        };
    }, [selectedId, axios]);


    const handleSelect = (id: number | undefined) => {
        setSelectedId(id)
    };

    const closeDetail = () => {
        setShowDetail(false);
    };


    return (
        <div className="container app-container">
            <div className="d-flex justify-content-between">
                <div className="h4 text-dark">Distributors</div>
                <button onClick={() => goTo('/')} className="btn btn-warning fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faDashboard} /></span> Dashboard</button>
            </div>
            {isLoadingDetail ? <Loading position="center" /> : <div className="d-flex-jcs entity-content mt-3">
                <div className="list-group col entity-list-items">
                    {distributors.sort(compareDistributor).map(i => (
                        <button type="button" onClick={() => handleSelect(i.id)} className={`list-group-item list-group-item-action ${i.id === selectedId ? 'bg-warning fw-bold' : ''}`} key={i.id}>{i.name}</button>
                    ))}
                </div>
                <div className={`col entity-detail ${showDetail ? '' : 'd-none'}`}>
                    <div className="card card-body">
                        <div className="heading">
                            <div className="h6 text-dark mb-0">Distributor Detail</div>
                            <button onClick={closeDetail} type="button" className="btn btn-light fw-bold btn-md"><span><FontAwesomeIcon size='lg' icon={faXmark} /></span></button>
                        </div>
                        {isLoadingDistributor ? <Loading position="center" size="3rem" /> :
                            <div className="p-2">
                                <Field title="Name" value={distributor?.name ?? ''} />
                                <Field title="Tax ID" value={distributor?.taxIdentificationNumber ?? ''} />
                                <Field title="Address" value={distributor?.address ?? ''} />
                                <Field title="Primary Phone" value={distributor?.primaryPhone ?? ''} />
                                <Field title="Primary Email" value={distributor?.primaryEmail ?? ''} />
                                <Field title="Status" value={distributor?.isActive ? 'Active' : 'Inactive'} className={distributor?.isActive ? "text-success" : "text-danger"} />
                            </div>
                        }
                    </div>
                </div>
            </div>}
        </div>
    );
}

function compareDistributor(a: IDistributorDto, b: IDistributorDto) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}