export const isValidEmail = (email: string | number | undefined) => {
    if (typeof email === 'string') return /\S+@\S+\.\S+/.test(email);
    return false;
};


export const hasValue = (value: string | number | undefined): boolean => {
    if (typeof value === 'string') return value.trim().length > 0;
    if (typeof value === 'number') return value > 0;
    return false;
};

const isLength = (value: string, requiredLength: number): boolean => (value?.trim().length ?? 0) >= requiredLength;
const hasSpecialChars = (value: string): boolean => /[!@#$]/.test(value);
const hasDigit = (value: string): boolean => /[0-9]/.test(value);
const hasUpperCase = (value: string): boolean => /[A-Z]/.test(value);
const hasMultipe = (value: string): boolean => value?.split('')
    .reduce((r: string[], e) => r.includes(e) ? r : [...r, e], [])
    .length >= 3 ?? false;


export const passwordValidators = {
    isLength,
    hasSpecialChars,
    hasDigit,
    hasUpperCase,
    hasMultipe
}

export const isValidPassword = (value: any): boolean => {
    if (!isLength(value, 10)) return false;
    if (!hasSpecialChars(value)) return false;
    if (!hasDigit(value)) return false;
    if (!hasUpperCase(value)) return false;
    if (!hasMultipe(value)) return false;
    return true;
};