import { IAssociatedEntity, IRoleDto } from 'appDtos';
import React, { useContext } from 'react';
import SearchComponent from '../../../Shared/components/SearchComponent';
import { UserDetailContext } from '../../../Shared/contexts/UserDetailProvider';
import useApi from '../../../Shared/hooks/useApi';

export interface UpdateUserEntity {
  entityId?: number,
  name?: string,
  code?: string,
  isSelected: boolean,
}

interface EntitiesManagementProps {
  entities: IAssociatedEntity[];
  userId?: string;
  roles: IRoleDto[];
  cancel: () => void;
}

export function EntitiesManagement(props: EntitiesManagementProps) {
  const [entitiesResult, setEntitiesResult] = React.useState<UpdateUserEntity[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [showError, setshowError] = React.useState(false);
  const [noResult, setNoResult] = React.useState(false);
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [showSavedText, setShowSavedText] = React.useState(false);
  const API = useApi();
  const { triggerRefresh } = useContext(UserDetailContext);

  React.useEffect(() => {
    setEntitiesResult(props.entities.map(e => ({
      entityId: e.id,
      name: e.name,
      code: e.code,
      isSelected: true
    })))
  }, [props.entities]);

  const reset = () => {
    setEntitiesResult([]);
    setshowError(true);
  };

  const handleOnChange = (entity: UpdateUserEntity) => {
    if (!changeDetected) {
      setChangeDetected(true);
      setShowSavedText(false);
    }
    setEntitiesResult(entitiesResult.map(e => e.entityId === entity.entityId ? { ...e, isSelected: !e.isSelected } : e));
  };

  const handleLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
      setChangeDetected(false);
      setShowSavedText(true);
    }, 2000);
  }

  const handleEntityUpdateSubmit = async () => {
    setIsLoading(true);
    try {
      const userEntities = entitiesResult.map(({ entityId, isSelected }) => ({ entityId, isSelected }));
      await API.post(`/Administration/UpdateUserEntity`, { userId: props.userId, userEntities });
      triggerRefresh();
      handleLoading();
    } catch (error) {
    }
  };

  const handleSearchSubmit = async (e: any) => {
    setIsLoading(true);

    const validSearchTerm = searchTerm.trim();
    if (!validSearchTerm) {
      reset();
    } else {
      setshowError(false);
      try {
        const result = await API.get<IAssociatedEntity[]>(`Administration/Entity/Search/${validSearchTerm}`);
        if (result?.data?.length) {
          setNoResult(false);
          const userEntityIds = entitiesResult.map(e => e.entityId);
          setEntitiesResult(result.data.map(d => ({
            entityId: d.id,
            name: d.name,
            code: d.code,
            isSelected: userEntityIds.includes(d.id)
          })));
        } else {
          setEntitiesResult([]);
          setNoResult(true);
        }
      } catch (error) {
        setEntitiesResult([]);
        setNoResult(true);
      }
    }

    setIsLoading(false);
  };

  const handleSearchInputChange = (e: any) => {
    setshowError(false);
    setSearchTerm(e.target.value);
  };

  const btnText = showSavedText ? 'Saved' : 'Save Changes';

  return (
    <div>
      <SearchComponent handleOnChange={handleSearchInputChange} handleSubmit={handleSearchSubmit} isLoading={isLoading} placeholder="Search by entity name or code." />
      {noResult && <span>No results found.</span>}
      {showError && <span className="text-danger">Search term can not be empty.</span>}
      {entitiesResult.length > 0 && (<div className="fw-bold">Entity</div>)}
      <div className="list-group overflow-auto">
        {entitiesResult?.map(entity => (
          <label className="list-group-item mr-2" key={entity.code}>
            <div>
              <input onChange={() => handleOnChange(entity)} className="form-check-input me-1 mr-3" type="checkbox" checked={entity.isSelected} value="" />
              {entity.name}
            </div>
          </label>
        ))}
      </div>
      <button onClick={handleEntityUpdateSubmit} type="button" className={`btn btn-${showSavedText ? 'success' : 'secondary'} btn-sm m-2`} disabled={!changeDetected}>
        {isLoading && <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>}  {isLoading ? 'Saving...' : btnText}
      </button>
      <button onClick={props.cancel} type="button" className="btn btn-dark btn-sm">Cancel</button>
    </div>
  );
}
