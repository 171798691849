import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IStorageInventoryDto, IStorageTankDetailDto, IStorageTankDto } from 'appDtos';
import React, { forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/components/Loading';
import useApi from '../../Shared/hooks/useApi';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { Field } from '../Administration/Distributors/DistributorDetail';
import { getInitialState } from './ImportersEntity';
import { useReactToPrint } from 'react-to-print';

export function StorageTankEntity() {
    const [showVeryDetail, setShowVeryDetail] = React.useState(false);
    const goTo = useNavigate()
    const [tanks, setTank] = React.useState<{ tanks: IStorageTankDto[], isLoading: boolean }>({ tanks: [], isLoading: false });
    const ref = React.useRef(false);
    const axios = useApi();
    const [tankDetail, setTankDetail] = React.useState<{ detail: IStorageTankDetailDto | null, isLoading: boolean }>({ detail: null, isLoading: false });
    const [selectedId, setSelectedId] = React.useState<number | undefined>(getInitialState);
    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => (componentRef as any)?.current,
    });


    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                setTank({ ...tanks, isLoading: true });
                const result = await axios.get<IStorageTankDto[]>(`StorageTank`);
                setTank({ tanks: result.data, isLoading: false });
            };
            fetch();
        }
        return () => { ref.current = true; };
        // eslint-disable-next-line
    }, [axios]);


    React.useEffect(() => {
        if (selectedId) {
            const fetchDetail = async () => {
                setTankDetail({ ...tankDetail, isLoading: true });
                const response = await axios.get<IStorageTankDetailDto>(`StorageTank/${selectedId}`);
                setTankDetail({ detail: response.data, isLoading: false });
                window.history.replaceState(null, "LPRC", `/storageTanks/list/#/${selectedId}`);
            }
            fetchDetail();
        } else {
            window.history.replaceState(null, "LPRC", `/storageTanks/list`);
        };
        // eslint-disable-next-line
    }, [selectedId, axios]);


    const handleSelect = (id: number) => setSelectedId(id);
    const inventories = tankDetail?.detail?.inventories ?? [];

    return (
        <>
            <div className="noPrint container app-container">
                <div className="d-flex justify-content-between">
                    <div className="h4 text-dark">Storage Tanks</div>
                    <button onClick={() => goTo('/')} className="btn btn-warning fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faDashboard} /></span> Dashboard</button>
                </div>
                {tanks.isLoading ? <Loading position="center" /> : <div className="d-flex-jcs entity-content mt-3">
                    <div className="list-group col entity-list-items">
                        {tanks.tanks.map(i => (
                            <button type="button" onClick={() => handleSelect(i.id)} className={`list-group-item list-group-item-action ${i.id === selectedId ? 'bg-warning fw-bold' : ''}`} key={i.id}>Tank# {i.number} - {i.productName}</button>
                        ))}
                    </div>
                    <div className={`entity-detail ${selectedId ? 'col-lg-8 col-md-8 -col-sm-12' : 'd-none'}`}>
                        <div className="card card-body">
                            <div className="heading">
                                <div className="h4 text-dark mb-0">Tank Detail</div>
                                <div>
                                    {Boolean(tankDetail) && <>
                                        <button onClick={handlePrint} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">Print</button>
                                        <button onClick={() => setShowVeryDetail(!showVeryDetail)} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">{showVeryDetail ? 'Show Summary' : 'Show Detail'}</button>
                                    </>}
                                    <button onClick={() => handleSelect(0)} type="button" className="btn btn-sm btn-dark text-warning fw-bold m-1">Close</button>
                                </div>
                            </div>
                            {tankDetail.isLoading ? <Loading position="center" size="3rem" /> :
                                <>
                                    <Field title="Tank #" value={tankDetail?.detail?.number.toString() ?? ''} />
                                    <Field title="Product" value={tankDetail?.detail?.product.name ?? ''} />
                                    <div className="h5 mt-2 p-2 theme-dark-color">Inventory Summary</div>
                                    <Field title="Stock Balance" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.stockBalance), 0))} />
                                    {/* <Field title="Incoming Transfers" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingIncomingStockTransfersTotal), 0))} />
                                <Field title="Incoming Shipments" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingShipmentsTotal), 0))} />
                                <Field title="Overages" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingOveragesTotal), 0))} />
                                <Field title="Shortages" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingShortagesTotal), 0))} />
                                <Field title="Outgoing Transfers" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingOutGoingStockTransfersTotal), 0))} />
                                <Field title="Pending Delivery Orders" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingTrackingOrdersTotal), 0))} /> */}
                                    <Field title="Adjusted Balance" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.adjustedStockBalance), 0))} />
                                    <div className="h5 mt-2  p-2 theme-dark-color">Inventory Detail</div>
                                    <div className="results-container">
                                        {inventories.sort(compare).map(i =>
                                            <div key={i.id} className="card my-2">
                                                <div className="card-body">
                                                    <div className="fw-bold">{i.importerName}</div>
                                                    <div className="d-flex justify-content-between">
                                                        <div>Stock Balance</div><div>{displayQuantity(i.stockBalance)}</div>
                                                    </div>
                                                    {showVeryDetail &&
                                                        <><div className="fw-bold border-bottom mt-3">Additions</div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Incoming Transfers</div><div>{displayQuantity(i.pendingIncomingStockTransfersTotal)}</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Incoming Shipments</div><div>{displayQuantity(i.pendingShipmentsTotal)}</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Overages</div><div>{displayQuantity(i.pendingOveragesTotal)}</div>
                                                            </div>
                                                            <div className="fw-bold border-bottom mt-3">Reductions</div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Shortages</div><div>({displayQuantity(i.pendingShortagesTotal)})</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Outgoing Transfers</div><div>({displayQuantity(i.pendingOutGoingStockTransfersTotal)})</div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div>Pending Delivery Orders</div><div>({displayQuantity(i.pendingTrackingOrdersTotal)})</div>
                                                            </div></>}
                                                    <div className="d-flex justify-content-between border-1 border-top border-bottom mt-3">
                                                        <div>Adjusted Balance</div><div className="fw-bold">{displayQuantity(i.adjustedStockBalance)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>}
            </div>
            <ComponentToPrint ref={componentRef} tankDetail={tankDetail.detail} showVeryDetail={showVeryDetail} />
        </>
    );
}

type ComponentToPrintProps = {
    tankDetail: IStorageTankDetailDto | null;
    showVeryDetail: boolean;
}

export const ComponentToPrint = forwardRef<HTMLDivElement, ComponentToPrintProps>((props, ref) => {
    return (
        <div ref={ref}>
            {props.tankDetail ? <div className="noScreen bg-light p-4">
                <div className="heading">
                    <div className="h6 text-dark mb-0">LPRC Petroleum Products Management System</div>
                    <hr className="mt-0" />
                    <div className="fw-bold">Storage Tank Detail</div>
                </div>
                <div className="border rounded d-flex-jcs">
                    <div className="col-md-3 col-lg-3 col-sm-12 mb-3">
                        <Field title="Tank #" value={props.tankDetail?.number.toString() ?? ''} />
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-12 mb-3">
                        <Field title="Product" value={props.tankDetail.product.name ?? ''} />
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-12 mb-3">
                        <Field title="Stock Balance" value={displayQuantity(props.tankDetail.inventories.reduce((r, e) => r + Number(e.stockBalance), 0))} />
                    </div>
                    {/* <Field title="Incoming Transfers" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingIncomingStockTransfersTotal), 0))} />
                                <Field title="Incoming Shipments" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingShipmentsTotal), 0))} />
                                <Field title="Overages" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingOveragesTotal), 0))} />
                                <Field title="Shortages" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingShortagesTotal), 0))} />
                                <Field title="Outgoing Transfers" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingOutGoingStockTransfersTotal), 0))} />
                            <Field title="Pending Delivery Orders" value={displayQuantity(inventories.reduce((r, e) => r + Number(e.pendingTrackingOrdersTotal), 0))} /> */}
                    <div className="col-md-3 col-lg-3 col-sm-12 mb-3">
                        <Field title="Adjusted Balance" value={displayQuantity(props.tankDetail.inventories.reduce((r, e) => r + Number(e.adjustedStockBalance), 0))} />
                    </div>
                </div>
                <div className="mt-2 fw-bold">Inventory Detail</div>
                <div className="results-container">
                    {props.tankDetail.inventories.sort(compare).map(i =>
                        <div key={i.id} className="card mb-2">
                            <div className="card-body">
                                <div className="fw-bold">{i.importerName}</div>
                                <div className="d-flex justify-content-between">
                                    <div>Stock Balance</div><div>{displayQuantity(i.stockBalance)}</div>
                                </div>
                                {props.showVeryDetail &&
                                    <><div className="border-bottom mt-3">Additions</div>
                                        <div className="d-flex justify-content-between">
                                            <div>Incoming Transfers</div><div>{displayQuantity(i.pendingIncomingStockTransfersTotal)}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Incoming Shipments</div><div>{displayQuantity(i.pendingShipmentsTotal)}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Overages</div><div>{displayQuantity(i.pendingOveragesTotal)}</div>
                                        </div>
                                        <div className="border-bottom mt-3">Reductions</div>
                                        <div className="d-flex justify-content-between">
                                            <div>Shortages</div><div>({displayQuantity(i.pendingShortagesTotal)})</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Outgoing Transfers</div><div>({displayQuantity(i.pendingOutGoingStockTransfersTotal)})</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Pending Delivery Orders</div><div>({displayQuantity(i.pendingTrackingOrdersTotal)})</div>
                                        </div></>}
                                <div className="d-flex justify-content-between border-1 border-top border-bottom mt-3">
                                    <div>Adjusted Balance</div><div className="fw-bold">{displayQuantity(i.adjustedStockBalance)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div> : null}
        </div>
    );
});

function compare(a: IStorageInventoryDto, b: IStorageInventoryDto) {
    if (a.importerName < b.importerName) {
        return -1;
    }
    if (a.importerName > b.importerName) {
        return 1;
    }
    return 0;
}
