import { faUpload } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import useApi from '../hooks/useApi';
import { useApiHandleError } from '../hooks/useApiHandleError';
import Alert, { AlertType } from './Alert';
import Icon from './Icon';
import { usePrompt } from './PromptModal';

export default function FileUploadComponent({ entityType, entityId, canUpload, docTitles }: { entityType: number, entityId: number, canUpload: boolean, docTitles: string[] }) {
    const API = useApi();
    const [document, setDocument] = React.useState<{ title: string, content: string }>({ title: '', content: '' });
    const [fileError, setFileError] = React.useState<string>('');
    const { errorMessage, setError } = useApiHandleError();
    const { openModal, isOpen } = usePrompt();
    const handleTitleChange = (e: any) => {
        setFileError('');
        setDocument({ ...document, title: e.target.value });
    }

    const handleFileChange = (e: any) => {
        setFileError('');
        const file = e.target.files[0];

        if (["jpeg", "jpg", "png", "pdf"].includes(file?.name.split('.').pop().toLowerCase())) {
            setDocument({ ...document, content: file });
        } else {
            setDocument({ ...document, content: '' });
            setFileError('Unsupported file was selected. Please convert to pdf before selecting file.');
        }
    }

    const uploadDoc = async () => {
        try {
            const formData = new FormData();
            formData.append("entityId", `${entityId}`);
            formData.append("entityType", `${entityType}`);
            formData.append("file", document.content);
            formData.append("title", document.title);
            await API.post("Document", formData);
            window.location.reload();
        } catch (error: any) {
            setError(error);
        }
    }

    const handleUpload = async () => {
        if (!document.title || !document.content) {
            return setFileError('Document title and valid pdf file are required.');
        }

        if (docTitles.some(t => t === document.title)) {
            openModal({
                ok: uploadDoc,
                cancel: () => setDocument({ ...document, title: '' }),
                title: 'Please Confirm Document Replacement!',
                text: 'There is already a document with the same title as the one you entered. Are you sure you want to replace this document with the one you selected?'
            });
        } else {
            uploadDoc();
        }
    }

    if (!canUpload) {
        return null;
    }

    return (
        <>
            <div className="m-2 fw-bold">Upload document.</div>
            {Boolean(errorMessage || fileError) && <Alert type={AlertType.DANGER} message={errorMessage || fileError} />}
            <div className="d-flex-jcb">
                <div className="col">
                    <div className="input-group document-title-input">
                        <input
                            onChange={handleTitleChange}
                            value={document.title}
                            type="text"
                            placeholder="Enter document title"
                            className="form-control"
                            id="title"
                            aria-label="title"
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="input-group">
                        <input
                            onChange={handleFileChange}
                            type="file"
                            className="form-control"
                            id="inputGroupFile04"
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                        />
                        <button
                            disabled={isOpen}
                            onClick={handleUpload}
                            className="btn btn-outline-warning fw-bold text-dark upload-button"
                            type="button"
                            id="inputGroupFileAddon04">
                            <span><Icon icon={faUpload} /></span> Upload
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
