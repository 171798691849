import { faDashboard, faList, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateCompartmentDto, DeliveryOrderInfo, ICreateFillingAdviceNoteRequest, IDeliveryOrderForFanDto } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import { usePrompt } from '../../Shared/components/PromptModal';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { SelectedDeliveryOrders } from '../deliveryOrders/SelectedTransferOrders';
import { DeliveryOrderInputForm } from '../deliveryOrders/TransferOrderInputForm';
import { CompartmentModal } from './CompartmentModal';

export default function CreateFillingAdviceNote() {
  const goTo = useNavigate();
  const axios = useApi();
  const [deliveryOrdersResults, setDeliveryOrdersResults] = React.useState<DeliveryOrderInfo[]>([]);
  const [formErrorMessage, setErrorMesssage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const { openModal, closeModal } = usePrompt();
  const [doFilters, setDoFilters] = React.useState({ doNumber: '', product: '', priority: '', quantity: 0 });

  const path = 'FillingAdviceNote';
  const {
    handleOnChange,
    handleFieldError,
    isProcessing,
    setIsProcessing,
    formData,
  } = useFormProcessor<ICreateFillingAdviceNoteRequest>({
    vehicleNumber: '',
    deliveryOrders: [],
    compartments: [],
  }, {
    // cardNumber: false,
    vehicleNumber: false,
    deliveryOrders: false,
    compartments: false,
  });

  React.useEffect(() => {
    const search = async () => {
      try {
        const result = await axios.get<IDeliveryOrderForFanDto[]>(`${path}/FanCreationInfo`);
        if (result?.data?.length) {
          setDeliveryOrdersResults(result.data.map(t => ({
            isSelected: false,
            deliveryOrderId: t.id,
            deliveryOrderNumber: t.deliveryOrderNumber,
            quantity: t.quantity,
            priority: t.priority,
            product: t.product,
          })));
        } else {
          setDeliveryOrdersResults([]);
        }
      } catch (error) {
        setDeliveryOrdersResults([]);
      }
    }
    search();
  }, [axios])

  React.useEffect(() => {
    setErrorMesssage('');
  }, [formData]);

  const handleSubmit = async () => {
    // if (!Number(formData.cardNumber?.trim())) {
    //   return setErrorMesssage("Card number is required and can only be numeric.")
    // }

    if (formData.deliveryOrders?.length === 0) {
      return setErrorMesssage("At least one delivery order is required to create a filling advice note.")
    }

    if (formData.compartments?.length === 0) {
      return setErrorMesssage("At least one compartment is required to create a filling advice note.")
    }

    setIsProcessing(true);

    try {
      const request = {
        vehicleNumber: formData.vehicleNumber,
        deliveryOrders: formData.deliveryOrders.map(t => ({ deliveryOrderId: t.deliveryOrderId, quantity: t.quantity })),
        compartments: formData.compartments,
      }

      await axios.post(path, request);
      return goTo('/filling-advice-notes/list');
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const resetAll = (info: DeliveryOrderInfo) => {
    handleOnChange({ deliveryOrders: [info] });
    closeModal();
  }

  const handleDeliveryOrderSelectionChange = (info: DeliveryOrderInfo) => {
    if (formData.deliveryOrders.some(t => t.product !== info.product)) {
      return openModal({
        ok: () => resetAll(info),
        title: 'Please Confirm Delivery Order Replacement!',
        text: 'You are adding delivery order with different product from what was added before.\nDo you want to replace the current selected delivery order(s)?'
      });
    } else {
      handleOnChange({ deliveryOrders: [...formData.deliveryOrders, info] });
    }
  };

  const addCompartment = (compartment: CreateCompartmentDto) => {
    handleOnChange({ compartments: [...formData.compartments, compartment] });
  };

  const removeDeliveryOrder = (id: number) => {
    handleOnChange({ deliveryOrders: formData.deliveryOrders.filter(t => t.deliveryOrderId !== id) });
  };

  const totalDeliveryOrderQuantity = formData.deliveryOrders.reduce((q, e) => q + Number(e.quantity), 0);
  const compartmentTotal = formData.compartments.reduce((r, e) => r + Number(e.quantity), 0);
  const unselectedDeliveryOrders = deliveryOrdersResults.filter(d => !formData.deliveryOrders.some(s => s.deliveryOrderId === d.deliveryOrderId));

  let isValid = false;
  if (formData.vehicleNumber && formData?.deliveryOrders?.length && compartmentTotal === totalDeliveryOrderQuantity) {
    isValid = true;
  }

  const priorities = (deliveryOrdersResults.map(d => d.priority) as string[]).reduce((r: string[], e: string) => r.length > 0 && r.includes(e) ? r : [...r, e], []);
  const products = (deliveryOrdersResults.map(d => d.product) as string[]).reduce((r: string[], e: string) => r.length > 0 && r.includes(e) ? r : [...r, e], []);

  const filteredDeliveryOrders = React.useMemo(() => {
    if (!unselectedDeliveryOrders.length) {
      return [];
    }
    let dos = unselectedDeliveryOrders;

    if (doFilters.doNumber) dos = dos.filter(d => d.deliveryOrderNumber.toLowerCase().startsWith(doFilters.doNumber.toLowerCase()));
    if (doFilters.product) dos = dos.filter(d => doFilters.product === d.product);
    if (doFilters.priority) dos = dos.filter(d => doFilters.priority === d.priority);
    if (doFilters.quantity) dos = dos.filter(d => Number(doFilters.quantity) === d.quantity);

    return dos;
  }, [unselectedDeliveryOrders, doFilters]);

  const clearFilters = () => {
    setDoFilters({ ...doFilters, doNumber: '', product: '', priority: '', quantity: 0 })
  }

  //const handlePriorityChange = (e: any) => setDoFilters({ ...doFilters, priority: e.target.value });
  //const handleProductChange = (e: any) => setDoFilters({ ...doFilters, product: e.target.value });
  const handleDoFilterChange = (e: any) => setDoFilters({ ...doFilters, [e.target.name]: e.target.value });
  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h5 text-dark">Create Filling Advice Note</div>
        <div className="d-flex-jce">
          <NavigationButton link="/filling-advice-notes/list" icon={faList} label="Filling Advice Notes" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Processing..." position="center" />}
      {(!!errorMessage || !!formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
      {!isProcessing && <>
        {/* <div className="col">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Card Number"
            placeholder="Enter card number."
            id="cardNumber"
            value={formData.cardNumber}
            name="cardNumber"
            type="text"
            required={true}
          />
        </div> */}
        <div className="col">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Vehicle Number"
            placeholder="Enter vehicle number."
            id="vehicleNumber"
            value={formData.vehicleNumber}
            name="vehicleNumber"
            type="text"
            required={true}
          />
        </div>
        <div className="d-flex-jcs">
          <div className="col mt-3">
            <div>Delivery Order Total</div>
            <div className="h6">{displayQuantity(totalDeliveryOrderQuantity)} Gallons</div>
          </div>
          <div className="col mt-3">
            <div>Compartment Total</div>
            <div className="h6">{displayQuantity(compartmentTotal)} Gallons</div>
          </div>
          <div className="col mt-3">
            <div>Difference</div>
            <div className="h6">{displayQuantity(totalDeliveryOrderQuantity - compartmentTotal)} Gallons</div>
          </div>
          <button disabled={(!totalDeliveryOrderQuantity || (compartmentTotal === totalDeliveryOrderQuantity))} data-bs-toggle="modal" data-bs-target="#compartmentModal" type="button" className="btn btn-warning btn-sm fw-bold my-4"><span><FontAwesomeIcon size='lg' icon={faPlus} /></span> Add Compartment</button>
        </div>

        <div className="h6 p-2 bg-dark text-light">Compartments</div>
        <div className="table-responsive">
          <table className="table table-sm table-responsive align-middle">
            <thead>
              <tr className="border-bottom">
                <th scope="col">Compartment #</th>
                {/* <th scope="col">Bay Number</th> */}
                <th scope="col">Quantity</th>
                <th scope="col">Destination</th>
              </tr>
            </thead>
            <tbody>
              {formData.compartments.length > 0 ? formData.compartments.map(c =>
                <tr key={c.compartmentNumber}>
                  <th scope="row">{c.compartmentNumber}</th>
                  {/* <td>{c.bayNumber}</td> */}
                  <td>{displayQuantity(c.quantity)}</td>
                  <td title={c.destination}><div className="trucated-table-cell">{c.destination}</div></td>
                  <td className="table-cell-right">
                    <button onClick={() => handleOnChange({ compartments: formData.compartments.filter(fc => fc.compartmentNumber !== c.compartmentNumber) })} type="button" className="add-button btn btn-danger fw-bold btn-sm"><span><FontAwesomeIcon size='lg' icon={faTrash} /></span> Remove</button>
                  </td>
                </tr>
              ) : <tr><td colSpan={4}>No compartment added.</td></tr>}
            </tbody>
          </table>
        </div>

        <SelectedDeliveryOrders deliveryOrders={formData.deliveryOrders} removeDeliveryOrder={removeDeliveryOrder} />

        <div className="h6 p-2 bg-dark text-light">Available Delivery Orders</div>

        <div className="table-responsive">
          <table className="table table-sm table-responsive align-middle data-table">
            <thead>
              <tr className="border-bottom">
                <th scope="col">#</th>
                <th scope="col">
                  Delivery Order #
                  <input name="doNumber" onChange={handleDoFilterChange} value={doFilters.doNumber} type="text" className="form-control" id="doNumberInput" placeholder="Enter DO#" />
                </th>
                <th className="col-4" scope="col">
                  Product
                  <select name="product" className="form-select" aria-label="default product" value={doFilters.product} onChange={handleDoFilterChange}>
                    <option value={''}>Select Product</option>
                    {products.map((i) => <option value={i} key={i}>{i}</option>)}
                  </select>
                </th>
                <th scope="col">
                  Priority
                  <select name="priority" className="form-select" aria-label="default priority" value={doFilters.priority} onChange={handleDoFilterChange}>
                    <option value={''}>Select Priority</option>
                    {priorities.map((i) => <option value={i} key={i}>{i}</option>)}
                  </select>
                </th>
                <th scope="col">
                  Quantity
                  <input name="quantity" onChange={handleDoFilterChange} value={doFilters.quantity || ''} type="text" className="form-control" id="quantityInput" placeholder="Enter quantity" />
                </th>
                <th scope="col">
                  <button onClick={clearFilters} type="button" className="btn btn-warning bt-md fw-bold w-100 ">Clear Filters</button>
                </th>
              </tr>
            </thead>
            <tbody className="scrollable-table-body">
              {filteredDeliveryOrders.length ? filteredDeliveryOrders.map((d, i) =>
                <DeliveryOrderInputForm
                  deliveryOrder={d}
                  handleSelection={handleDeliveryOrderSelectionChange}
                  key={d.deliveryOrderId}
                  index={i}
                />) : <tr><td colSpan={5}>No available delivery orders.</td></tr>}
            </tbody>
          </table>
        </div>
      </>}
      <div className="page-action-button-group">
        <BackButton disabled={isProcessing} />
        <SubmitButton handleSubmit={handleSubmit} disabled={!isValid || isProcessing} />
      </div>
      <CompartmentModal addCompartment={addCompartment} totalDeliveryOrderQuantity={totalDeliveryOrderQuantity} addedCompartments={formData.compartments} />
    </div>
  );
}
