import { forwardRef } from "react";

import './style.css';

interface PrintingVersionProps {
    element: JSX.Element;
    shouldRender: boolean;
    headerProps: string[];
}

export const PrintingVersion = forwardRef<HTMLDivElement, PrintingVersionProps>((props, ref) => {
    const date = new Date();
    return props.shouldRender ? (
        <div ref={ref} className="noScreen">
            <div className="print-header">
                <div>
                    <div className="header-props-top">LPRC Protroleum Products Management System</div>
                    <hr className="my-0"/>
                    {props.headerProps.filter(v => v).map(v => <div key={v} className="header-props-others">{v}</div>)}
                </div>
            </div>
            {props.element}
            <div className="timestamp">PPMS Timestamp: {date.toLocaleDateString()} {date.toLocaleTimeString()}</div>
        </div>) : null;
});