import { faCheck, faComment, faDashboard, faEdit, faFolderPlus, faList, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CommentDto, DocumentDto, IDeliveryOrderDetailDto } from 'appDtos';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { ChangePriorityModal } from '../../Shared/components/ChangePriorityModal';
import { CommentModal } from '../../Shared/components/CommentModal';
import { ConfirmModal } from '../../Shared/components/ConfirmModal';
import { DocumentSectionTable } from '../../Shared/components/DocumentSection';
import HeadingElement from '../../Shared/components/HeadingElement';
import { CommentComponent } from '../../Shared/components/History';
import Icon from '../../Shared/components/Icon';
import { InventoryUpdateModal } from '../../Shared/components/InventoryUpdateModal';
import Loading from '../../Shared/components/Loading';
import ModalToggleButton from '../../Shared/components/ModalToggleButton';
import { BackButton, NavigationButton } from '../../Shared/components/NavigationButton';
import { Action, EntityType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import { useDocumentActions } from '../../Shared/hooks/useDocumentActions';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import TransferOrderTable from './TransferOrderTable';

export function DeliveryOrderDetail() {
  const [detail, setDeliveryOrderDetail] = React.useState<IDeliveryOrderDetailDto>();
  const axios = useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef(false);
  const { id } = useParams();
  const path = 'DeliveryOrder';
  const { isInRole, hasPermission, rCodes, pCodes, getUser } = useAuth();
  const { viewDocument, handleDelete } = useDocumentActions(EntityType.DeliveryOrder, getUser()?.userId);
  const [formErrorMessage, setErrorMessage] = React.useState('');
  const [priority, setPriority] = React.useState<number>();
  const [action, setAction] = React.useState('');
  const [comment, setComment] = React.useState<CommentDto | undefined>();
  const { errorMessage, setError } = useApiHandleError();
  const [transferOrderId, setTransferOrderId] = React.useState(0);
  const goTo = useNavigate();
  
  const getPriorityValue = () => {
    if (detail?.priority === 'Medium') return 1;
    if (detail?.priority === 'High') return 2;
    return 0;
  }

  React.useEffect(() => {
    if (!ref.current) {
      setIsLoading(true);
      const fetch = async () => {
        try {
          if (id && !detail) {
            const result = await axios.get<IDeliveryOrderDetailDto>(`${path}/${id}`);
            if (result.data) {
              setDeliveryOrderDetail(result.data);
            } else {

            }
          }
        } catch (error: any) {
          setError(error);
        }
        setIsLoading(false);
      };
      fetch();
    }

    return () => {
      ref.current = true;
    };

  }, [id, axios, detail, setError]);

  const handleDeleteDocument = async (doc: DocumentDto) => handleDelete(doc, detail?.id);

  const handleviewDocument = async (doc: DocumentDto) => viewDocument(doc, detail?.id);

  const handleClick = async () => {
    setErrorMessage('');

    const map: { [key: string]: number } = {
      [Action.REVIEW]: 5,
      [Action.APPROVE]: 10,
      [Action.CANCEL]: 25,
    }
    try {
      await axios.post(`${path}/StatusUpdate`, { deliveryOrderId: id, status: map[action], priority: priority ?? getPriorityValue() });
      if (action === Action.CANCEL) {
        return goTo('/delivery-orders/list');
      }

      window.location.reload();
    } catch (error: any) {
      setError(error);
    }
  }

  const confirmBodyElement = <div className="h6">Are you sure you want to <strong>{`${action.toUpperCase()}`}</strong> this delivery order?</div>;

  const getPriorityText = () => {
    if (priority === 1) return 'Medium';
    if (priority === 2) return 'High';
    return 'Low';
  }

  const handleCommentAction = ({ comment, action }: { comment: CommentDto, action: string }) => {
    setAction(action);
    setComment(comment);
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Delivery Order Detail</div>
        <div className="d-flex-jce">
          {(detail?.isEditable ?? false) && <NavigationButton link={`/edit-delivery-order/${detail?.id}`} icon={faEdit} label="Edit Delivery Order" />}
          {isInRole(rCodes?.R300) && hasPermission(pCodes?.P300) && <NavigationButton link="/create-delivery-order" icon={faFolderPlus} label="Create Delivery Order" />}
          <NavigationButton link="/delivery-orders/list" icon={faList} label="Delivery Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isLoading && <Loading loadingText="Loading delivery order detail." position="center" />}
      {(Boolean(formErrorMessage) || Boolean(errorMessage)) && <Alert type={AlertType.DANGER} message={formErrorMessage || errorMessage} />}
      {!!detail && <div className="mb-5 mt-2">
        <HeadingElement text="Detail Information" />
        <div className="d-flex-jcs">
          <div className="p-1 col-md-3">
            <p className="mb-0">Delivery Order Number</p>
            <p className="fw-bold">{detail.deliveryOrderNumber}</p>
            <p className="mb-0">Current Status</p>
            <p className="fw-bold">{detail.status}</p>
          </div>
          <div className="p-1 col-md-3">
            <p className="mb-0">Created Date</p>
            <p className="fw-bold">{formatDate(detail.submittedDate)}</p>
            <p className="mb-0">Created By</p>
            <p className="fw-bold">{detail.submittedBy}</p>
          </div>
          {!!detail.reviewedDate && <div className="p-1 col-md-3">
            <p className="mb-0">Reviewed Date</p>
            <p className="fw-bold">{formatDate(detail.reviewedDate)}</p>
            <p className="mb-0">Reviewed By</p>
            <p className="fw-bold">{detail.reviewedBy}</p>
          </div>}
          {!!detail.approvedDate && <div className="p-1 col-md-3">
            <p className="mb-0">Approved Date</p>
            <p className="fw-bold">{formatDate(detail.approvedDate)}</p>
            <p className="mb-0">Approved By</p>
            <p className="fw-bold">{detail.approvedBy}</p>
          </div>}
          <div className="p-1 col-md-3">
            <p className="mb-0">Product</p>
            <p className="fw-bold"> {detail.transferOrders[0]?.product}</p>
            <p className="mb-0">Total Quantity</p>
            <p className="fw-bold mb-0">{displayQuantity(detail.transferOrders.reduce((q, e) => q + Number(e.quantity), 0))} Gallons</p>
          </div>
          <div className="p-1 col-md-3">
            <div className="d-flex-jcb">
              <div>
                <p className="mb-0">Priority</p>
                <p className="fw-bold"> {priority ? getPriorityText() : detail.priority}</p>
              </div>
              {detail.isApprovable && <button data-bs-toggle="modal" data-bs-target="#ChangePriorityModal" type="button" className="btn btn-sm btn-warning m-1 align-self-start fw-bold"><Icon icon={faEdit} /> Update Priority</button>}
            </div>
          </div>
        </div>
        <HeadingElement text="Filling Advice Note" />
        {Boolean(detail.fillingAdviceNote) ? <div className="d-flex-jcs">
          <div className="p-1 col-md-3">
            <p className="mb-0">FAN Number</p>
            <Link to={`/filling-advice-note-detail/${detail.fillingAdviceNote.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{detail.fillingAdviceNote.id}</Link>
          </div>
          <div className="p-1 col-md-3">
            <p className="mb-0">Card Number</p>
            <p className={`fw-bold  ${detail.fillingAdviceNote.cardNumber === 0 ? 'text-danger' : ''}`}>{detail.fillingAdviceNote.cardNumber === 0 ? 'Pending': detail.fillingAdviceNote.cardNumber}</p>
          </div>
          <div className="p-1 col-md-3">
            <p className="mb-0">Created Date</p>
            <p className="fw-bold">{formatDate(detail.fillingAdviceNote.createdDate)}</p>
          </div>
          <div className="p-1 col-md-3">
            <p className="mb-0">Status</p>
            <p className="fw-bold">{detail.fillingAdviceNote.status}</p>
          </div>
        </div> : <p className="p-2">Filling Advice Note has not been created for this delivery order.</p>}
        <HeadingElement text="Transfer Order" />
        <TransferOrderTable transferOrders={detail.transferOrders} setTransferOrderId={setTransferOrderId} showInventoryUpdate={detail.canUpdateInventory} />
        <HeadingElement text="Documents" />
        <DocumentSectionTable canUpload={detail.canManageDocuments} entityType={EntityType.DeliveryOrder} documents={detail.documents} id={detail.id} handleDelete={handleDeleteDocument} handleDocumentView={handleviewDocument} />
        <HeadingElement text="Comments" />
        <CommentComponent id={detail.id} comments={detail.comments} handleCommentAction={handleCommentAction} />
      </div>}
      <div className="page-action-button-group">
        <BackButton disabled={isLoading} />
        {(detail?.isCancellable ?? false) && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.CANCEL)} value="Cancel" icon={faXmark} color="danger" />}
        {!!detail && <ModalToggleButton modalId="#CommentModal" handleClick={() => setAction(Action.COMMENT)} value="Add Comment" icon={faComment} />}
        {(detail?.isReviewable ?? false) && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.REVIEW)} value="Review" icon={faCheck} />}
        {(detail?.isApprovable ?? false) && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.APPROVE)} value="Approve" icon={faCheck} />}
      </div>
      <ConfirmModal body={confirmBodyElement} func={handleClick} />
      <ChangePriorityModal priority={getPriorityValue()} handleChange={setPriority} />
      <CommentModal action={action} id={detail?.id} comment={comment} path={path} />
      <InventoryUpdateModal transferOrder={detail?.transferOrders.find(t => t.id === transferOrderId)} deliveryOrderId={Number(id)} path={path} />
    </div>
  );
}

export function DeliveryOrderNoFound() {
  return (
    <div className="card">
      <div className="card-body text-center">
        <h5 className="card-title h5">Delivery Order not found.</h5>
      </div>
    </div>);
}
