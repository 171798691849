import React from 'react'
import ControlInputField from '../../../Shared/components/ControlInputField';
import { Link } from 'react-router-dom';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { isValidPassword } from '../../../Shared/components/validators';
import './passwordForgetEmailSubmission.css';
import Loading from '../../../Shared/components/Loading';
import PasswordRules from './PasswordRules';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';

export interface UserPasswordResetInfo {
  newPassword: string;
  confirmedPassword: string;
}

export default function ResetPassword() {
  const axios = useApi();
  const [resetSuccess, setResetSuccess] = React.useState(false);
  const [isExpired, setIsExpired] = React.useState<boolean | undefined>();
  const { errorMessage, setError } = useApiHandleError();
  const [show, setShow] = React.useState(false);

  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    formData,
    isProcessing,
    hasError } = useFormProcessor<UserPasswordResetInfo>({ newPassword: '', confirmedPassword: '' }, { newPassword: true, confirmedPassword: true });

  const values = React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }, []);

  React.useEffect(() => {
    if (values?.timeStamp) {
      const expired = Date.now() > Date.parse(values?.timeStamp);
      setIsExpired(expired);
    }
  }, [values]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const { email, token, currentPassword, isNewUser } = values;
      await axios.post('Account/ResetPassword', {
        ...formData,
        currentPassword,
        email,
        token,
        isNewUser: isNewUser ? true : false
      });
      setResetSuccess(true);
      window.localStorage.removeItem(StorageKeys.APP_TOKEN);
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  if (isExpired === undefined) {
    return <Loading loadingText="Loading..." position="center" />;
  }

  if (isExpired) {
    return (
      <div className="login-form mx-auto mt-5 text-center">
        <h5 className="border-warning text-light">Your password reset link has expired. Please submit a new request</h5>
        <div className="d-flex-jcc text-center">
          <Link to="/" className="btn btn-warning btn-md m-1 fw-bold">Login</Link>
          <Link to="/reset-password" className="btn btn-warning btn-md m-1 fw-bold">Reset Password</Link>
        </div>
      </div >)
  }

  if (resetSuccess) {
    return (
      <div className="login-form mx-auto mt-5 text-center">
        <Alert type={AlertType.SUCCESS} message="Password successfully reset." />
        <Link to="/" className="btn btn-warning btn-md mt-4 fw-bold">Go to Login</Link>
      </div >);
  }

  const toggleShow = () => setShow(!show);

  return (
    <div className='forgot-email-form mx-auto'>
      <div className="form-title text-warning">Create New Password</div>
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      <form onSubmit={handleSubmit}>
        <ControlInputField
          onChange={handleOnChange}
          label="New Password"
          placeholder="Enter new password"
          id="newPassword"
          value={formData.newPassword}
          name="newPassword"
          type={show ? "text" : "password"}
          required={true}
          validationFunction={isValidPassword}
          onFieldError={handleFieldError}
        />
        <ControlInputField
          onChange={handleOnChange}
          label="Confirm Password"
          placeholder="Re-enter password"
          id="confirmedPassword"
          value={formData.confirmedPassword}
          name="confirmedPassword"
          type={show ? "text" : "password"}
          required={true}
          validationFunction={(value: any) => isValidPassword(value) && value === formData.newPassword}
          onFieldError={handleFieldError}
        />
        <button onClick={toggleShow} type="button" className="mb-1 mt-0 btn btn-link text-warning fw-bold p-0">{show ? 'Hide' : 'Show'}</button>
        <button type="submit" className="btn fw-bold btn-md btn-warning w-100 mx-0" disabled={isProcessing || hasError}>
          {isProcessing && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          {isProcessing ? 'Processing' : 'Submit'}
        </button>
      </form>
      <PasswordRules value={formData.newPassword} />
    </div>
  );
}
