import React, { ReactElement } from 'react';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';
import RequiredText from './RequiredText';
import { CompartmentDto } from 'appDtos';

export function ConfirmModal(props: { body: ReactElement; func: () => void; }) {
  return (
    <div className="modal fade" id="ConfirmModal" tabIndex={-1} aria-labelledby="ConfirmModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Please Confirm!</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h6>{props.body}</h6>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary fw-bold" data-bs-dismiss="modal"><Icon icon={faX} /> Close</button>
            <button type="button" onClick={props.func} data-bs-dismiss="modal" className="btn btn-warning fw-bold"><span><Icon icon={faCheck} /></span> Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function IssueFanModal(props: { body: ReactElement; func: (data: { cardNumber: number, compartments: CompartmentDto[] }) => void; compartments: CompartmentDto[] }) {
  const [cardNumber, setCardNumber] = React.useState('');
  const [updatedCompartments, setUpdatedCompartments] = React.useState<CompartmentDto[]>([]);

  React.useEffect(() => {
    setUpdatedCompartments(props.compartments);
  }, [props.compartments]);

  const handleCompartmentChange = ({ id, value }: { id: number, value: string }) => {
    setUpdatedCompartments(updatedCompartments.map(u => u.id === id ? ({ ...u, bayNumber: value }) : u));
  }

  const handleCardNumberChange = (e: any) => setCardNumber(e.target.value);

  const isValid = (Number(cardNumber) > 0 && !updatedCompartments.some(u => !Number(u.bayNumber)));

  return (
    <div className="modal fade" id="IssueFanModal" tabIndex={-1} aria-labelledby="IssueFanModal" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Issue Filling Advice Note</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="cardNumber" className="form-label fw-bold">Card Number<RequiredText /></label>
              <input autoComplete="off" onChange={handleCardNumberChange} value={cardNumber} type="text" className="form-control" id="cardNumber" placeholder="Enter card number" />
            </div>
            <table className="table table-responsive table-sm">
              <thead>
                <tr>
                  <th scope="col">Comp. #</th>
                  <th scope="col">Bay #</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Destination</th>
                </tr>
              </thead>
              <tbody>
                {updatedCompartments.map(c => (<tr key={c.id}>
                  <th className="col-2" scope="row">{c.compartmentNumber}</th>
                  <td className="col-1"><input autoComplete="off" onChange={(e: any) => handleCompartmentChange({ id: c.id, value: e.target.value })} value={Number(c.bayNumber) ? c.bayNumber : ''} type="text" className="form-control" id={c.id.toString()} /></td>
                  <td>{c.quantity}</td>
                  <td>{c.destination}</td>
                </tr>))}
              </tbody>
            </table>
            <h6>{props.body}</h6>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary fw-bold" data-bs-dismiss="modal"><Icon icon={faX} /> Close</button>
            <button type="button" disabled={!isValid} onClick={() => props.func(({ cardNumber: Number(cardNumber), compartments: updatedCompartments }))} data-bs-dismiss="modal" className="btn btn-warning fw-bold"><span><Icon icon={faCheck} /></span> Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}
