import { IProductDto, IStorageTankDetailDto, PutStorageTankRequest } from 'appDtos';
import React from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import HeadingElement from '../../../Shared/components/HeadingElement';
import { SubmitButton } from '../../../Shared/components/NavigationButton';
import RequiredText from '../../../Shared/components/RequiredText';
import { hasValue } from '../../../Shared/components/validators';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

export function EditStorageTank(props: { storageTank: IStorageTankDetailDto, setMode: (mode: Mode) => void }) {
    const axios = useApi();
    const { setError, errorMessage } = useApiHandleError();
    const [products, setProducts] = React.useState<IProductDto[]>([]);
    const ref = React.useRef(false);
    const {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        formData,
        isProcessing,
        hasError } = useFormProcessor<PutStorageTankRequest>({
            id: props.storageTank.id, number: props.storageTank.number, productId: props.storageTank.product.id
        },
            { number: false, productId: false });

    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                const result = await axios.get<IProductDto[]>('Product');
                if (result?.data) {
                    setProducts(result?.data);
                }
            }

            fetch();
        }

        return () => {
            ref.current = true;
        };
    }, [axios, products]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            const response = await axios.put('StorageTank', formData);
            if (response?.data) {
                props.setMode(Mode.DETAIL);
            }
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    const handleProductChange = (product: IProductDto) => {
        handleOnChange({ productId: product.id });
    }

    return (
        <div className="">
            <HeadingElement text="Edit Storage Tank" />
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            <form className="p-3">
                <ControlInputField
                    onChange={handleOnChange}
                    autoComplete="off"
                    onFieldError={handleFieldError}
                    label="Storage Tank Number"
                    placeholder="Enter storage tank number."
                    id="number"
                    value={formData.number}
                    name="number"
                    type="text"
                    required={true}
                    validationFunction={hasValue} />
                <div className="">Select Product <RequiredText /></div>
                <div className="list-group mb-3">
                    {products?.map(product => (
                        <label className="list-group-item mr-2" key={product.name}>
                            <input onChange={() => handleProductChange(product)} className="form-check-input me-1 mr-3" type="checkbox" checked={product.id === Number(formData.productId)} value="" />
                            {product.name}
                        </label>
                    ))}
                </div>
            </form>
            <div className="page-action-button-group">
                <button onClick={() => props.setMode(Mode.DETAIL)} className="btn btn-secondary fw-bold m-1">Cancel</button>
                <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing || hasError} />
            </div>
        </div>
    );
}


