import { faDashboard, faFolderPlus, faList } from '@fortawesome/free-solid-svg-icons';
import { DeliveryOrderForEditInfoDto, InventoryForDeliveryOrderDto, TransferOrderInfo, UpdateDeliveryOrderDto } from 'appDtos';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import Pagination from '../../Shared/components/Pagination';
import { usePrompt } from '../../Shared/components/PromptModal';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import useChangeDetector from '../../Shared/hooks/useChangeDetector';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { TableHeader, transferOrderSorter } from './CreateDeliveryOrder';
import { SelectedTransferOrders } from './SelectedTransferOrders';
import { TransferOrderInputForm } from './TransferOrderInputForm';

export default function EditDeliveryOrder() {
  const goTo = useNavigate();
  const axios = useApi();
  const path = 'DeliveryOrder';
  const [transferOrdersResults, setTransferOrdersResults] = React.useState<TransferOrderInfo[]>([]);
  const [inventoryResults, setInventoryResults] = React.useState<InventoryForDeliveryOrderDto[]>([]);
  const [formErrorMessage, setFormErrorMesssage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const { id } = useParams();
  const { hasChanged, setInitialValues, checkChanges, renderNoChangeComponnent } = useChangeDetector();
  const [sort, setSort] = React.useState<{ sortBy: string, sortOrder: number }>({ sortBy: 'transferOrderNumber', sortOrder: 0 });
  const [sortedResults, setSortedResults] = React.useState<TransferOrderInfo[]>([]);
  const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, pages: 0 });
  const { openModal, closeModal } = usePrompt();

  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    isProcessing,
    formData,
  } = useFormProcessor<UpdateDeliveryOrderDto>({
    deliveryOrderId: 0,
    deliveryOrderNumber: '',
    product: '',
    transferOrders: [],
  }, {
    deliveryOrderNumber: false,
    productId: false,
    transferOrders: false,
  });

  React.useEffect(() => {
    checkChanges({
      deliveryOrderNumber: formData.deliveryOrderNumber,
      deliveryOrderId: formData.deliveryOrderId,
      product: formData.product,
      transferOrders: formData.transferOrders
    });
  }, [formData, checkChanges])

  React.useEffect(() => {
    setIsProcessing(true);
    const fetch = async () => {
      try {
        if (id && !formData.deliveryOrderId) {
          const result = await axios.get<DeliveryOrderForEditInfoDto>(`${path}/${id}/DoForEdit`);
          if (result.data) {
            const { deliveryOrder, transferOrders, inventories } = result.data;
            const values = {
              deliveryOrderNumber: deliveryOrder.deliveryOrderNumber,
              deliveryOrderId: deliveryOrder.id,
              product: deliveryOrder.transferOrders[0].product,
              transferOrders: deliveryOrder.transferOrders.map(t => ({
                isSelected: true,
                transferOrderId: t.id,
                transferOrderNumber: t.transferOrderNumber,
                product: t.product,
                importer: t.importer,
                distributor: t.distributor,
                quantity: t.quantity,
                inventoryId: t.inventoryId,
              })),
            };
            handleOnChange(values);
            setInitialValues(values);
            setTransferOrdersResults(transferOrders.map(t => ({
              isSelected: false,
              transferOrderId: t.id,
              date: t.date,
              transferOrderNumber: t.transferOrderNumber,
              quantity: t.quantity,
              product: t.product,
              importer: t.importer,
              distributor: t.distributor,
              inventoryId: 0,
            })));
            setInventoryResults(inventories);
            setPageInfo({ currentPage: 1, pages: Math.ceil(result?.data?.transferOrders.length / 10) })
          }
        }
      } catch (error: any) {
        setError(error);
      }
      setIsProcessing(false);
    };
    fetch();
  }, [id, axios, setInitialValues, setError, setIsProcessing, formData.deliveryOrderId, handleOnChange]);

  React.useEffect(() => {
    setFormErrorMesssage('');
  }, [formData]);

  const handleSubmit = async () => {
    if (!formData.deliveryOrderNumber?.trim()) {
      return setFormErrorMesssage("Delivery order number is required.")
    }

    if (formData.transferOrders?.length === 0) {
      return setFormErrorMesssage("At least one transfer order is required to create a delivery order.")
    }

    setIsProcessing(true);

    try {
      const request = {
        deliveryOrderId: id,
        deliveryOrderNumber: formData.deliveryOrderNumber,
        transferOrders: formData.transferOrders.map(t => ({ transferOrderId: t.transferOrderId, inventoryId: t.inventoryId, quantity: t.quantity })),
      }

      await axios.put('DeliveryOrder', request);
      return goTo(`/delivery-order-detail/${id}`);
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  React.useEffect(() => {
    if (!transferOrdersResults.length) {
      setSortedResults([]);
    } else {
      const sorted = transferOrdersResults.sort(transferOrderSorter(sort));
      const end = pageInfo.currentPage * 10;
      const start = end - 10;
      setSortedResults(sorted.slice(start, end));
    }
  }, [transferOrdersResults, sort, pageInfo]);

  const resetAll = (info: TransferOrderInfo) => {
    handleOnChange({ transferOrders: [info] });
    closeModal();
  }

  const handleTransferSelectionChange = (info: TransferOrderInfo) => {
    // Added with different product, importer, distributor, clear the transfers and add the new transfer
    if (formData.transferOrders.some(t => t.product !== info.product || t.importer !== info.importer || t.distributor !== info.distributor)) {
      return openModal({
        ok: () => resetAll(info),
        title: 'Please Confirm Transfer Order Replacement!',
        text: 'You are adding a transfer order with a product, importer or distributor that is different from what was added before.\n\nAll transfers on the delivery order must have the same product, importer and distributor.\n\nDo you want to replace the current selected transfer order(s)?'
      });
      // Already has the transfer, so update
    } else if (formData.transferOrders.some(t => t.transferOrderId === info.transferOrderId)) {
      handleOnChange({ transferOrders: formData.transferOrders.map(t => t.transferOrderId === info.transferOrderId ? info : t) });
    } else {
      // Add another transfer
      handleOnChange({ transferOrders: [...formData.transferOrders, info] });
    }
  };

  const removeTransferOrder = (id: number) => {
    handleOnChange({ transferOrders: formData.transferOrders.filter(t => t.transferOrderId !== id) });
  };

  let isValid = false;
  if (formData.deliveryOrderNumber && formData?.transferOrders?.length > 0) {
    isValid = true;
  }

  const totalDeliveryOrders = formData.transferOrders.reduce((q, e) => q + Number(e.quantity), 0);
  const handlePaginate = (pageNumber: number) => setPageInfo({ ...pageInfo, currentPage: pageNumber });

  const hasInventory = inventoryResults.length > 0;
  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h4 text-dark">Edit Delivery Order</div>
        <div className="d-flex-jce">
          {isInRole(rCodes?.R300) && hasPermission(pCodes?.P300) && <NavigationButton link="/create-delivery-order" icon={faFolderPlus} label="Create Delivery Order" />}
          <NavigationButton link="/delivery-orders/list" icon={faList} label="Delivery Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Loading..." position="center" />}
      {(formErrorMessage || errorMessage) && <Alert type={AlertType.DANGER} message={formErrorMessage || errorMessage} />}
      {!!formData.deliveryOrderId && !isProcessing && <>
        <div className="col">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Delivery Order Number"
            placeholder="Enter Delivery Order Number."
            id="deliveryOrderNumber"
            value={formData.deliveryOrderNumber}
            name="deliveryOrderNumber"
            type="text"
            required={true}
          />
        </div>
        <div className="col my-3">
          <div>Delivery Order Total</div>
          <div className="h6">{displayQuantity(totalDeliveryOrders)} {`Gallon${totalDeliveryOrders > 1 ? 's' : ''}`}</div>
        </div>
        <SelectedTransferOrders
          hasInventory={hasInventory}
          transferOrders={formData.transferOrders}
          removeTransferOrder={removeTransferOrder}
          inventoryMap={hasInventory ? inventoryResults.reduce((r, i) => ({ ...r, [i.id]: i.storageTank }), {}): []}
        />
        <div className="h6 my-2 p-2 bg-dark text-light">Available Transfer Orders</div>
        <div className="table-responsive">
          <table className="table table-sm table-responsive create-dos-transfers-list align-middle">
            <thead>
              <tr className="border-bottom">
                <th scope="col">#</th>
                <TableHeader title='TO#' value='transferOrderNumber' onClick={setSort} />
                <TableHeader title='Product' value='product' onClick={setSort} />
                <TableHeader title='Importer' value='importer' onClick={setSort} />
                <TableHeader title='Distributor' value='distributor' onClick={setSort} />
                <th scope="col">Created</th>
                <TableHeader title='Available Quantity' value='quantity' onClick={setSort} />
                {hasInventory && <th scope="col">Inventory</th>}
                <th scope="col" colSpan={2}>Quantity Input</th>
              </tr>
            </thead>
            <tbody>
              {isProcessing && !sortedResults.length && <Loading loadingText="Loading available transfer orders..." position="left" size='sm' />}
              {sortedResults.map((t, i) =>
                <TransferOrderInputForm
                hasInventory={hasInventory}
                  inventories={inventoryResults.filter(i => `${i.importer}${i.product}` === `${t.importer}${t.product}`)}
                  transferOrder={t}
                  isSelected={formData.transferOrders.some(s => s.transferOrderId === t.transferOrderId)}
                  handleSelection={handleTransferSelectionChange}
                  key={t.transferOrderId}
                  index={i}
                />)}
              {!isProcessing && !sortedResults.length && <tr><td colSpan={7}>No transfer order available for delivery order.</td></tr>}
            </tbody>
          </table>
        </div>
      </>}
      <Pagination {...pageInfo} paginate={handlePaginate} />
      {renderNoChangeComponnent()}
      <div className="page-action-button-group">
        <BackButton disabled={isProcessing} />
        {hasChanged && <SubmitButton handleSubmit={handleSubmit} disabled={!isValid || isProcessing} />}
      </div>
    </div>
  );
}

