import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateCompartmentDto } from 'appDtos';
import { getValue } from '../../Shared/utils/numberInputUtil';

export function CompartmentModal(props: { addedCompartments: CreateCompartmentDto[]; totalDeliveryOrderQuantity: number; addCompartment: (compartment: CreateCompartmentDto) => void; }) {
  const [compartment, setCompartment] = React.useState<CreateCompartmentDto>({ } as CreateCompartmentDto);
  const [compartmentError, setCompartmentError] = React.useState<{ number: string; qty: string; destination: string; }>({ number: '', qty: '', destination: '' });

  const handleCompartmentChange = (e: any) => {
    setCompartmentError({ number: '', qty: '', destination: '' });
    let value = e.target.value;
    const key = e.target.name;
    if (key === 'quantity') {
      value = getValue(value);
    }
    setCompartment({ ...compartment, [key]: value });
  };

  const totalCompartmentQuantity = props.addedCompartments.reduce((q, e) => q + Number(e.quantity), 0) + Number(compartment.quantity);
  
  const handleAddCompartment = () => {
    let errors = { ...compartmentError };
    if (!compartment.compartmentNumber || props.addedCompartments.some(c => Number(c.compartmentNumber) === Number(compartment.compartmentNumber))) {
      errors = { ...errors, number: 'Compartment number is required and should be unique.' };
    }
    // if (!Number(compartment.bayNumber)) {
    //   errors = { ...errors, bay: 'Bay number is required.' };
    // }

    if (!Number(compartment.quantity)) {
      errors = { ...errors, qty: 'Quantity is required.' };
    }

    if (totalCompartmentQuantity > props.totalDeliveryOrderQuantity) {
      errors = { ...errors, qty: 'Total compartment quantity is greater than total DO quantity.' };
    }

    if (!compartment.destination) {
      errors = { ...errors, destination: 'Destination is required.' };
    }

    if (Object.values(errors).some(v => v)) {
      return setCompartmentError(errors);
    }

    props.addCompartment(compartment);
    setCompartment({
      compartmentNumber: '',
      bayNumber: '',
      destination: '',
      quantity: 0
    } as CreateCompartmentDto);
  };

  return (
    <div className="modal fade" id="compartmentModal" tabIndex={-1} aria-labelledby="compartmentModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="compartmentModal">Add New Compartment</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="form-floating mb-3">
              <input onChange={handleCompartmentChange} name="compartmentNumber" type="number" className="form-control input-group-sm" id="number" min="1" value={compartment?.compartmentNumber} autoComplete="off" />
              <label htmlFor="number">Compartment Number</label>
              <small className="text-danger">{compartmentError.number}</small>
            </div>
            {/* <div className="form-floating mb-3">
              <input onChange={handleCompartmentChange} name="bayNumber" type="number" className="form-control" id="bayNumber" min="1" value={compartment?.bayNumber} />
              <label htmlFor="bayNumber">Bay Number</label>
              <small className="text-danger">{compartmentError.bay}</small>
            </div> */}
            <div className="form-floating mb-3">
              <input onChange={handleCompartmentChange} name="quantity" type="text" className="form-control" id="quantity" value={compartment?.quantity} autoComplete="off" />
              <label htmlFor="quantity">Quantity</label>
              <small className="text-danger">{compartmentError.qty}</small>
            </div>
            <div className="form-floating mb-3">
              <input onChange={handleCompartmentChange} name="destination" type="text" className="form-control" id="destination" value={compartment?.destination} autoComplete="off" />
              <label htmlFor="destination">Destination</label>
              <small className="text-danger">{compartmentError.destination}</small>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" onClick={handleAddCompartment} className="btn btn-warning" data-bs-dismiss={totalCompartmentQuantity === props.totalDeliveryOrderQuantity ? 'modal' : ''}><span><FontAwesomeIcon size='lg' icon={faPlus} /></span> Add</button>
          </div>
        </div>
      </div>
    </div>
  );
}
