export interface LoadProps {
    position?: 'left' | 'center' | 'right' | "top",
    loadingText?: string,
    size?: string
}

export default function Loading({ position, size, loadingText }: LoadProps) {
    let height;
    switch (size) {
        case 'sm':
            height = 'p';
            break;
        case 'md':
            height = 'h5';
            break;
        default:
            height = 'h6';
            break;
    }
    return (
        <div className={`d-flex align-items-center w-100 justify-content-center ${height}`} >
            <span className={`spinner-border spinner-border-${size ?? 'lg'} mx-2`} role="status" aria-hidden="true"></span>
            {loadingText ?? ''}
        </div>
    )
}
