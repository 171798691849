import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import RequiredText from './RequiredText';
import Alert from './Alert';
import useApi from '../hooks/useApi';
import { useApiHandleError } from '../hooks/useApiHandleError';
import Icon from './Icon';
import { Action } from '../constant';
import { CommentDto } from 'appDtos';

export interface CommentModalProps {
  id?: number;
  action: string;
  comment?: CommentDto;
  path: string;
}

export function CommentModal(props: CommentModalProps) {
  const { errorMessage, setError } = useApiHandleError();
  const [comment, setComments] = React.useState('');
  const [isCommentRequiredError, setIsCommentRequiredError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [successResponse, setSuccessResponse] = React.useState('');
  const axios = useApi();

  React.useEffect(() => {
    if (errorMessage) {
      setError(undefined);
      setIsCommentRequiredError(false);
    }
    if (props.action === Action.EDIT_COMMENT && props.comment?.text) {
      setComments(props.comment.text);
    }
  }, [props.action, props.comment, errorMessage, setError]);

  const handleCommentChange = (e: any) => {
    setIsCommentRequiredError(false);
    setComments(e.target.value);
  };

  const isCommenting = props.action === Action.COMMENT;
  const isUpdatingComment = props.action === Action.EDIT_COMMENT;
  const isDeletingComment = props.action === Action.DELETE_COMMENT;

  const handleCommentSubmit = async () => {
    if (!comment?.trim()) {
      return setIsCommentRequiredError(true);
    }

    setIsLoading(true);

    try {
      const result = await axios.post(`${props.path}/Comments`, { entityId: props.id, text: comment.trim() });
      setSuccessResponse(result.data.message);
      setIsLoading(false);
      setComments('');
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const handleEditCommentSubmit = async () => {
    if (!comment?.trim()) {
      return setIsCommentRequiredError(true);
    }

    setIsLoading(true);

    try {
      const result = await axios.put(`${props.path}/Comments`, { entityId: props.id, commentId: props.comment?.id, text: comment.trim() });
      setSuccessResponse(result.data.message);
      setIsLoading(false);
      setComments('');
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const handleDeleteCommentSubmit = async () => {
    setIsLoading(true);

    try {
      const result = await axios.delete(`${props.path}/${props.id}/Comments/${props.comment?.id}`);
      setSuccessResponse(result.data.message);
      setIsLoading(false);
      setComments('');
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const handleSubmit = async () => {
    if (isCommenting) {
      return await handleCommentSubmit();
    } else if (isUpdatingComment) {
      return await handleEditCommentSubmit();
    } else if (isDeletingComment) {
      return await handleDeleteCommentSubmit();
    };
  };

  const renderHeaderText = () => {
    if(successResponse){
      return '';
    } else if (isCommenting) {
      return 'Add Comment';
    } else if (isUpdatingComment) {
      return 'Update Comment';
    } else if (isDeletingComment) {
      return 'Please Confirm!';
    }
  };

  const renderAlert = () => {
    if (successResponse) {
      return <Alert type="success" message={successResponse} />;
    } else if (errorMessage) {
      return <Alert type="danger" message={errorMessage} />;
    } else if (isCommentRequiredError) {
      return <Alert type="danger" message="Comment is required." />;
    } else if (isDeletingComment) {
      return <h6 className="h6">Are you sure you want to <strong>DELETE</strong> this comment?</h6>;
    } else {
      return null;
    }
  };

  const handleClose = () => {
    if (successResponse) {
      return window.location.reload();
    }
    setComments('');
    setError(undefined);
    setIsCommentRequiredError(false);
  };

  return (
    <div className="modal fade" data-bs-backdrop="static" id="CommentModal" tabIndex={-2} aria-labelledby="CommentModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{renderHeaderText()}</h5>
            <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {renderAlert()}
            {(!isDeletingComment && !successResponse) && <>
              <label htmlFor="basic-url" className="form-label mt-2 fw-bold">Comment <RequiredText /></label>
              <div className="input-group mb-2">
                <textarea onChange={handleCommentChange} className="form-control" aria-label="With textaresa" value={comment ?? ''}></textarea>
              </div>
            </>}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-secondary fw-bold" disabled={isLoading} data-bs-dismiss="modal">Close</button>
            {!successResponse && <button type="button" onClick={handleSubmit} disabled={isLoading || !!errorMessage} className="btn btn-warning fw-bold">
              {isLoading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...</> :
                <><span><Icon icon={faCheck} /></span> Submit</>}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}
