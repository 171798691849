import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import Icon from '../../../Shared/components/Icon'
import { passwordValidators as v} from '../../../Shared/components/validators';

export default function PasswordRules({ value }: { value: string }) {
    const renderIcon = (isValid: boolean) => <Icon icon={isValid ? faCheck : faX} size="sm" color={isValid ? "green" : "red"} />;
    return (
        <div className="password-requirement text-light mt-3">
            <h6>Password Requirements</h6>
            <ul>
                <li>Must be at least 10 characters. {renderIcon(v.isLength(value, 10))}</li>
                <li>Must have at least one of these non alphanumeric characters (!@#$). {renderIcon(v.hasSpecialChars(value))}</li>
                <li>Must have at least one digit (0-9). {renderIcon(v.hasDigit(value))}</li>
                <li>Must have at least one uppercase (A-Z). {renderIcon(v.hasUpperCase(value))}</li>
                <li>Must use at least 3 different characters. {renderIcon(v.hasMultipe(value))}</li>
            </ul>
        </div>
    );
}
