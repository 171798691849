import React from 'react'
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { UserAccountActivation } from '../../Auth/Components/UserAccountActivation';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { IPostRegisterUserDto, IRegisterUserResponse } from 'appDtos';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';

export default function UserRegister() {
  const axios = useApi();
  const [userId, setUserId] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    formData,
    isProcessing,
    hasError } = useFormProcessor<IPostRegisterUserDto>({} as IPostRegisterUserDto, { firstName: true, lastName: false, email: true, confirmedEmail: true });

  const handleSubmit = async () => {
    setIsProcessing(true);

    try {
      const response = await axios.post<IRegisterUserResponse>('Account/Register', formData);

      if (response?.data) {
        const { userId } = response?.data;
        setUserId(userId);
      }
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  if (userId) {
    return (
      <UserAccountActivation userId={userId} />
    )
  }

  const handleInternalCheck = (e: any) => {
    handleOnChange({isInternal: e.target.value === 'in'});
  }

  return (
    <>
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      <form className="p-3">
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="First Name"
          placeholder="Enter user's first name."
          id="firstName"
          value={formData.firstName}
          name="firstName"
          focusOnMount={true}
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Middle Initial"
          placeholder="Enter user's middle initial."
          id="middleInitial"
          value={formData.middleInitial?.toUpperCase()}
          name="middleInitial"
          type="text"
          validationFunction={(v: any) => v ? v.length <= 1 && !Number(v) : true}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Last Name"
          placeholder="Enter user's last name."
          id="lastName"
          value={formData.lastName}
          name="lastName"
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Email Address"
          placeholder="Enter user's email address."
          id="email"
          value={formData.email}
          name="email"
          type="text"
          required={true}
          validationFunction={isValidEmail}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Confirm Address"
          placeholder="Re-enter user's email address."
          id="confirmedEmail"
          value={formData.confirmedEmail}
          name="confirmedEmail"
          type="text"
          required={true}
          validationFunction={(value: string | number | undefined) => hasValue(value) && value === formData.email}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Primary Phone"
          placeholder="Enter user's primary phone if provided."
          id="phone"
          value={formData.phone}
          name="phone"
          type="text"
        />
        <div className="form-check">
          <input onChange={handleInternalCheck} className="form-check-input" type="checkbox" value="in" id="flexCheckDefault" checked={formData.isInternal === true}/>
            <label className="form-check-label" htmlFor="internal">
              Internal User (Check this box if this user is a LPRC employee.)
            </label>
        </div>
        <div className="form-check">
          <input onChange={handleInternalCheck} className="form-check-input" type="checkbox" value="ex" id="flexCheckChecked" checked={formData.isInternal === false}/>
            <label className="form-check-label" htmlFor="external">
              External User (Check this box if this user is a distributor or importer.)
            </label>
        </div>
        <button onClick={handleSubmit} type="button" disabled={isProcessing || hasError || formData.isInternal === undefined} className="btn btn-warning fw-bold mt-3">Submit</button>
      </form>
    </>
  );
}
