import { faDashboard, faFolderPlus, faList, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreateCompartmentDto, DeliveryOrderInfo, FillingAdviceNoteForEditInfoDto, IUpdateFillingAdviceNoteRequest } from 'appDtos';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import { usePrompt } from '../../Shared/components/PromptModal';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { SelectedDeliveryOrders } from '../deliveryOrders/SelectedTransferOrders';
import { DeliveryOrderInputForm } from '../deliveryOrders/TransferOrderInputForm';
import { CompartmentModal } from './CompartmentModal';

export default function EditFillingAdviceNote() {
  const goTo = useNavigate();
  const axios = useApi();
  const [deliveryOrdersResults, setDeliveryOrdersResults] = React.useState<DeliveryOrderInfo[]>([]);
  const [formErrorMessage, setErrorMesssage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const { id } = useParams();
  const { openModal, closeModal } = usePrompt();

  const path = 'FillingAdviceNote';

  const {
    handleOnChange,
    handleFieldError,
    isProcessing,
    setIsProcessing,
    formData,
  } = useFormProcessor<IUpdateFillingAdviceNoteRequest>({
    id: 0,
    vehicleNumber: '',
    deliveryOrders: [],
    compartments: [],
  }, {
    // cardNumber: false,
    vehicleNumber: false,
    deliveryOrders: false,
    compartments: false,
  });

  React.useEffect(() => {
    const search = async () => {
      try {
        const result = await axios.get<FillingAdviceNoteForEditInfoDto>(`${path}/${id}/FanForEdit`);
        if (result?.data) {
          const { deliveryOrders, fillingAdviceNote } = result.data;
          handleOnChange({
            isSelected: false,
            id: fillingAdviceNote.id,
            vehicleNumber: fillingAdviceNote.vehicleNumber,
            deliveryOrders: fillingAdviceNote.deliveryOrders.map(d => ({
              deliveryOrderId: d.id,
              product: fillingAdviceNote.product,
              deliveryOrderNumber: d.deliveryOrderNumber,
              priority: d.priority,
              quantity: d.quantity,
              SubmittedDate: d.submittedDate,
            })),
            compartments: fillingAdviceNote.compartments.map(c => ({
              compartmentId: c.id,
              compartmentNumber: c.compartmentNumber,
              // bayNumber: c.bayNumber,
              quantity: c.quantity,
              destination: c.destination,
            })),
          });
          setDeliveryOrdersResults(deliveryOrders.map(t => ({
            isSelected: false,
            deliveryOrderId: t.id,
            deliveryOrderNumber: t.deliveryOrderNumber,
            quantity: t.quantity,
            priority: t.priority,
            product: t.product,
          })));
        } else {
          setDeliveryOrdersResults([]);
        }
      } catch (error) {
        setError(error);
      }
    }
    if (id && !formData.id) {
      search();
    }
  }, [axios, id, formData.id, setError, handleOnChange])

  React.useEffect(() => {
    setErrorMesssage('');
  }, [formData]);

  const handleSubmit = async () => {
    // if (!Number(formData.cardNumber)) {
    //   return setErrorMesssage("Card number order number is required.")
    // }

    if (!formData.deliveryOrders?.length) {
      return setErrorMesssage("At least one delivery order is required to create a filling advice note.")
    }

    if (!formData.compartments?.length) {
      return setErrorMesssage("At least one compartment is required to create a filling advice note.")
    }

    setIsProcessing(true);

    try {
      const request = {
        id: Number(id),
        // cardNumber: Number(formData.cardNumber),
        vehicleNumber: formData.vehicleNumber,
        deliveryOrders: formData.deliveryOrders.map(t => ({ deliveryOrderId: Number(t.deliveryOrderId), quantity: Number(t.quantity) })),
        compartments: formData.compartments.map(c => ({...c, bayNumber : 0})),
      }

      await axios.put(path, request);
      return goTo(`/filling-advice-note-detail/${id}`);
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const resetAll = (info: DeliveryOrderInfo) => {
    handleOnChange({ deliveryOrders: [info] });
    closeModal();
  }

  const handleDeliveryOrderSelectionChange = (info: DeliveryOrderInfo) => {
    if (formData.deliveryOrders.some(t => t.product !== info.product)) {
      return openModal({
        ok: () => resetAll(info),
        title: 'Please Confirm Delivery Order Replacement!',
        text: 'You are adding delivery order with different product from what was added before.\nDo you want to replace the current selected delivery order(s)?'
      });
    } else {
      handleOnChange({ deliveryOrders: [...formData.deliveryOrders, info] });
    }
  };

  const addCompartment = (compartment: CreateCompartmentDto) => {
    handleOnChange({ compartments: [...formData.compartments, compartment] });
  };

  const removeDeliveryOrder = (id: number) => {
    handleOnChange({ deliveryOrders: formData.deliveryOrders.filter(t => t.deliveryOrderId !== id) });
  };

  const totalDeliveryOrderQuantity = formData.deliveryOrders.reduce((q, e) => q + Number(e.quantity), 0);
  const compartmentTotal = formData.compartments.reduce((r, e) => r + Number(e.quantity), 0);
  const unselectedDeliveryOrders = deliveryOrdersResults.filter(d => !formData.deliveryOrders.some(s => s.deliveryOrderId === d.deliveryOrderId));

  let isValid = false;
  if (formData.vehicleNumber && formData?.deliveryOrders?.length && compartmentTotal === totalDeliveryOrderQuantity) {
    isValid = true;
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h5 text-dark">Edit Filling Advice Note</div>
        <div className="d-flex-jce">
          {isInRole(rCodes?.R400) && hasPermission(pCodes?.P400) && <NavigationButton link="/create-filling-advice-note" icon={faFolderPlus} label="Create FAN" />}
          <NavigationButton link="/filling-advice-notes/list" icon={faList} label="Filling Advice Notes" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Processing..." position="center" />}
      {(!!errorMessage || !!formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
      {!isProcessing && <>
        {/* <div className="col">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Card Number"
            placeholder="Enter card number."
            id="cardNumber"
            value={formData.cardNumber}
            name="cardNumber"
            type="text"
            required={true}
          />
        </div> */}
        <div className="col">
          <ControlInputField
            onChange={handleOnChange}
            autoComplete="off"
            onFieldError={handleFieldError}
            label="Vehicle Number"
            placeholder="Enter vehicle number."
            id="vehicleNumber"
            value={formData.vehicleNumber}
            name="vehicleNumber"
            type="text"
            required={true}
          />
        </div>
        <div className="d-flex-jcs">
          <div className="col mt-3">
            <div>Delivery Order Total</div>
            <div className="h6">{displayQuantity(totalDeliveryOrderQuantity)} Gallons</div>
          </div>
          <div className="col mt-3">
            <div>Compartment Total</div>
            <div className="h6">{displayQuantity(compartmentTotal)} Gallons</div>
          </div>
          <div className="col mt-3">
            <div>Difference</div>
            <div className="h6">{displayQuantity(totalDeliveryOrderQuantity - compartmentTotal)} Gallons</div>
          </div>
          <button disabled={(!totalDeliveryOrderQuantity || (compartmentTotal === totalDeliveryOrderQuantity))} data-bs-toggle="modal" data-bs-target="#compartmentModal" type="button" className="btn btn-warning btn-sm fw-bold my-4"><span><FontAwesomeIcon size='lg' icon={faPlus} /></span> Add Compartment</button>
        </div>

        <div className="h6 p-2 bg-dark text-light">Compartments</div>
        <div className="table-responsive">
          <table className="table table-sm table-responsive align-middle">
            <thead>
              <tr className="border-bottom">
                <th scope="col">Compartment #</th>
                {/* <th scope="col">Bay Number</th> */}
                <th scope="col">Quantity</th>
                <th scope="col">Destination</th>
              </tr>
            </thead>
            <tbody>
              {formData.compartments.length > 0 ? formData.compartments.sort((a, b) => Number(a.compartmentNumber) - Number(b.compartmentNumber)).map(c =>
                <tr key={c.compartmentNumber}>
                  <th scope="row">{c.compartmentNumber}</th>
                  {/* <td>{c.bayNumber}</td> */}
                  <td>{displayQuantity(c.quantity)}</td>
                  <td title={c.destination}><div className="trucated-table-cell">{c.destination}</div></td>
                  <td className="table-cell-right">
                    <button onClick={() => handleOnChange({ compartments: formData.compartments.filter(fc => fc.compartmentNumber !== c.compartmentNumber) })} type="button" className="add-button btn btn-danger fw-bold btn-sm"><span><FontAwesomeIcon size='lg' icon={faTrash} /></span> Remove</button>
                  </td>
                </tr>
              ) : <tr><td colSpan={4}>No compartment added.</td></tr>}
            </tbody>
          </table>
        </div>
        <SelectedDeliveryOrders deliveryOrders={formData.deliveryOrders} removeDeliveryOrder={removeDeliveryOrder} />

        <div className="h6 p-2 bg-dark text-light">Available Delivery Orders</div>
        <div className="table-responsive">
          <table className="table table-sm table-responsive align-middle data-table">
            <thead>
              <tr className="border-bottom">
                <th scope="col">#</th>
                <th scope="col">Delivery Order #</th>
                <th scope="col">Product</th>
                <th scope="col">Priority</th>
                <th scope="col" colSpan={2}>Quantity</th>
              </tr>
            </thead>
            <tbody className="scrollable-table-body">
              {unselectedDeliveryOrders.length ? unselectedDeliveryOrders.map((d, i) =>
                <DeliveryOrderInputForm
                  deliveryOrder={d}
                  handleSelection={handleDeliveryOrderSelectionChange}
                  key={d.deliveryOrderId}
                  index={i}
                />) : <tr><td colSpan={5}>No available delivery orders.</td></tr>}
            </tbody>
          </table>
        </div>
      </>}
      <div className="page-action-button-group">
        <BackButton disabled={isProcessing} />
        <SubmitButton handleSubmit={handleSubmit} disabled={!isValid || isProcessing} />
      </div>
      <CompartmentModal addCompartment={addCompartment} totalDeliveryOrderQuantity={totalDeliveryOrderQuantity} addedCompartments={formData.compartments} />
    </div>
  );
}
