import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDistributorDetailDto } from 'appDtos';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Loading from '../../../Shared/components/Loading';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useMode } from '../../../Shared/hooks/useMode';
import { formatDate } from '../../../Shared/utils/date';
import { EditDistributor } from './EditDistributor';

export function DistributorDetail({ id }: { id: string; }) {
    const [distributor, setDistributor] = React.useState<IDistributorDetailDto>({} as IDistributorDetailDto);
    const axios = useApi();
    const {mode, updateMode} = useMode();

    React.useEffect(() => {
            const fetchUserDetail = async () => {
                if (id) {
                    const response = await axios.get<IDistributorDetailDto>(`Distributor/${id}`);
                    setDistributor(response.data);
                }
            };
            fetchUserDetail();
    }, [id, axios, mode]);

    if (!distributor.id) {
        return <Loading />
    }

    return mode === Mode.DETAIL ? (
        <div>
            <HeadingElement text="Distributor Detail" />
            <div className="p-2">
                <Field title="Name" value={distributor.name} />
                <Field title="Tax ID" value={distributor.taxIdentificationNumber} />
                <Field title="Registration Date" value={distributor.registrationDate? formatDate(distributor.registrationDate) :'NA'} />
                <Field title="Address" value={distributor.address} />
                <Field title="Primary Phone" value={distributor.primaryPhone} />
                <Field title="Primary Email" value={distributor.primaryEmail} />
                <Field title="Secondary Phone" value={distributor.secondaryPhone  ?? 'NA'} />
                <Field title="Secondary Email" value={distributor.secondaryEmail ?? 'NA'} />
                <Field title="Contact Person" value={distributor.contactPerson  ?? 'NA'} />
                <Field title="Status" value={distributor.isActive ? 'Active' : 'Inactive'} className={distributor.isActive ? "text-success" : "text-danger"} />
            </div>
            <button onClick={() => updateMode(Mode.EDIT)} className="btn btn-warning fw-bold mt-4"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faEdit} /></span> Edit distributor</button>
        </div>
    ) : <EditDistributor distributor={distributor} setMode={updateMode}/>
}

export function Field({ title, value, className }: { title: string, value: string, className?: string }) {
    return (
        <div className="mx-1 p-1">
            <div className="">{title}</div>
            <div className={className ? `fw-bold ${className}`: 'fw-bold'}>{value}</div>
        </div>
    )
}