import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAssociatedEntity, IRoleDto, IUserPermissionDto } from 'appDtos';
import React from 'react';

const IconComponent = () => {
  return <FontAwesomeIcon size='lg' color='red' bounce={true} icon={faWarning} />;
}

export function UserRoles(props: { roles: IRoleDto[]; }) {
  if (!props.roles.length) {
    return <div><IconComponent/> This user does not have any assigned role.</div>
  }
  return (
    <ul className="list-group">
      {props.roles.map(role => (
        <li className="list-group-item d-flex justify-content-between align-items-center" key={role.id}>
          <div className="ms-2 me-auto">
            <div className="fw-normal">{role.name}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}


export function UserPermissionComponent(props: { permissions: IUserPermissionDto[]; }) {
  if (!props.permissions.length) {
    return <div><IconComponent/> User does not have any permission.</div>
  }
  
  return (
    <ul className="list-group">
      {props.permissions.map(permission => (
        <li className="list-group-item d-flex justify-content-between align-items-center" key={permission.id}>
          <div className="ms-2 me-auto">
            <div className="fw-normal">{permission.name}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}


export function UserEntities(props: { authorizations: IAssociatedEntity[]; }) {
  if (!props.authorizations.length) {
    return <div><IconComponent/> This external user is not associated to any importer or distributor entity.</div>
  }

  return (
    <ul className="list-group">
      {props.authorizations.map(entity => (
        <li className="list-group-item" key={entity.id}>
          <div className="ms-2 me-auto">
            <div className="fw-normal">{entity.name}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}
