import { faPrint, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { DateRange, DateRangePickerComponent } from '../../Shared/components/DatePicker';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import { reportType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAppFilters from '../../Shared/hooks/useAppFilters';
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { useMetric } from '../../Shared/hooks/useMetric';
import { usePrinterRef } from '../../Shared/hooks/usePrinterRef';
import { setMidnight } from '../../Shared/utils/date';
import { PrintingVersion } from './components/PrintingVersion';
import Toggle from './components/Toggle';
import { useReportExporter } from './components/useReportExporter';

interface Report {
    importer: string;
    product: string;
    transactions: Transaction[]
}
interface Transaction {
    date: string;
    type: string;
    receiver: string;
    quantity: number;
    isPositive: boolean;
}

interface Filter {
    importer: string;
    product: string;
    from: Date | null,
    to: Date | null
}
export const ProductMovementReport = () => {
    const API = useApi();
    const { filters } = useAppFilters();
    const { toggleUnit, isMetric, toMetricTonString } = useMetric('__pmByImp');
    const { errorMessage, setError } = useApiHandleError();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { componentRef, handlePrint } = usePrinterRef();
    const { tableRef, Button } = useReportExporter({ sheet: reportType.ProductMovement });
    const [report, setReport] = useState<Report>(() => {
        const report = localStorage.getItem(StorageKeys.PRODUCT_MOVEMENT_BY_IMPORTER);
        return report ? JSON.parse(report) : [];
    });
    const [filter, setFilter] = useState<Filter>({ importer: report.importer, product: report.product, from: null, to: null })

    const { importers, products } = useMemo(() => ({
        importers: filters?.entityFilters?.importers ?? [],
        products: filters?.entityFilters?.products ?? []
    }), [filters?.entityFilters]);

    const hasValue = Boolean(filter.importer && filter.product);

    useEffect(() => {
        (async () => {
            if (refresh) {
                setLoading(true);
                errorMessage && setError('');
                try {
                    const { from, to, importer, product } = filter;

                    const data = {
                        importerId: importers.find(i => i.value === importer)?.key,
                        productId: products.find(i => i.value === product)?.key,
                        from: setMidnight(from),
                        to: setMidnight(to, true)
                    };
                    const res = await API.post('/Report/ProductMovement', data);
                    const responseData = { ...res.data, importer: filter.importer, product: filter.product };
                    localStorage.setItem(StorageKeys.PRODUCT_MOVEMENT_BY_IMPORTER, JSON.stringify(responseData));
                    setReport(responseData);
                } catch (error) {
                    setError(error)
                }
                setRefresh(false);
                setLoading(false);
            }
        })()
    }, [importers, products, refresh, API, setError, errorMessage, filter])

    const handleImporterChange = (e: any) => setFilter({ ...filter, importer: e.target.value });
    const handleProductChange = (e: any) => setFilter({ ...filter, product: e.target.value });
    const handleDateChange = (dateRange: DateRange) => setFilter({ ...filter, ...dateRange });

    const renderComponent = () => {
        return (
            <div className="border p-0 table-responsive">
                <table className="table table-sm m-0" ref={tableRef}>
                    <thead className="table-secondary report-table-container">
                        <tr>
                            <th className="fixed-width">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">Receiver</th>
                            <th scope="col">Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {report?.transactions ? report.transactions.map((t, i) =>
                            <tr key={i}>
                                <th className="fixed-width">{i + 1}.</th>
                                <td className="">{t.date}</td>
                                <td>{t.type}</td>
                                <td>{t.receiver ?? '-'}</td>
                                <td>{t.isPositive ? toMetricTonString(t.quantity) : `(${toMetricTonString(t.quantity)})`}</td>
                            </tr>) : <div>No results match your criteria.</div>}
                    </tbody>
                </table>
            </div>
        );
    }

    const getHeaderProps = () => {
        const props = [`${reportType.OvernightDifferences} Report`, `${filter.importer} - ${filter.product}`];

        if ((filter.from || filter.to) && filter.from === filter.to) {
            props.push(`Date: ${(filter?.from || filter?.to)?.toLocaleDateString()}`);
        } else if (filter.from && filter.to && filter.from !== filter.to) {
            props.push(`Period: ${filter.from.toLocaleDateString()} - ${filter.to.toLocaleDateString()}`);
        } else if (filter.from && !filter.to) {
            props.push(`From: ${filter.from.toLocaleDateString()}`);
        } else if (filter.to && !filter.from) {
            props.push(`Up to: ${filter.to.toLocaleDateString()}`);
        }
        return props;
    }


    const renderPrintVersion = () => {
        const element = renderComponent();
        return <PrintingVersion headerProps={getHeaderProps()} element={element} ref={componentRef} shouldRender={Boolean(report?.transactions?.length)} />;
    }

    return (
        <>
            <div className="d-flex-jcb p-2 border mt-3 mb-3">
                <Toggle checked={isMetric} label="Metric tonnes" handlier={toggleUnit} />
                <div className="d-flex-jcb">
                    <button
                        onClick={handlePrint}
                        className="btn btn-outline-dark fw-bold m-1 btn-sm">
                        <Icon icon={faPrint} color="dark" /> Print
                    </button>
                    {Button}
                    <button
                        disabled={!hasValue}
                        onClick={() => setRefresh(true)}
                        className="btn btn-dark fw-bold m-1 btn-sm">
                        <Icon icon={faRefresh} color="white" /> Refresh
                    </button>
                </div>
            </div>
            <div className="d-flex-jcb align-items-center p-2 border mt-3 mb-3">
                <div className="d-flex justify-content-start align-items-center mb-2">
                    <select className="form-select mx-2" aria-label="Default From Importer" value={filter.importer} onChange={handleImporterChange}>
                        <option value="">Select Importer</option>
                        {importers.map((i) => <option value={i.value} key={i.value}>{i.value}</option>)}
                    </select>
                    <select className="form-select mx-2" aria-label="Default From Product" value={filter.product} onChange={handleProductChange}>
                        <option value="">Select Product</option>
                        {products.map((i) => <option value={i.value} key={i.value}>{i.value}</option>)}
                    </select>
                </div>
                <DateRangePickerComponent placeholder="Select date" dateRange={{ from: filter.from, to: filter.to }} setDate={handleDateChange} />
            </div>
            {Boolean(errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {loading ? <Loading /> : report?.transactions?.length ? renderComponent() : <div>No results match your criteria.</div>}
            {renderPrintVersion()}
        </>
    )
}


