import { faDashboard, faList, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { CreateDeliveryOrderDto, DeliveryOrderCreationInfo, InventoryForDeliveryOrderDto, TransferOrderInfo } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import { usePrompt } from '../../Shared/components/PromptModal';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { SelectedTransferOrders } from './SelectedTransferOrders';
import { TransferOrderInputForm } from './TransferOrderInputForm';

export default function CreateDeliveryOrder() {
  const goTo = useNavigate();
  const axios = useApi();
  const [transferOrdersResults, setTransferOrdersResults] = React.useState<TransferOrderInfo[]>([]);
  const [inventoryResults, setInventoryResults] = React.useState<InventoryForDeliveryOrderDto[]>([]);
  const { errorMessage, setError } = useApiHandleError();
  const [formError, setErrorMesssage] = React.useState('');
  //const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, pages: 0 });
  const { openModal, closeModal } = usePrompt();
  const [toFilters, setToFilters] = React.useState({ toNumber: '', product: '', importer: '', distributor: '' });

  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    isProcessing,
    formData,
  } = useFormProcessor<CreateDeliveryOrderDto>({
    deliveryOrderNumber: '',
    product: '',
    transferOrders: [],

  }, {
    deliveryOrderNumber: false,
    productId: false,
    transferOrders: false,
  });

  React.useEffect(() => {
    const search = async () => {
      try {
        setIsProcessing(true);
        const result = await axios.get<DeliveryOrderCreationInfo>('DeliveryOrder/DeliveryOrderCreationInfo');
        if (result?.data?.transferOrders.length) {
          setTransferOrdersResults(result?.data?.transferOrders.map(t => ({
            isSelected: false,
            transferOrderId: t.id,
            transferOrderNumber: t.transferOrderNumber,
            quantity: t.quantity,
            date: t.date,
            product: t.product,
            importer: t.importer,
            distributor: t.distributor,
            inventoryId: 0,
          })));
          setInventoryResults(result?.data?.inventories);
          //setPageInfo({ currentPage: 1, pages: Math.ceil(result?.data?.transferOrders.length / 10) })
        }
      } catch (error) {
        setError(error);
        setTransferOrdersResults([]);
      }
      setIsProcessing(false);
    }
    search();
  }, [axios, setError, setIsProcessing])

  // React.useEffect(() => {
  //   if (!transferOrdersResults.length) {
  //     setSortedResults([]);
  //   } else {
  //     const sorted = transferOrdersResults.sort(transferOrderSorter(sort));
  //     const end = pageInfo.currentPage * 10;
  //     const start = end - 10;
  //     setSortedResults(sorted.slice(start, end));
  //   }
  // }, [transferOrdersResults, sort, pageInfo]);

  //const handlePaginate = (pageNumber: number) => setPageInfo({ ...pageInfo, currentPage: pageNumber });

  React.useEffect(() => {
    setErrorMesssage('');
  }, [formData]);

  const handleSubmit = async () => {
    if (!formData.deliveryOrderNumber?.trim()) {
      return setErrorMesssage("Delivery order number is required.")
    }

    if (formData.transferOrders?.length === 0) {
      return setErrorMesssage("At least one transfer order is required to create a delivery order.")
    }

    setIsProcessing(true);

    try {
      const request = {
        deliveryOrderNumber: formData.deliveryOrderNumber,
        transferOrders: formData.transferOrders.map(t => ({ transferOrderId: t.transferOrderId, inventoryId: t.inventoryId, quantity: t.quantity })),
      }
      await axios.post('DeliveryOrder', request);
      return goTo('/delivery-orders/list');
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const resetAll = (info: TransferOrderInfo) => {
    handleOnChange({ transferOrders: [info] });
    closeModal();
  }

  const handleTransferSelectionChange = (info: TransferOrderInfo) => {
    // Added with different product, importer, distributor, clear the transfers and add the new transfer
    if (formData.transferOrders.some(t => t.product !== info.product || t.importer !== info.importer || t.distributor !== info.distributor)) {
      return openModal({
        ok: () => resetAll(info),
        title: 'Please Confirm Transfer Order Replacement!',
        text: 'You are adding a transfer order with a product, importer or distributor that is different from what was added before.\n\nAll transfers on the delivery order must have the same product, importer and distributor.\n\nDo you want to replace the current selected transfer order(s)?'
      });
      // Already has the transfer, so update
    } else if (formData.transferOrders.some(t => t.transferOrderId === info.transferOrderId)) {
      handleOnChange({ transferOrders: formData.transferOrders.map(t => t.transferOrderId === info.transferOrderId ? info : t) });
    } else {
      // Add another transfer
      handleOnChange({ transferOrders: [...formData.transferOrders, info] });
    }
  };

  const removeTransferOrder = (id: number) => {
    handleOnChange({ transferOrders: formData.transferOrders.filter(t => t.transferOrderId !== id) });
  };

  const unselectedTransferOrders = transferOrdersResults.filter(d => !formData.transferOrders.some(s => s.transferOrderId === d.transferOrderId));

  let isValid = false;
  if (formData.deliveryOrderNumber && formData?.transferOrders?.length > 0) {
    isValid = true;
  }

  const totalDeliveryOrders = formData.transferOrders.reduce((q, e) => q + Number(e.quantity), 0);

  const hasInventory = inventoryResults.length > 0;

  const filteredDeliveryOrders = React.useMemo(() => {
    if (!unselectedTransferOrders.length) {
      return [];
    }
    let dos = unselectedTransferOrders;

    if (toFilters.toNumber) dos = dos.filter(d => d.transferOrderNumber.toLowerCase().startsWith(toFilters.toNumber.toLowerCase()));
    if (toFilters.product) dos = dos.filter(d => toFilters.product === d.product);
    if (toFilters.importer) dos = dos.filter(d => toFilters.importer === d.importer);
    if (toFilters.distributor) dos = dos.filter(d => toFilters.distributor === d.distributor);

    return dos;
  }, [unselectedTransferOrders, toFilters]);

  const products = (transferOrdersResults.map(d => d.product) as string[]).reduce((r: string[], e: string) => r.length > 0 && r.includes(e) ? r : [...r, e], []);
  const importers = (transferOrdersResults.map(d => d.importer) as string[]).reduce((r: string[], e: string) => r.length > 0 && r.includes(e) ? r : [...r, e], []);
  const distributors = (transferOrdersResults.map(d => d.distributor) as string[]).reduce((r: string[], e: string) => r.length > 0 && r.includes(e) ? r : [...r, e], []);

  const handleToFilterChange = (e: any) => setToFilters({ ...toFilters, [e.target.name]: e.target.value });

  const clearFilters = () => {
    setToFilters({ ...toFilters, toNumber: '', product: '', importer: '', distributor: '' })
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h4 text-dark">Create Delivery Order</div>
        <div className="d-flex-jce">
          <NavigationButton link="/delivery-orders/list" icon={faList} label="Delivery Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {!!(errorMessage || formError) && <Alert type={AlertType.DANGER} message={errorMessage || formError} />}
      <div className="col">
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Delivery Order Number"
          placeholder="Enter Delivery Order Number."
          id="doNumber"
          value={formData.deliveryOrderNumber}
          name="deliveryOrderNumber"
          type="text"
          required={true}
        />
      </div>
      <div className="col my-3">
        <div>Delivery Order Total</div>
        <div className="h6">{displayQuantity(totalDeliveryOrders)} {`Gallon${totalDeliveryOrders > 1 ? 's' : ''}`}</div>
      </div>
      <SelectedTransferOrders
        hasInventory={hasInventory}
        transferOrders={formData.transferOrders}
        removeTransferOrder={removeTransferOrder}
        inventoryMap={inventoryResults.reduce((r, i) => ({ ...r, [i.id]: i.storageTank }), {})}
      />
      <div className="h6 my-2 p-2 bg-dark text-light">Available Transfer Orders</div>
      <div className="table-responsive">
        <table className="table table-sm table-responsive create-dos-transfers-list align-middle">
          <thead>
            <tr className="border-bottom">
              <th scope="col">#</th>
              <th scope="col">
                TO#
                <input name="toNumber" onChange={handleToFilterChange} value={toFilters.toNumber} type="text" className="form-control" id="toNumberInput" placeholder="Enter TO#" />
              </th>
              <th className="col-2" scope="col">
                Product
                <select name="product" className="form-select" aria-label="default product" value={toFilters.product} onChange={handleToFilterChange}>
                  <option value={''}>Select Product</option>
                  {products.map((i) => <option value={i} key={i}>{i}</option>)}
                </select>
              </th>
              <th className="col-2" scope="col">
                Importer
                <select name="importer" className="form-select" aria-label="default importer" value={toFilters.importer} onChange={handleToFilterChange}>
                  <option value={''}>Select Importer</option>
                  {importers.map((i) => <option value={i} key={i}>{i}</option>)}
                </select>
              </th>
              <th className="col-2" scope="col">
                Distributor
                <select name="distributor" className="form-select" aria-label="default distributor" value={toFilters.importer} onChange={handleToFilterChange}>
                  <option value={''}>Select Distributor</option>
                  {distributors.map((i) => <option value={i} key={i}>{i}</option>)}
                </select>
              </th>
              <th className="col-2" scope="col">
                Created
              </th>
              <th className="col"scope="col">
                Available Quantity
              </th>
              {hasInventory && <th className="col-2" scope="col">Inventory</th>}
              <th className="col-3" scope="col">Quantity Input</th>
              <th scope="col">
                <button onClick={clearFilters} type="button" className="btn btn-warning bt-md fw-bold w-100 ">Clear</button>
              </th>
            </tr>
          </thead>
          <tbody className="">
            {isProcessing && !transferOrdersResults.length && <tr><td className="p-4" colSpan={7}><Loading loadingText="Loading available transfer orders..." position="left" size='md' /></td></tr>}
            {filteredDeliveryOrders.map((t, i) =>
              <TransferOrderInputForm
                hasInventory={hasInventory}
                inventories={hasInventory ? inventoryResults.filter(i => `${i.importer}${i.product}` === `${t.importer}${t.product}`) : []}
                transferOrder={t}
                isSelected={formData.transferOrders.some(s => s.transferOrderId === t.transferOrderId)}
                handleSelection={handleTransferSelectionChange}
                key={t.transferOrderId}
                index={i}
              />)}
            {!isProcessing && !transferOrdersResults.length && <tr><td colSpan={7}>No transfer order available for delivery order.</td></tr>}
          </tbody>
        </table>
      </div>
      <div className="page-action-button-group">
        <BackButton disabled={isProcessing} />
        <SubmitButton handleSubmit={handleSubmit} disabled={!isValid || isProcessing} />
      </div>
    </div>
  );
}

export function transferOrderSorter(sort: { sortBy: string; sortOrder: number; }): ((a: TransferOrderInfo, b: TransferOrderInfo) => number) | undefined {
  return (a: TransferOrderInfo, b: TransferOrderInfo) => {
    const { sortBy, sortOrder } = sort;
    if (!(sortBy === 'transferOrderNumber' || sortBy === 'quantity' || sortBy === 'product' || sortBy === 'distributor' || sortBy === 'importer')) {
      return 0;
    }

    if (sortOrder === 0) {
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
    }

    if (a[sortBy] < b[sortBy]) {
      return 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return -1;
    }

    return 0;
  };
}

export function TableHeader({ title, value, onClick }: { title: string, value: string, onClick: (v: { sortBy: string, sortOrder: number }) => void }) {
  const [sortUp, setSortUp] = React.useState(false);

  const handleSort = () => {
    onClick({ sortBy: value, sortOrder: sortUp ? 1 : 0 });
    setSortUp(!sortUp);
  }

  return (
    <th scope="col">{title}<button onClick={handleSort} type="button" className="btn btn-link btn-sm p-0 m-0">  <Icon icon={sortUp ? faSortUp : faSortDown} /></button></th>
  )
}


