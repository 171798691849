import React from 'react';
import axios from '../../../Shared/Api/axios';

import ControlInputField from '../../../Shared/components/ControlInputField';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { ForgetPasswordRequest, ForgetPasswordResponse } from 'appDtos';
import { Link } from 'react-router-dom';
import { isValidEmail } from '../../../Shared/components/validators';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import Alert, { AlertType } from '../../../Shared/components/Alert';

import './passwordForgetEmailSubmission.css';

export default function PasswordForgetEmailSubmission() {
    const [message, setResponseMessage] = React.useState('');
    const { errorMessage, setError } = useApiHandleError();
    const {
        handleOnChange,
        setIsProcessing,
        handleFieldError,
        formData,
        hasError,
        isProcessing,
    } = useFormProcessor<ForgetPasswordRequest>({ email: '' });

    const handleSubmit = async () => {
        setIsProcessing(true);
        try {
            const result = await axios.post<ForgetPasswordResponse>('Account/ForgotPassword', formData);
            if (result?.data) {
                setResponseMessage(result.data.message);
                handleOnChange({ email: '' });
            }
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    }

    return (
        <div className='forgot-email-form mx-auto'>
            <h4 className="h4 text-warning border-2 border-bottom border-warning">{message ? "Email Submitted!" : "Reset Your Password"}</h4>
            {!message && <p className="text-light">Enter the email associated with your account and you will be sent instructions to reset your password.</p>}
            {(!!errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {message ?
                <Alert type={AlertType.SUCCESS} message={message} />
                :
                <>
                    <form>
                        <ControlInputField
                            onChange={handleOnChange}
                            onFieldError={handleFieldError}
                            label="Email Address"
                            placeholder="Enter email"
                            id="email"
                            value={formData.email}
                            name="email"
                            type="text"
                            required={true}
                            validationFunction={isValidEmail}
                        />
                        <button disabled={hasError || isProcessing || !!message} onClick={handleSubmit} type="button" className="btn btn-md btn-warning fw-bold w-100">
                            {isProcessing && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}  {isProcessing ? 'Processing...' : 'Submit'}
                        </button>
                    </form>
                    <Link to="/" className="btn btn-md btn-link my-2 text-light fw-bold">Go to login</Link>
                </>
            }
        </div>
    )
}
