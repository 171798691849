import { faCheck, faComment, faDashboard, faEdit, faFolderPlus, faList, faPrint, faXmark } from '@fortawesome/free-solid-svg-icons';
import { CommentDto, CompartmentDto, FillingAdviceNoteDetailDto } from 'appDtos';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { CommentModal } from '../../Shared/components/CommentModal';
import { ConfirmModal, IssueFanModal } from '../../Shared/components/ConfirmModal';
import HeadingElement from '../../Shared/components/HeadingElement';
import { CommentComponent } from '../../Shared/components/History';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import ModalToggleButton from '../../Shared/components/ModalToggleButton';
import { BackButton, NavigationButton } from '../../Shared/components/NavigationButton';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useAuth from '../../Shared/hooks/useAuth';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import DeliveryOrderTable, { CompartmentTable } from '../deliveryOrders/DeliveryOrderTable';

enum Action {
  NONE = '',
  COMMENT = 'Comment',
  ISSUE = 'issue',
  CANCEL = 'cancel',
}

export function FillingAdviceNoteDetail() {
  const [detail, setFillingAdviceNoteDetail] = React.useState<FillingAdviceNoteDetailDto>();
  const axios = useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef(false);
  const goTo = useNavigate();
  const { id } = useParams();
  const path = 'FillingAdviceNote';
  const [action, setAction] = React.useState<Action>(Action.NONE);
  const { errorMessage, setError } = useApiHandleError();
  const { isInRole, hasPermission, rCodes, pCodes, getUser } = useAuth();
  const [comment, setComment] = React.useState<CommentDto | undefined>();

  React.useEffect(() => {
    if (!ref.current) {
      setIsLoading(true);
      const fetch = async () => {
        try {
          if (id && !detail) {
            const result = await axios.get<FillingAdviceNoteDetailDto>(`${path}/${id}`);
            if (result.data) {
              setFillingAdviceNoteDetail(result.data);
            } else {

            }
          }
        } catch (error) {
          setError(error);
        }
        setIsLoading(false);
      };
      fetch();
    }

    return () => {
      ref.current = true;
    };

  }, [id, axios, detail, setError]);

  const handleClick = async () => {
    try {
      const map: { [k: string]: number } = { [Action.ISSUE]: 10, [Action.CANCEL]: 20 };
      await axios.post('FillingAdviceNote/StatusUpdate', { id, status: map[action] });
      if (action === Action.CANCEL) {
        goTo('/filling-advice-notes/list');
      } else {
        window.location.reload();
      }
    } catch (error: any) {
      setError(error);
    }
  }

  const handleIssueFanClick = async ({ cardNumber, compartments }: { cardNumber: number, compartments: CompartmentDto[] }) => {
    try {
      await axios.post('FillingAdviceNote/StatusUpdate', { id, status: 10, cardNumber, compartments });
      window.location.reload();
    } catch (error: any) {
      setError(error);
    }
  }

  const confirmBodyElement = (
    <>
      <div className="h6 mb-3">{`Are you sure you want to ${action.toUpperCase()} this Filling Advice Note?`}</div>
      {action === Action.ISSUE && <p>Clicking the confirm button will issue this FAN, fulfill all delivery orders, and deliver all qualified transfer orders associated with these DOs.</p>}
      {action === Action.CANCEL && <p>Clicking confirm button will <strong>CANCEL</strong> this FAN, set all delivery orders on this FAN back to "Approved" status.<br /><br /> <strong className="text-danger">NOTE: This action CAN NOT be reverted.</strong></p>}
    </>
  );

  const handleCommentAction = ({ comment, action }: { comment: CommentDto, action: string }) => {
    setAction(action as Action);
    setComment(comment);
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <h4 className="text-dark noScreen border-bottom border-4">LPRC Petroleum Products Management System (PPMS)</h4>
        <div className="h4 text-dark noScreen">Filling Advice Note</div>
        <div className="h4 text-dark noPrint">Filling Advice Note Detail</div>
        <div className="d-flex-jce noPrint">
          {(detail?.status === 'Issued' && isInRole(rCodes?.R400) && hasPermission(pCodes?.P401)) && <button onClick={window.print} className="btn btn-warning btn-md fw-bold m-1"><Icon icon={faPrint} /> Print</button>}
          {(detail?.isEditable ?? false) && <NavigationButton link={`/edit-filling-advice-note/${detail?.id}`} icon={faEdit} label="Edit FAN" />}
          {isInRole(rCodes?.R300) && hasPermission(pCodes?.P400) && <NavigationButton link="/create-filling-advice-note" icon={faFolderPlus} label="Create FAN" />}
          <NavigationButton link="/filling-advice-notes/list" icon={faList} label="Filling Advice Notes" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isLoading && <Loading loadingText="Processing..." position="center" />}
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      {!!detail &&
        <div className="mb-5 mt-2">
          <PrintableHeading fan={detail} user={getUser()?.fullName} />
          <HeadingElement text="Detail Information" />
          <PrintVersionFan fan={detail} />
          <div className="d-flex-jcs noPrint">
            <div className="p-1 col-md-3">
              <p className="mb-0">FAN Number</p>
              <p className="fw-bold">{detail.id}</p>
              <p className="mb-0">Card Number</p>
              <p className={`fw-bold ${detail.cardNumber === 0 ? 'text-danger' : ''}`}>{detail.cardNumber > 0 ? detail.cardNumber : 'Pending'}</p>
            </div>
            <div className="p-1 col-md-3">
              <p className="mb-0">Created Date</p>
              <p className="fw-bold">{formatDate(detail.createdDate)}</p>
              <p className="mb-0">Created By</p>
              <p className="fw-bold">{detail.createdBy}</p>
            </div>
            {Boolean(detail.issuedBy) &&
              <div className="p-1 col-md-3">
                <p className="mb-0">Issued Date</p>
                <p className="fw-bold">{formatDate(detail.issuedDate)}</p>
                <p className="mb-0">Issued By</p>
                <p className="fw-bold">{detail.issuedBy}</p>
              </div>}
            <div className="p-1 col-md-3">
              <p className="mb-0">Product</p>
              <p className="fw-bold"> {detail.product}</p>
              <p className="mb-0">Total Quantity</p>
              <p className="fw-bold">{displayQuantity(detail.quantity)} Gallons</p>
            </div>
            <div className="p-1 col-md-3">
              <p className="mb-0">FAN Status</p>
              <p className="fw-bold">{detail.status}</p>
            </div>
            <div className="p-1 col-md-3">
              <p className="mb-0">Vehicle Number</p>
              <p className="fw-bold">{detail.vehicleNumber}</p>
            </div>
          </div>
          <HeadingElement text="Compartment Information" />
          <CompartmentTable compartments={detail.compartments} />
          <div className="noPrint">
            <HeadingElement text="Delivery Orders" />
            <DeliveryOrderTable deliveryOrders={detail.deliveryOrders} />
            <HeadingElement text="Comments" />
            <CommentComponent id={detail.id} comments={detail.comments} handleCommentAction={handleCommentAction} />
          </div>
        </div>}
      <div className="page-action-button-group noPrint">
        <BackButton disabled={isLoading} />
        {detail?.canCancel && <ModalToggleButton modalId="#ConfirmModal" handleClick={() => setAction(Action.CANCEL)} value="Cancel FAN" icon={faXmark} color="danger" />}
        {!!detail && <ModalToggleButton modalId="#CommentModal" handleClick={() => setAction(Action.COMMENT)} value="Add Comment" icon={faComment} />}
        {detail?.isIssuable && <ModalToggleButton modalId="#IssueFanModal" handleClick={() => setAction(Action.ISSUE)} value="Issue FAN" icon={faCheck} />}
      </div>
      <ConfirmModal body={confirmBodyElement} func={handleClick} />
      <IssueFanModal body={confirmBodyElement} func={handleIssueFanClick} compartments={detail?.compartments ?? []} />
      <CommentModal action={action} id={detail?.id} comment={comment} path={path} />
    </div>
  )
}

export function NoFound() {
  return (
    <div className="card">
      <div className="card-body text-center">
        <h5 className="card-title display-6">Filling Advice Note not found.</h5>
      </div>
    </div>);
}

function PrintVersionFan({ fan }: { fan?: FillingAdviceNoteDetailDto }) {
  if (!fan) {
    return null;
  }

  return (
    <div className="noScreen">
      <div className="row">
        <div className="col-3">
          <div className="">FAN Number</div>
          <div className="fw-bold">{fan.id}</div>
        </div>
        <div className="col-3">
          <div className="">Created Date</div>
          <div className="fw-bold">{formatDate(fan.createdDate)}</div>
        </div>
        <div className="col-3">
          <div className="">Product</div>
          <div className="fw-bold">{fan.product}
          </div>
        </div>
        <div className="col-3">
          <div className="">FAN Status</div>
          <div className="fw-bold">{fan.status}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-3">
          <div className="">Card Number</div>
          <div className="fw-bold">{fan.cardNumber}</div>
        </div>
        <div className="col-3">
          <div className="">Created By</div>
          <div className="fw-bold">{fan.createdBy}</div>
        </div>
        <div className="col-3">
          <div className="">Total Quantity</div>
          <div className="fw-bold">{fan.quantity.toLocaleString()}
          </div>
        </div>
        <div className="col-3">
          <div className="">Vehicle Number</div>
          <div className="fw-bold">{fan.vehicleNumber}</div>
        </div>
      </div>
    </div>
  );
}

function PrintableHeading({ fan, user }: { fan?: FillingAdviceNoteDetailDto, user?: string }) {
  if (!fan) {
    return null;
  }
  return (
    <div className="noScreen">
      <div className="row">
        <div className="col-2">
          <div>Issued Date</div>
          <div className="fw-bold">{formatDate(fan.issuedDate)}</div>
        </div>
        <div className="col-3 text-right">
          <div>Issued By</div>
          <div className="fw-bold">{fan.issuedBy}</div>
        </div>
        <div className="col-4">
          <div>Printed Date/Time</div>
          <div className="fw-bold">{new Date().toLocaleDateString()} at {new Date().toLocaleTimeString()}</div>
        </div>
        <div className="col-3 text-right">
          <div>Printed By</div>
          <div className="fw-bold">{user}</div>
        </div>
      </div>
    </div>
  )
}



