import React from 'react';
import { faBucket, faDashboard } from '@fortawesome/free-solid-svg-icons';
import { CreateStockUpdateRequest, IImporterDetailDto, Inventory, InventoryInfo, IUserEntities } from 'appDtos';
import { useNavigate } from 'react-router-dom';
import useApi from '../../Shared/hooks/useApi';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import RequiredText from '../../Shared/components/RequiredText';
import { StockRequestInputForm } from '../deliveryOrders/TransferOrderInputForm';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import Alert, { AlertType } from '../../Shared/components/Alert';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import Loading from '../../Shared/components/Loading';
import "./updateStock.css";
import { SingleDatePickerComponent } from '../../Shared/components/DatePicker';

export const StockUpdateTypeStringToNumberMap: { [key: string]: number } = {
  'Shipment': 0,
  'Shortage Adjustment': 1,
  'Overage Adjustment': 2,
  'Overnight Differences': 3,
  'Loading Differences': 4
};
export const StockUpdateNumberToStringMap: { [key: number]: string } = {
  [StockUpdateTypeStringToNumberMap.Shipment]: 'Shipment',
  [StockUpdateTypeStringToNumberMap['Shortage Adjustment']]: 'Shortage Adjustment',
  [StockUpdateTypeStringToNumberMap['Overage Adjustment']]: 'Overage Adjustment',
  [StockUpdateTypeStringToNumberMap['Overnight Differences']]: 'Overnight Differences',
  [StockUpdateTypeStringToNumberMap['Loading Differences']]: 'Loading Differences',
};

export function CreateStockUpdate() {
  const goTo = useNavigate();
  const axios = useApi();
  const [importers, setImporters] = React.useState<IImporterDetailDto[]>([]);
  const ref = React.useRef(false);
  const [showFormError, setShowError] = React.useState(false);
  const { errorMessage, setError } = useApiHandleError();
  const path = 'stockUpdate';
  const {
    handleOnChange,
    setIsProcessing,
    isProcessing,
    formData,
  } = useFormProcessor<CreateStockUpdateRequest>({
    importerId: 0,
    createdDate: null,
    stockUpdateType: -1,
    referenceNumber: '',
    inventoryInfos: [],
  }, {
    importerId: true,
    referenceNumber: true,
    inventoryInfos: true,
    requestType: true,
  });

  React.useEffect(() => {
    if (!ref.current) {
      const fetch = async () => {
        setIsProcessing(true);
        try {
          const result = await axios.post<IUserEntities>('User/Entities', { includeImporters: true, ensureImporterAuthorization: false });
          if (result?.data.importers) {
            setImporters(result?.data.importers);
          }
        } catch (error) {
          setError(error);
        }
        setIsProcessing(false);
      }

      fetch();
    }

    return () => {
      ref.current = true;
    };
  }, [axios, importers, setError, setIsProcessing]);

  React.useEffect(() => {
    setShowError(false);
  }, [formData]);

  const isValid = !!Number(formData.importerId) &&
    formData.stockUpdateType !== -1 &&
    !!formData.createdDate &&
    formData.inventoryInfos.length > 0;

  const handleSubmit = async () => {
    if (!isValid) return setShowError(true);

    setIsProcessing(true);

    try {
      await axios.post(path, {...formData, createdDate: (formData.createdDate as Date).toISOString()});
      return goTo('/stock-request/list');
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const handleImporterSelect = (e: any) => {
    handleOnChange({ importerId: e.target.value });
  };

  const handleRequestTypeSelect = (e: any) => {
    handleOnChange({ stockUpdateType: Number(e.target.value) });
  };

  const handleBolChange = (e: any) => {
    handleOnChange({ referenceNumber: e.target.value });
  };

  const handleDateChange = (date: any) => {
    handleOnChange({ createdDate: date });
  };

  const inventories = React.useMemo(() => {
    return importers
      .find(i => i.id === Number(formData.importerId))
      ?.inventories
      .reduce((r, i) => [...r, i], [] as Inventory[]) ?? [];
  }, [formData.importerId, importers]);

  const handleSelectionChange = (info: InventoryInfo) => {
    if (formData.inventoryInfos.some(t => t.inventoryId === info.inventoryId)) {
      // Already has the transfer, so update
      if (info.quantity) {
        handleOnChange({ inventoryInfos: formData.inventoryInfos.map(t => t.inventoryId === info.inventoryId ? info : t) });
      } else {
        handleOnChange({ inventoryInfos: formData.inventoryInfos.filter(t => t.inventoryId !== info.inventoryId) });
      }
    } else {
      handleOnChange({ inventoryInfos: [...formData.inventoryInfos, info] });
    }
  };

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Stock Update Request</div>
        <div className="d-flex-jce">
          <NavigationButton link="/stock-request/list" icon={faBucket} label="Stock Update Requests" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Processing..." position="center" />}
      {showFormError && <Alert type={AlertType.DANGER} message="Please complete all required fields." />}
      {errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      {!isProcessing && <>
        <div className="col mx-1 my-2">
          <label
            htmlFor="createdDate"
            className="form-label fw-bold"
          >
            Submitted Date <RequiredText />
          </label>
          <br />
          <SingleDatePickerComponent placeholder="Select Date" date={formData.createdDate} setDate={handleDateChange} />
        </div>
        <div className="col mx-1 my-2">
          <label
            htmlFor="quantity"
            className="form-label fw-bold"
          >
            Reference Number
          </label>
          <input
            onChange={handleBolChange}
            type="text"
            value={formData.referenceNumber}
            autoComplete="off"
            name="referenceNumber"
            className="form-control"
            autoFocus
            placeholder="Enter reference number"
            id="referenceNumber"
            aria-describedby="Reference Number field"
          />
        </div>
        <div className="col my-2">
          <div className="form-label fw-bold">Update Type <RequiredText /></div>
          <select className="form-select" aria-label="Default select update type" value={formData.stockUpdateType} onChange={handleRequestTypeSelect}>
            <option value={-1}>Select Update Type</option>
            {Object.entries(StockUpdateTypeStringToNumberMap).map(([k, v]) => <option value={v} key={k}>{k}</option>)}
          </select>
        </div>
        <div className="col my-2">
          <div className="form-label fw-bold">Importer <RequiredText /></div>
          <select className="form-select" aria-label="Default select example" value={formData.importerId} onChange={handleImporterSelect}>
            <option value={0}>Select importer</option>
            {importers.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
          </select>
        </div>
        <div className="form-section-heading">Inventory Information</div>
        <div className="table-responsive">
          <table className="table table-sm table-responsive align-middle">
            <thead>
              <tr className="border-bottom">
                <th scope="col">Tank#</th>
                <th scope="col">Product</th>
                <th scope="col">Quantity Input <RequiredText /></th>
              </tr>
            </thead>
            <tbody>
              {inventories.length ? inventories.map(i =>
                <StockRequestInputForm
                  inventory={i}
                  isSelected={formData.inventoryInfos.some(s => s.inventoryId === i.inventoryId)}
                  handleSelection={handleSelectionChange}
                  key={i.inventoryId}
                  quantity={formData.inventoryInfos.find(s => s.inventoryId === i.inventoryId)?.quantity}
                />) : <tr><td colSpan={7}>Select importer to display inventories.</td></tr>}
            </tbody>
          </table>
        </div>
        <div className="page-action-button-group">
          <BackButton disabled={isProcessing}/>
          <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing || !isValid} />
        </div>
      </>}
    </div>
  );
}

