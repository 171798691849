import { IProductDto } from 'appDtos';
import React from 'react';
import { ModalContext } from '../../../Shared/contexts/ModalContext';
import useApi from '../../../Shared/hooks/useApi';
import { useLoader } from '../../../Shared/hooks/useLoader';
import { ProductDetail } from "./ProductDetail";


export function ProductList() {
    const [products, setProducts] = React.useState<IProductDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const { setModalData } = React.useContext(ModalContext);
    const { setIsLoading, renderLoader } = useLoader();

    const setProductDetail = (id: any) => {
        setModalData({ element: <ProductDetail id={id} /> });
    };

    React.useEffect(() => {
        if (!ref.current) {
            const fetchProducts = async () => {
                setIsLoading(true);
                try {
                    setIsLoading(true);
                    const result = await axios.get<IProductDto[]>(`Product`);
                    setProducts(result.data);
                } catch (error) {

                }
                setIsLoading(false);
            };
            fetchProducts();
        }
        return () => { ref.current = true; };
    }, [axios, setIsLoading]);


    return (
        <div className="results-container">
            {renderLoader()}
            {products.map(p => (
                <div className="d-flex justify-content-between align-items-center search-results-card" key={p.id}>
                    <div className="fw-bold mb-0">{p.name}</div>
                    <button onClick={() => setProductDetail(p.id)} type="button" className="btn btn-warning fw-bold btn-sm" data-bs-toggle="modal" data-bs-target="#sharedModal">Manage</button>
                </div>
            ))}
        </div>
    );
}
