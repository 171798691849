import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';

export const usePrinterRef = () => {
    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => (componentRef as any)?.current,
    });

    return { handlePrint, componentRef }
}
