import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import useFilters from '../../../Shared/hooks/useFilters';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import UserRegister from './UserRegistration';
import { UsersSearch } from './UsersSearch';

enum FilterOptions {
    SEARCH = 'Search',
    REGISTER = 'Register User',
}

export function UsersComponent() {
    const { filters, selectFilter } = useFilters(StorageKeys.AP_USERS_MENU, { [FilterOptions.SEARCH]: true, [FilterOptions.REGISTER]: false });
    return (
        <div className="p-2 card shadow-sm">
            <HeadingElement text="Users Management" />
            <ul className="nav">
                <li className="nav-item">
                    <button onClick={()=> selectFilter(FilterOptions.SEARCH)} className="nav-link btn btn-sm btn-link active" aria-current="page">{FilterOptions.SEARCH}</button>
                </li>
                <li className="nav-item">
                    <button  onClick={()=> selectFilter(FilterOptions.REGISTER)} className="nav-link btn btn-sm btn-link ">{FilterOptions.REGISTER}</button>
                </li>
            </ul>
            {filters[FilterOptions.SEARCH] && <UsersSearch />}
            {filters[FilterOptions.REGISTER] && <UserRegister />}
        </div>
    );
}
