import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import axios from "../Api/axios";
import { AppFilter } from "appDtos";

interface AppFilterContextType {
    filters: AppFilter | null
}

export const AppFilterContext = React.createContext<AppFilterContextType>({
   filters: null
});

export const AppFilterContextProvider = ({ children }: any) => {
    const [filters, setFilters] = useLocalStorage('__appFilters__', null);
    const ref = React.useRef(false);

    React.useEffect(() => {
        if (!ref.current && !filters) {
            const fetch = async () => {
                try {
                    const result = await axios.get('Setting/Filters');
                    if (result.data) {
                        setFilters(result.data);
                    }
                } catch (error) {
                    console.log(error)
                }
            };
            fetch();
        }

        return () => {
            ref.current = true
        };

    }, [filters, setFilters]);

    return (
        <AppFilterContext.Provider value={{filters }}>
            {children}
        </AppFilterContext.Provider>
    );
}
