import { faPrint, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Alert, { AlertType } from '../../Shared/components/Alert';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import { reportType } from '../../Shared/constant';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { StorageKeys } from '../../Shared/hooks/useLocalStorage';
import { useMetric } from '../../Shared/hooks/useMetric';
import { usePrinterRef } from '../../Shared/hooks/usePrinterRef';
import { PrintingVersion } from './components/PrintingVersion';
import Toggle from './components/Toggle';
import { useReportExporter } from './components/useReportExporter';

interface Report {
    importer: string;
    balanceByProduct: BalanceByProduct
}
interface BalanceByProduct {
    pms: number;
    ago: number;
    jet: number;
}

export const ImporterStockBalance = () => {
    const API = useApi();
    const { toMetricTon, toggleUnit, isMetric, toMetricTonString } = useMetric('__impStoBal');
    const { errorMessage, setError } = useApiHandleError();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { componentRef, handlePrint } = usePrinterRef();
    const { tableRef, Button } = useReportExporter({ sheet: reportType.StockBalanceSummary });
    const [reports, setReports] = useState<Report[]>(() => {
        const report = localStorage.getItem(StorageKeys.STOCK_BALANCE_BY_PRODUCT);
        if (report) {
            return JSON.parse(report);
        }
        return [];
    });

    useEffect(() => {
        (async () => {
            if (!reports.length || refresh) {
                setLoading(true);
                errorMessage && setError('');
                try {
                    const res = await API.get('/Report/ImporterStockBalanceByProduct');
                    localStorage.setItem(StorageKeys.STOCK_BALANCE_BY_PRODUCT, JSON.stringify(res.data));
                    setReports(res.data);
                } catch (error) {
                    setError(error)
                }
                setRefresh(false);
                setLoading(false);
            }
        })()
    }, [reports.length, refresh, API, setError, errorMessage])

    const total = reports.reduce((r, e) => {
        r.ago += toMetricTon(e.balanceByProduct.ago);
        r.pms += toMetricTon(e.balanceByProduct.pms);
        r.jet += toMetricTon(e.balanceByProduct.jet);
        return r;
    }, {
        ago: 0,
        pms: 0,
        jet: 0,
    });

    const renderComponent = () => {
        return (<div className="border p-0 table-responsive">
            <table className="table table-sm m-0" ref={tableRef}>
                <thead className="table-secondary">
                    <tr>
                        <th className="fixed-width">#</th>
                        <th scope="col" className="w-50">Importer</th>
                        <th scope="col">AGO</th>
                        <th scope="col">PMS</th>
                        <th scope="col">JET</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map(({ importer, balanceByProduct: { ago, pms, jet } }, i) => <tr key={i}>
                        <td className="fixed-width">{i + 1}.</td>
                        <td className="w-50">{importer}</td>
                        <td>{toMetricTonString(ago)}</td>
                        <td>{toMetricTonString(pms)}</td>
                        <td>{toMetricTonString(jet)}</td>
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <td className=""></td>
                        <td className="fw-bold">Total</td>
                        <td className="fw-bold">{total.ago.toLocaleString()}</td>
                        <td className="fw-bold">{total.pms.toLocaleString()}</td>
                        <td className="fw-bold">{total.jet.toLocaleString()}</td>
                    </tr>
                </tfoot>
            </table>
        </div>);
    }

    return (
        <>
            <div className="d-flex-jcb p-2 border mt-3 mb-3 noPrint">
                <Toggle checked={isMetric} label="Metric tonnes" handlier={toggleUnit} />
                <div className="d-flex-jcb">
                    <button
                        onClick={handlePrint}
                        className="btn btn-outline-dark fw-bold m-1 btn-sm btn-sm">
                        <Icon icon={faPrint} color="dark" /> Print
                    </button>
                    {Button}
                    <button
                        onClick={() => setRefresh(true)}
                        className="btn btn-dark fw-bold m-1 btn-sm btn-sm">
                        <Icon icon={faRefresh} color="white" /> Refresh
                    </button>
                </div>
            </div>
            {Boolean(errorMessage) && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {loading ? <Loading /> : renderComponent()}
            <PrintingVersion
                headerProps={[`${reportType.StockBalanceSummary} Report`]}
                element={renderComponent()} ref={componentRef}
                shouldRender={Boolean(reports.length)}
            />
        </>
    )
}


