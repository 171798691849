import { faCircleCheck, faEdit, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserEmailConfirmationResponse as UserAccountActivationResponse } from 'appDtos';
import React, { useContext } from 'react'
import Alert, { AlertType } from '../../../Shared/components/Alert';
import Icon from '../../../Shared/components/Icon';
import Loading from '../../../Shared/components/Loading';
import { Mode } from '../../../Shared/constant';
import { UserDetailContext } from '../../../Shared/contexts/UserDetailProvider';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import { formatDate } from '../../../Shared/utils/date';
import { UserAuthorizationSection } from './UserAuthorizationSection';
import UpdateUser from './UserEdit';

export interface UserDetailProps {
  id?: string;
}

export default function UserDetail({ id }: UserDetailProps) {
  const [userAuth] = React.useState({ userId: id, roles: [], importers: [], distributors: [] });
  const { userDetail, triggerRefresh, errorMessage : error } = useContext(UserDetailContext);
  const [mode, setMode] = React.useState(Mode.DETAIL);
  const axios = useApi();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [sentConfirmation, setSentConfirmation] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();

  const updateMode = (mode: Mode) => {
    triggerRefresh();
    setMode(mode);
  }

  const handleSubmit = async () => {
    setIsProcessing(true);
    setSentConfirmation('');
    setError('');

    try {
      const response = await axios.post<UserAccountActivationResponse>('Account/Activation', { userId: userDetail?.id });
      if (response?.data?.message) {
        setSentConfirmation(response?.data?.message);
      }
    } catch (error: any) {
      setError(error)
    }
    setIsProcessing(false);
  }

  let sendAccountActivationButtonText = userDetail?.emailConfirmed ? 'Resend account activation email' : 'Send account activation email';

  if (sentConfirmation) {
    sendAccountActivationButtonText = 'Email Sent!';
  }

  return (
    <>
      {userDetail ?
        <>
          {mode === Mode.DETAIL ? <>
            {(!!errorMessage || !!error) && <Alert type={AlertType.DANGER} message={errorMessage || error} />}
            {!!sentConfirmation && <Alert type={AlertType.SUCCESS} message={sentConfirmation} />}
            <h5 className="card-title border p-2 theme-dark-color text-light mx-0">User Detail</h5>
            <div className="px-3">
              <p className="">Name: {userDetail.firstName} {userDetail.middleInitial} {userDetail.lastName}</p>
              <p className="">Email: {userDetail.email}</p>
              <p className="">Phone: {userDetail.phone}</p>
            </div>
            <h5 className="card-title mt-4 border p-2 theme-dark-color text-light mx-0">Account Information</h5>
            <div className="px-3">
              <p className="">Status: {userDetail.isActive ? 'Active' : 'Inactive'}</p>
              <p className="">Created Date: {formatDate(userDetail.createdDate)}</p>
              <p className="">Created By: {userDetail.createdBy}</p>
              {!!userDetail.lastUpdatedDate && <>
                <p className="">Last Updated Date: {formatDate(userDetail.lastUpdatedDate)}</p>
                <p className="">Last Updated By: {userDetail.lastUpdatedBy}</p>
              </>}
              <p className="">Has Confirmed Email: {userDetail.emailConfirmed ? 'Yes' : 'No'}</p>
              <button disabled={isProcessing} onClick={handleSubmit} className="btn btn-sm btn-warning  m-1 fw-bold">{sentConfirmation ? <Icon icon={faCircleCheck} color="green" /> : <Icon icon={faPaperPlane} color="black" />} {isProcessing ? "Sending email..." : sendAccountActivationButtonText}</button>
            </div>
            <UserAuthorizationSection user={userDetail} userAuth={userAuth} />
            <button onClick={() => setMode(Mode.EDIT)} className="btn btn-warning fw-bold mt-4"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faEdit} /></span> Edit User</button>
          </> : <UpdateUser user={userDetail} setMode={updateMode} />
          }
        </> :
        <Loading loadingText="Loading user information" position="center" />
      }
    </>
  );
}
