import React from 'react'

export interface ControlCheckboxFieldProps {
    onChange: (e: any) => void;
    label: string;
    checked: boolean;
    id: string;
    name: string;
}

const ControlCheckboxField = (props: ControlCheckboxFieldProps) => {
    return (
        <div className="form-check">
            <input onChange={() => props.onChange({ [props.name]: !props.checked })} className="form-check-input" type="checkbox" id={props.id} checked={props.checked} />
            <label className="form-check-label" htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    )
}

export default ControlCheckboxField
