import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DateRange {
  from: Date | null;
  to: Date | null;
}

interface DatePickerProps {
  dateRange: DateRange;
  setDate: (dateRange: DateRange) => void;
}

export function DatePickerComponent({ dateRange, setDate }: DatePickerProps) {
  return (
    <div>
      <div>From</div>
      <DatePicker
        isClearable={Boolean(dateRange.from)}
        className="w-100 mb-2"
        selected={dateRange?.from}
        onChange={(date) => setDate({ ...dateRange, from: date })}
        selectsStart
        startDate={dateRange?.from}
        maxDate={dateRange?.to ?? new Date()}
        placeholderText="Select start date." />
      <div>To</div>
      <DatePicker
        isClearable={Boolean(dateRange.to)}
        className="w-100 mb-2"
        selected={dateRange?.to}
        onChange={(date) => setDate({ ...dateRange, to: date })}
        selectsEnd
        startDate={dateRange?.from}
        endDate={dateRange?.to}
        minDate={dateRange?.from}
        maxDate={new Date()}
        placeholderText="Select end date." />
    </div>
  );
}
