import { IImporterDetailDto, ImporterInventory, IStorageTankDto, IUpdateImporterRequest } from 'appDtos';
import React from 'react';
import ControlCheckboxField from '../../../Shared/components/ControlCheckboxField';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { Mode } from '../../../Shared/constant';
import { SubmitButton } from '../../../Shared/components/NavigationButton';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import HeadingElement from '../../../Shared/components/HeadingElement';

interface TankOption extends IStorageTankDto {
    isSelected: boolean;
}

export function EditImporter(props: { importer: IImporterDetailDto, setMode: (mode: Mode) => void }) {
    const { id,
        isActive,
        address,
        name,
        primaryEmail,
        primaryPhone,
        secondaryEmail,
        secondaryPhone,
        taxIdentificationNumber,
        contactPerson,
        inventories
    } = props.importer;
    const axios = useApi();
    const { setError, errorMessage } = useApiHandleError();
    const [storageTankOptions, setStorageTanks] = React.useState<TankOption[]>([]);
    const ref = React.useRef(false);
    const {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        formData,
        isProcessing,
        hasError
    } = useFormProcessor<IUpdateImporterRequest>(
        {
            id,
            isActive,
            address,
            name,
            primaryEmail,
            primaryPhone,
            secondaryEmail,
            secondaryPhone,
            taxIdentificationNumber,
            contactPerson,
            inventories: []
        });

    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                const result = await axios.get<IStorageTankDto[]>('StorageTank');
                if (result?.data) {
                    const updateResults = result?.data.map(r => ({ ...r, isSelected: inventories.some(i => i.storageTank.id === r.id) }));
                    setStorageTanks(updateResults);
                }
            }

            fetch();
        }

        return () => {
            ref.current = true;
        };
    }, [axios, storageTankOptions, inventories]);


    React.useEffect(() => {
        setError(undefined);
    }, [formData, setError]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            const importerInventories: ImporterInventory[] = storageTankOptions
                // .filter(o => o.isSelected)
                .map(o => ({ storageTankId: o.id, isSelected: o.isSelected }));
            await axios.put('Importer', { ...formData, inventories: importerInventories });
            props.setMode(Mode.DETAIL);
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    const handleStorageTankChange = (inventory: TankOption) => {
        const updatedList = storageTankOptions.map(t => t.id === inventory.id ? ({ ...t, isSelected: !t.isSelected }) : t);
        setStorageTanks(updatedList);
    }

    return (
        <div className="">
            <HeadingElement text="Edit Importer" />
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            <form className="p-3">
            <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Importer Name"
                    placeholder="Enter name."
                    id="name"
                    value={formData.name}
                    name="name"
                    focusOnMount={true}
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Tax Identification Number"
                    placeholder="Enter Tax Identification Number"
                    id="primaryEmail"
                    value={formData.taxIdentificationNumber}
                    name="taxIdentificationNumber"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Full Address"
                    placeholder="Enter full address."
                    id="address"
                    value={formData.address}
                    name="address"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Primary Phone Number"
                    placeholder="Enter primary phone number"
                    id="primaryPhone"
                    value={formData.primaryPhone}
                    name="primaryPhone"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Secondary Phone Number"
                    placeholder="Enter secondary phone number."
                    id="primaryPhone"
                    value={formData.secondaryPhone}
                    name="secondaryPhone"
                    type="text"
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Primary Email"
                    placeholder="Enter primary email"
                    id="primaryEmail"
                    value={formData.primaryEmail?.trim()}
                    name="primaryEmail"
                    type="text"
                    required={true}
                    validationFunction={isValidEmail}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Secondary Email"
                    placeholder="Enter secondary email."
                    id="secondaryEmail"
                    value={formData.secondaryEmail?.trim()}
                    name="secondaryEmail"
                    type="text"
                    validationFunction={(v: any) => v ? isValidEmail(v) : true}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    onFieldError={handleFieldError}
                    label="Contact Person"
                    placeholder="Enter contact person."
                    id="contactPerson"
                    value={formData.contactPerson}
                    name="contactPerson"
                    type="text"
                />
                <ControlCheckboxField
                    onChange={handleOnChange}
                    label="Is Active"
                    id="isActive"
                    checked={formData.isActive}
                    name="isActive"
                />
                <div className="fw-bold">Select importer's Tanks from below.</div>
                <div className="list-group mb-3">
                    {storageTankOptions?.map(tank => (
                        <label className="list-group-item mr-2" key={tank.id}>
                            <input onChange={() => handleStorageTankChange(tank)} className="form-check-input me-1 mr-3" type="checkbox" checked={tank.isSelected} value="" />
                            StorageTank #{tank.number} - {tank.productName}
                        </label>
                    ))}
                </div>
                <div className="page-action-button-group">
                    <button onClick={() => props.setMode(Mode.DETAIL)} className="btn btn-secondary fw-bold m-1">Cancel</button>
                    <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing || hasError} />
                </div>
            </form>
        </div>
    );
}
