import React from 'react';
import useApi from '../../../Shared/hooks/useApi';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import { CachedSearchResult } from '../Portal';
import SearchComponent from '../../../Shared/components/SearchComponent';
import { UserResultCard } from "./UserResultCard";
import { ISearchUsersResponse } from 'appDtos';
import { useLoader } from '../../../Shared/hooks/useLoader';

export const UsersSearch = () => {
    const {isLoading, setIsLoading, renderLoader} = useLoader();
    const [searchTerm, setSearchTerm] = React.useState('');
    const API = useApi();

    const [searchResults, setSearchResults] = React.useState<CachedSearchResult>(() => {
        const value = localStorage.getItem(StorageKeys.ADMIN_STORED_USERS);
        if (value) {
            return { data: JSON.parse(value), fromCache: true };
        }
        return {};
    });

    const [noResult, setNoResult] = React.useState(false);
    const [showError, setshowError] = React.useState(false);

    const reset = () => {
        localStorage.removeItem(StorageKeys.ADMIN_STORED_USERS);
        setSearchResults({});
        setshowError(true);
    };

    const handleOnChange = async (e: any) => {
        setshowError(false);
        const value = e.target.value;
        if (value) {
            setSearchTerm(value);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        const validSearchTerm = searchTerm.trim();
        if (!validSearchTerm) {
            reset();
        } else {
            setshowError(false);
            try {
                const result = await API.get<ISearchUsersResponse>(`User/Search/${validSearchTerm}`);
                if (result?.data?.users?.length) {
                    localStorage.setItem(StorageKeys.ADMIN_STORED_USERS, JSON.stringify(result?.data?.users));
                    setNoResult(false);
                    setSearchResults({ data: result.data.users, fromCache: false });
                } else {
                    localStorage.removeItem(StorageKeys.ADMIN_STORED_USERS);
                    setSearchResults({});
                    setNoResult(true);
                }
            } catch (error) {
                localStorage.removeItem(StorageKeys.ADMIN_STORED_USERS);
                setSearchResults({});
                setNoResult(true);
            }
        }

        setIsLoading(false);
    };

    return (
        <div>
            <SearchComponent handleOnChange={handleOnChange} handleSubmit={handleSubmit} placeholder="Search by first name, last name or email address." isLoading={isLoading} />
            <div className="col-12">
                {searchResults?.fromCache && <div>Recent search results.</div>}
                <div className="results-container">
                    {(noResult && !showError) ? <div>No result found.</div> : searchResults?.data?.length ? searchResults.data.map(user => <UserResultCard user={user} key={user.id} />) : null}
                    {showError && <div className="text-danger">Search term is required to seach users.</div>}
                    {renderLoader()}
                </div>
            </div>
        </div>
    );
};
