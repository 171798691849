import { ICreateImporterRequest, ImporterInventory, IStorageTankDto } from 'appDtos';
import React, { useState } from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

interface TankOption extends IStorageTankDto {
    isSelected: boolean;
}

export function RegisterImporter() {
    const axios = useApi();
    const [success, setSuccess] = useState(false);
    const { setError, errorMessage } = useApiHandleError();
    const [storageTankOptions, setStorageTanks] = React.useState<TankOption[]>([]);
    const ref = React.useRef(false);
    const {
        handleOnChange,
        handleFieldError,
        setIsProcessing,
        formData,
        isProcessing,
        hasError,
        reset
    } = useFormProcessor<ICreateImporterRequest>({} as ICreateImporterRequest,);

    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                const result = await axios.get<IStorageTankDto[]>('StorageTank');
                if (result?.data) {
                    setStorageTanks(result?.data.map(p => ({ ...p, isSelected: false })));
                }
            }

            fetch();
        }

        return () => {
            ref.current = true;
        };
    }, [axios, storageTankOptions]);


    React.useEffect(() => {
        setError(undefined);
    }, [formData, setError]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            const inventories: ImporterInventory[] = storageTankOptions
                .filter(o => o.isSelected)
                .map(o => ({ storageTankId: o.id, isSelected: o.isSelected }));

            await axios.post('Importer', { ...formData, inventories });

            setSuccess(true);
            reset();
            setStorageTanks(storageTankOptions.map(p => ({ ...p, isSelected: false })));
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    const handleStorageTankChange = (inventory: TankOption) => {
        const updatedList = storageTankOptions.map(t => t.id === inventory.id ? ({ ...t, isSelected: !t.isSelected }) : t);
        setStorageTanks(updatedList);
    }

    return (
        <div className="">
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {success && <Alert type={AlertType.SUCCESS} message="Importer successfully added." />}
            <form className="p-3">
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Importer Name"
                    placeholder="Enter name."
                    id="name"
                    value={formData.name}
                    name="name"
                    focusOnMount={true}
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Tax Identification Number"
                    placeholder="Enter Tax Identification Number"
                    id="primaryEmail"
                    value={formData.taxIdentificationNumber}
                    name="taxIdentificationNumber"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Full Address"
                    placeholder="Enter full address."
                    id="address"
                    value={formData.address}
                    name="address"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Primary Phone Number"
                    placeholder="Enter primary phone number"
                    id="primaryPhone"
                    value={formData.primaryPhone}
                    name="primaryPhone"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Secondary Phone Number"
                    placeholder="Enter secondary phone number."
                    id="primaryPhone"
                    value={formData.secondaryPhone}
                    name="secondaryPhone"
                    type="text"
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Primary Email"
                    placeholder="Enter primary email"
                    id="primaryEmail"
                    value={formData.primaryEmail?.trim()}
                    name="primaryEmail"
                    type="text"
                    required={true}
                    validationFunction={isValidEmail}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Secondary Email"
                    placeholder="Enter secondary email."
                    id="secondaryEmail"
                    value={formData.secondaryEmail?.trim()}
                    name="secondaryEmail"
                    type="text"
                    validationFunction={(v: any) => v ? isValidEmail(v) : true}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Contact Person"
                    placeholder="Enter contact person."
                    id="contactPerson"
                    value={formData.contactPerson}
                    name="contactPerson"
                    type="text"
                />
                <div className="fw-bold">Select importer's tanks from below.</div>
                <div className="list-group mb-3">
                    {storageTankOptions?.map(tank => (
                        <label className="list-group-item mr-2" key={tank.id}>
                            <input onChange={() => handleStorageTankChange(tank)} className="form-check-input me-1 mr-3" type="checkbox" checked={tank.isSelected} value="" />
                            Tank #{tank.number} - {tank.productName}
                        </label>
                    ))}
                </div>
                <button onClick={handleSubmit} type="button" disabled={isProcessing || hasError} className="btn btn-warning fw-bold">Submit</button>
            </form>
        </div>
    );
}
