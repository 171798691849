import useLocalStorage from './useLocalStorage';

export default function useFilters(storageKey: string, defaultOptions: { [key: string]: boolean }) {
    const [filters, setFilters] = useLocalStorage<{ [key: string]: boolean }>(storageKey, defaultOptions);

    const selectFilter = (key: string) => {
        const resetFilters: { [key: string]: boolean } = {};

        for (const key in filters) {
            resetFilters[key] = false;
        }

        setFilters({ ...resetFilters, [key]: true });
    }

    return { filters, selectFilter };
}
