import React from 'react';
import { Status } from 'appDtos';
import './orderTracking.css'
import Icon from '../../Shared/components/Icon';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export const COMPLETED_STAGE = 40;

export function LoadingRequestProgress({ status: { progress } }: { status: Status }) {

  return (
    <div className="order-progress">
      <ApprovalStage progress={progress} stage={0} text="Operations" />
      <ApprovalStage progress={progress} stage={10} text="Finance" />
      <ApprovalStage progress={progress} stage={20} text="Marketing" />
      <ApprovalStage progress={progress} stage={30} text="Control Room" />
      <ApprovalStage progress={progress} stage={40} text="Delivered" />
    </div>
  );
}

function ApprovalStage({ progress, stage, text }: { progress: number, stage: number, text: string }) {
  let className = "step";

  const isComplete = progress > stage || progress === COMPLETED_STAGE;
  
if (progress === stage && !isComplete) {
    className = "current-step step";
  }

  return (
    <div className={className}>{isComplete && <Icon icon={faCircleCheck} color="green" />} {text}</div>
  );
}
