import React from 'react'
import qs from 'qs';
import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import Pagination from '../../Shared/components/Pagination';
import useApi from '../../Shared/hooks/useApi';
import useAppFilters from '../../Shared/hooks/useAppFilters';
import useAuth from '../../Shared/hooks/useAuth';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faChevronDown, faChevronUp, faDashboard, faFolderPlus, faGasPump, faMagnifyingGlass, faShip, faX, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterKeyValue, FilterRequest, IStockTransferDto, PaginatedStockTransferResponse } from 'appDtos';
import { Link } from 'react-router-dom';
import { NavigationButton } from '../../Shared/components/NavigationButton';
import { NoResultsFound } from '../../Shared/components/NoResultsFound';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { formatDate } from '../../Shared/utils/date';
import { scrollToTop } from '../../Shared/utils/windowUtil';
import "../orders/OrderList";
import { DatePickerComponent, DateRange } from '../../Shared/components/DatePickerComponent';
import { format, parseISO } from 'date-fns';

export function StockTransferList() {
  const axios = useApi();
  const [results, setStockTransfers] = React.useState<IStockTransferDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, pages: 0 });
  const { errorMessage, setError } = useApiHandleError();
  const { filters } = useAppFilters();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const [stockTransferFilterRequest, setStockTransferFilterRequest] = React.useState<FilterRequest>(() => {
    if (window?.location?.hash) {
      const values = qs.parse(window.location.hash.replace('#/?', ''))
      const stringValues = ['referenceNumber', 'createdFrom', 'createdTo']
      const data = Object.entries(values).reduce((r, [k, v]) => ({ ...r, [k]: stringValues.includes(k) ? v : Number(v) }), {});
      return data;
    }
    return {};
  });
  const [dateRange, setDateRange] = React.useState<{ from: Date | null, to: Date | null }>(() => {
    return {
      from: stockTransferFilterRequest.createdFrom ? parseISO(stockTransferFilterRequest.createdFrom as string) : null,
      to: stockTransferFilterRequest.createdTo ? parseISO(stockTransferFilterRequest.createdTo as string) : null
    };
  });
  const handleDateChange = (range: DateRange) => {
    const update = { ...stockTransferFilterRequest };
    const { from, to } = range;
    from ? update.createdFrom = format(from, 'yyyy-MM-dd') : delete update.createdFrom;
    to ? update.createdTo = format(to, 'yyyy-MM-dd') : delete update.createdTo;
    setDateRange({ ...dateRange, ...range });
    setStockTransferFilterRequest(update);
  }
  const [search, setSearch] = React.useState(stockTransferFilterRequest.refencenceNumber ?? '');

  const handlePaginate = (pageNumber: number) => setStockTransferFilterRequest({ ...stockTransferFilterRequest, pageNumber });

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const result = await axios.post<PaginatedStockTransferResponse>('StockTransfer/Search', stockTransferFilterRequest);
        const { results, pages, currentPage } = result.data;
        setStockTransfers(results);
        setPageInfo({ pages, currentPage });
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetch();
  }, [axios, setError, stockTransferFilterRequest]);

  const handleFilterChange = (filter: { [key: string]: number | string }) => {
    const [[key, value]] = Object.entries(filter);

    let update = { ...stockTransferFilterRequest };

    if (stockTransferFilterRequest[key] === value) {
      delete update[key];
      setStockTransferFilterRequest(update);
    } else {
      update = { ...update, ...filter }
      setStockTransferFilterRequest(update)
    }
  }

  React.useEffect(() => {
    const url = Object.entries(stockTransferFilterRequest).reduce((r, [k, v]) => r ? `${r}&${k}=${v}` : `/stock-transfer/list/#/?${k}=${v}`, '');
    scrollToTop();
    if (url) {
      return window.history.replaceState(null, "LPRC", url);
    }
    return window.history.replaceState(null, "LPRC", "/stock-transfer/list");
  }, [stockTransferFilterRequest])

  const hasMultipleFilters = React.useMemo(() => Object.values(stockTransferFilterRequest).length > 1, [stockTransferFilterRequest]);
  const handleClearAll = () => {
    setStockTransferFilterRequest({});
    setSearch('');
    setStockTransfers([]);
    setDateRange({ from: null, to: null });
  }

  const hasSearchTerm = !!search;
  const handleSearch = () => setStockTransferFilterRequest({
    ...stockTransferFilterRequest,
    referenceNumber: search
  })
  const handleSearchClear = () => {
    delete stockTransferFilterRequest.referenceNumber;
    setSearch('');
    setStockTransferFilterRequest({ ...stockTransferFilterRequest });
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Stock Transfer Requests</div>
        <div className="d-flex-jce">
          {isInRole(rCodes?.R100) && hasPermission(pCodes?.P100) && <NavigationButton link="/submit-stock-transfer" icon={faFolderPlus} label="Submit Stock Transfer" />}
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      <div className="d-flex-jcs">
        <div className="filters">
          <div className="input-group mb-2">
            <input onChange={(e: any) => setSearch(e.target.value)} value={search} type="text" className="form-control" placeholder="Enter reference number" aria-label="Search input" aria-describedby="search" />
            {hasSearchTerm && <button onClick={handleSearchClear} type="button" className="input-group-text fw-bold" id="search"><Icon icon={faX} /></button>}
            <button disabled={!hasSearchTerm} onClick={handleSearch} type="button" className="input-group-text fw-bold bg-warning" id="search"><Icon icon={faMagnifyingGlass} /></button>
          </div>
          <div className="filter-section-title">
            <h5 className="m-0">Filter</h5>
            {hasMultipleFilters && <button aria-label="filter-collaspe-button" type="button" onClick={handleClearAll} className="btn btn-sm btn-link p-0 m-0 text-dark fw-bold">Clear All Filters</button>}
          </div>
          <div className="mb-1 shadow">
            <label htmlFor="date-range" className="form-label fw-bold border p-2 w-100 border-warning"><Icon icon={faCalendar} /> Created Date</label>
            <DatePickerComponent setDate={handleDateChange} dateRange={dateRange} />
          </div>
          {Boolean(filters) ? <div>
            <FilterSet icon={faDashboard} filterKey="stage" title="Stages" keyValues={filters?.stockTransferFilter?.stages ?? []} value={stockTransferFilterRequest.stage} onChange={handleFilterChange} />
            <FilterSet icon={faGasPump} filterKey="productId" title="Products" keyValues={filters?.entityFilters?.products ?? []} value={stockTransferFilterRequest.productId} onChange={handleFilterChange} />
            <FilterSet icon={faShip} filterKey="importerId" title="Importers" keyValues={filters?.entityFilters?.importers ?? []} value={stockTransferFilterRequest.importerId} onChange={handleFilterChange} />
          </div> : <Loading />}
        </div>
        <div id="resultList" className={isLoading ? "results-list pt-5" : "results-list"}>
          {isLoading && <Loading position="center" />}
          {!isLoading && results.length > 0 && results.map(p => (
            <div className="card p-4 mb-2 shadow-lg border-warning" key={p.id}>
              <div className="d-flex-jcb border-bottom mb-1">
                <div className="col">
                  <div className="mb-0">Transaction #</div>
                  <Link to={`/stock-transfer/detail/${p.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5">{p.id}</Link>
                </div>
                <div className="col">
                  <div className="mb-0">Reference Number</div>
                  <div className="h6 mb-0">{p.referenceNumber}</div>
                </div>
                <div className="col">
                  <div className="mb-0">Submitted Date</div>
                  <div className="h6 mb-0">{formatDate(p.submittedDate)}</div>
                </div>
                <div className="col">
                  <div className="mb-0">Status</div>
                  <div className="h6 mb-0">{p.status}</div>
                </div>
              </div>
              <div className="d-flex-jce mb-0">
                <div className="col">
                  <div className="mb-0 h6">Origin</div>
                  <div className="mb-0 text-truncate">{p.fromImporter}</div>
                  <div className="mb-0 text-truncate">{p.fromInventory}</div>
                </div>
                <div className="col">
                  <div className="mb-0 h6">Destination</div>
                  <div className="mb-0 text-truncate">{p.toImporter}</div>
                  <div className="mb-0 text-truncate">{p.toInventory}</div>
                </div>
              </div>
            </div>
          ))}
          {(!isLoading && results.length === 0 && !errorMessage) && <NoResultsFound message="No results were found." />}
          {errorMessage && <NoResultsFound message={errorMessage} />}
          {!isLoading && <div className="d-flex justify-content-start mt-4">
            {results.length > 0 && <button onClick={() => scrollToTop()} className="btn btn-warning btn-sm fw-bold" disabled={results.length < 10}><span><FontAwesomeIcon size='sm' icon={faArrowAltCircleUp} /></span> Go to top</button>}
            <Pagination {...pageInfo} paginate={handlePaginate} />
          </div>}
        </div>
      </div>
    </div>
  );
}

interface FilterSetProps {
  icon: IconDefinition;
  title: string;
  keyValues: FilterKeyValue[];
  filterKey: string;
  value?: number | string;
  onChange: (value: any) => void
}

export function FilterSet(props: FilterSetProps) {
  const [collaspe, setCollaspe] = React.useState(false);

  return (
    <div className="mb-1 shadow">
      <div className="filter-set-header border border-warning">
        <div className="h6 mb-0"><Icon icon={props.icon} /> {props.title}</div>
        <button aria-label="filter-collaspe-button" type="button" onClick={() => setCollaspe(!collaspe)} className="btn btn-link p-0 m-0"><Icon color="black" icon={collaspe ? faChevronUp : faChevronDown} /></button>
      </div>
      <div className={collaspe ? "d-none" : "filter-set"}>
        <ul className="list-group">
          {props.keyValues.map((s: any) => (
            <li key={s.key} title={s.value} className="list-group-item text-truncate filter-set-item">
              <input
                onChange={() => props.onChange({ [props.filterKey]: s.key })}
                className="form-check-input me-1"
                type="checkbox"
                value={s.key}
                aria-label={s.value}
                checked={props.value === s.key}
              />
              {s.value}
            </li>))
          }
        </ul>
      </div>
    </div>
  );
}

export function Field({ title, value, col }: { title: string, value: string | undefined, col?: string }) {
  return (
    <div className={col ? col : "col-md-3"}>
      <div className="mb-0 field-heading">{title}</div>
      <div className="h6 mb-0">{value}</div>
    </div>
  );
}
