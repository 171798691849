import { IUserDto } from 'appDtos';
import React from 'react';
import { ModalContext } from '../../../Shared/contexts/ModalContext';
import { UserDetailProvider } from '../../../Shared/contexts/UserDetailProvider';
import UserDetail from './UserDetail';

export const UserResultCard = (props: { user: IUserDto; }) => {
    const { setModalData } = React.useContext(ModalContext);

    const getUserDetail = () => {
        setModalData({
            element: (
            <UserDetailProvider userId={props.user.id}>
                <UserDetail id={props.user.id} />
            </UserDetailProvider>)
        });
    }

    return (
        <div className="d-flex justify-content-between align-items-center search-results-card">
            <div>
                <h6 className="card-title">{`${props.user.firstName} ${props.user.lastName}`}</h6>
                <p className="card-subtitle">{props.user.email}</p>
            </div>
            <div>
                <button onClick={getUserDetail} type="button" className="btn btn-warning fw-bold btn-sm" data-bs-toggle="modal" data-bs-target="#sharedModal">Manage</button>
            </div>
        </div>
    );
};
