import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';
import { ILoadingRequestDto, InventoryForDeliveryOrderDto } from 'appDtos';
import axios from '../Api/axios';
import { useLoader } from '../hooks/useLoader';
import { useApiHandleError } from '../hooks/useApiHandleError';
import Alert, { AlertType } from './Alert';

interface Props {
  transferOrder?: ILoadingRequestDto;
  deliveryOrderId: number;
  path: string;
}

export function InventoryUpdateModal({ transferOrder, deliveryOrderId, path }: Props) {
  const [inventories, setInventories] = React.useState<InventoryForDeliveryOrderDto[]>([]);
  const [inventoryId, setSelectedInventoryId] = React.useState(0);
  const { isLoading, renderLoader, setIsLoading } = useLoader();
  const { errorMessage, setError } = useApiHandleError();
  const [successMessage, setSuccessMessage] = React.useState('');

  React.useEffect(() => {
    const fetch = async () => {
      try {
        if (transferOrder) {
          setIsLoading(true);
          const result = await axios.get<InventoryForDeliveryOrderDto[]>(`${path}/${transferOrder?.id ?? 0}/Inventories`);
          if (result?.data?.length) {
            setInventories(result?.data);
          }
        }
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    }
    fetch();
  }, [setError, setIsLoading, transferOrder, path])

  const handleInventoryChange = (e: any) => setSelectedInventoryId(Number(e.target.value));

  const handleSubmit = async () => {
    try {
      if (inventoryId) {
        setIsLoading(true);
        const result = await axios.post(`${path}/InventoryUpdate`, { transferOrderId: transferOrder?.id, deliveryOrderId, inventoryId });
        if (result?.data) {
          setSuccessMessage(result?.data);
        }
      }
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  }

  const handleClose = () => {
    if (successMessage) {
      return window.location.reload();
    }
    setSelectedInventoryId(0);
    setError(undefined);
  };

  const renderInventories = () => {
    if (successMessage) {
      return null;
    }
    return (<select className="form-select" aria-label="Default select update type" onChange={handleInventoryChange}>
      <option value={0}>Select storage tank</option>
      {inventories.map((v, i) => <option selected={transferOrder?.inventoryId === v.id} value={v.id} key={i}>{v.storageTank}</option>)}
    </select>)
  }
  
  return (
    <div className="modal fade" data-bs-backdrop="static" id="InventoryUpdateModal" tabIndex={-2} aria-labelledby="InventoryUpdateModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="InventoryUpdateModal">Add Storage Tank</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {!!successMessage && <Alert type={AlertType.SUCCESS} message={successMessage} />}
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {isLoading ? renderLoader() : renderInventories()}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-secondary fw-bold" disabled={isLoading} data-bs-dismiss="modal">Close</button>
            {!successMessage && <button type="button" onClick={handleSubmit} disabled={isLoading || !!errorMessage || !inventoryId || transferOrder?.inventoryId === inventoryId} className="btn btn-warning fw-bold">
              {isLoading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...</> :
                <><span><Icon icon={faCheck} /></span> Submit</>}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}
