import { faDashboard, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProductDetailDto, IProductDto } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Shared/components/Loading';
import useApi from '../../Shared/hooks/useApi';
import { Field } from '../Administration/Distributors/DistributorDetail';
import { getInitialState } from './ImportersEntity';

export function ProductsEntity() {
    const goTo = useNavigate()
    const [products, setproducts] = React.useState<IProductDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const [showDetail, setShowDetail] = React.useState(false);
    const [product, setproduct] = React.useState<IProductDetailDto>();
    const [selectedId, setSelectedId] = React.useState<number | undefined>(getInitialState);
    const [isLoadingproduct, setIsLoadingproduct] = React.useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = React.useState(false);

    React.useEffect(() => {
        if (!ref.current) {
            const fetchproducts = async () => {
                setIsLoadingDetail(true);
                const result = await axios.get<IProductDto[]>(`product`);
                setproducts(result.data);
                setIsLoadingDetail(false);
            };
            fetchproducts();
        }
        return () => { ref.current = true; };
    }, [axios]);


    React.useEffect(() => {
        if (selectedId) {
            const fetchDetail = async () => {
                setIsLoadingproduct(true);
                setShowDetail(true);
                const response = await axios.get<IProductDetailDto>(`product/${selectedId}`);
                setproduct(response.data);
                window.history.replaceState(null, "LPRC", `/products/list/#/${selectedId}`);
                setIsLoadingproduct(false);
            }
            fetchDetail();
        };
    }, [selectedId, axios]);


    const handleSelect = (id: number | undefined) => {
        setSelectedId(id)
    };

    const closeDetail = () => {
        setShowDetail(false);
    };


    return (
        <div className="container app-container">
            <div className="d-flex justify-content-between">
                <div className="h4 text-dark">Products</div>
                <button onClick={() => goTo('/')} className="btn btn-warning fw-bold"><span className="mr-2"><FontAwesomeIcon size='lg' icon={faDashboard} /></span> Dashboard</button>
            </div>
            {isLoadingDetail ? <Loading position="center" /> : <div className="d-flex-jcs entity-content mt-3">
                <div className="list-group col entity-list-items">
                    {products.map(i => (
                        <button type="button" onClick={() => handleSelect(i.id)} className={`list-group-item list-group-item-action ${i.id === selectedId ? 'bg-warning fw-bold' : ''}`} key={i.id}>{i.name}</button>
                    ))}
                </div>
                <div className={`col entity-detail ${showDetail ? '' : 'd-none'}`}>
                    <div className="card card-body">
                        <div className="heading">
                            <div className="h6 text-dark mb-0">Product Detail</div>
                            <button onClick={closeDetail} type="button" className="btn btn-light fw-bold btn-md"><span><FontAwesomeIcon size='lg' icon={faXmark} /></span></button>
                        </div>
                        {isLoadingproduct ? <Loading position="center" size="3rem" /> :
                            <div className="p-2">
                                <Field title="Name" value={product?.name ?? ''} />
                                <Field title="Unit Of Measure" value={product?.unitOfMeasure ?? ''} />
                            </div>}
                    </div>
                </div>
            </div>}
        </div>
    );
}
