import React from 'react'
import { faFileAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DocumentDto } from 'appDtos'
import { formatDate } from '../utils/date'
import FileUploadComponent from './FileUploadComponent'
import Icon from './Icon'
import { usePrompt } from './PromptModal'

interface DocumentSectionProps {
    documents: DocumentDto[];
    id: number;
    entityType: number;
    handleDelete: (document: DocumentDto) => any;
    handleDocumentView: (document: DocumentDto) => any;
    canUpload: boolean;
}


export function DocumentSectionTable(props: DocumentSectionProps) {
    const { openModal } = usePrompt();
    
    const confirm = (doc: DocumentDto) => {
        openModal({
            ok: () =>  props.handleDelete(doc),
            title: 'Please Confirm Document Deletion!',
            text: 'Are you sure you want to delete this document from the system. This action can not reverted.'
        });
    }

    return (
        <>
            {props.documents.length > 0 ?
                <div className="table-responsive">
                    <table className="table table-responsive-sm w-100">
                        <thead>
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Uploaded Date</th>
                                <th scope="col">Uploaded By</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.documents.map((d) => (
                                <tr key={d.id}>
                                    <td className="do-table-cell">{d.title}</td>
                                    <td className="do-table-cell">{formatDate(d.uploadedDate)}</td>
                                    <td className="do-table-cell">{d.uploadedBy}</td>
                                    <td className="d-flex justify-content-end">
                                        <div className="d-flex justify-content-between">
                                            <button onClick={() => props.handleDocumentView(d)} className="btn btn-warning btn-sm document-action-button fw-bold"><span className="mr-2"><Icon size='lg' icon={faFileAlt} /></span> View</button>
                                            {props.canUpload && d.isDeletable && <button onClick={() => confirm(d)} className="btn btn-danger btn-sm document-action-button fw-bold"><span className="mr-2"><Icon size='lg' icon={faTrash} /></span> Delete</button>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : <div className="m-3">No delivery orders have been created for this order.</div>}
            <FileUploadComponent entityType={props.entityType} entityId={props.id} canUpload={props.canUpload} docTitles={props.documents.map(d => d.title)} />
        </>
    )
}
