import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Icon(props: {color?: string, size?: 'lg' | 'sm', icon: IconDefinition}) {
  
  if (props.color) {
    return (
      <span><FontAwesomeIcon color={props.color} className="mr-2" size={props.size ?? 'lg'} icon={props.icon} /></span>
    );
  }
  return (
    <span><FontAwesomeIcon className="mr-2" size={props.size ?? 'lg'} icon={props.icon} /></span>
  );
}
