import { faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import Icon from '../../../Shared/components/Icon';
import useFilters from '../../../Shared/hooks/useFilters';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import { ImportersList } from './ImportersList';
import { RegisterImporter } from './RegisterImporter';

enum FilterOptions {
    LIST = 'List',
    ADD = 'Add New',
}

export function ImportersComponent() {
    const { filters, selectFilter } = useFilters(StorageKeys.AP_IMPORTERS_MENU, { [FilterOptions.LIST]: true, [FilterOptions.ADD]: false });
    const isList = filters[FilterOptions.LIST];
    const isForm = filters[FilterOptions.ADD];
    return (
        <div className="p-2 card shadow-sm">
            <HeadingElement text="Importers Management" />
            <ul className="nav">
                <li className="nav-item">
                    <button disabled={isList} onClick={() => selectFilter(FilterOptions.LIST)} className="btn btn-md m-1 fw-bold btn-link" aria-current="page"><Icon icon={faList}/> {FilterOptions.LIST}</button>
                </li>
                <li className="nav-item">
                    <button disabled={isForm} onClick={() => selectFilter(FilterOptions.ADD)} className="btn btn-md m-1 fw-bold btn-link"><Icon icon={faPlus}/> {FilterOptions.ADD}</button>
                </li>
            </ul>
            {filters[FilterOptions.LIST] && <ImportersList />}
            {filters[FilterOptions.ADD] && <RegisterImporter />}
        </div>
    );
}
