import React from 'react'
import qs from 'qs';
import { format, parseISO } from 'date-fns';

import Icon from '../../Shared/components/Icon';
import Loading from '../../Shared/components/Loading';
import Pagination from '../../Shared/components/Pagination';
import useApi from '../../Shared/hooks/useApi';
import useAppFilters from '../../Shared/hooks/useAppFilters';
import useAuth from '../../Shared/hooks/useAuth';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDashboard, faFolderPlus, faGasPump, faMagnifyingGlass, faShip, faTruck, faX, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterKeyValue, ILoadingRequestDto, PaginatedLoadingResponse, FilterRequest } from 'appDtos';
import { Link } from 'react-router-dom';
import { NavigationButton } from '../../Shared/components/NavigationButton';
import { NoResultsFound } from '../../Shared/components/NoResultsFound';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { formatDate } from '../../Shared/utils/date';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { scrollToTop } from '../../Shared/utils/windowUtil';
import { DatePickerComponent, DateRange } from '../../Shared/components/DatePickerComponent';
import "./orderList.css";

export default function OrdersList() {
  const axios = useApi();
  const [results, setTransferOrders] = React.useState<ILoadingRequestDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState({ currentPage: 0, pages: 0 });
  const { errorMessage, setError } = useApiHandleError();
  const { filters } = useAppFilters();
  const { isInRole, hasPermission, rCodes, pCodes } = useAuth();
  const [transferOrderFilterRequest, setTransferOrderFilterRequest] = React.useState<FilterRequest>(() => {
    if (window?.location?.hash) {
      const values = qs.parse(window.location.hash.replace('#/?', ''))
      const stringValues = ['transferOrderNumber', 'createdFrom', 'createdTo']
      const data = Object.entries(values).reduce((r, [k, v]) => ({ ...r, [k]: stringValues.includes(k) ? v : Number(v) }), {});
      return data;
    }
    return {};
  });
  const [search, setSearch] = React.useState(transferOrderFilterRequest.transferOrderNumber ?? '');
  const [dateRange, setDateRange] = React.useState<{ from: Date | null, to: Date | null }>(() => {
    return {
      from: transferOrderFilterRequest.createdFrom ? parseISO(transferOrderFilterRequest.createdFrom as string) : null,
      to: transferOrderFilterRequest.createdTo ? parseISO(transferOrderFilterRequest.createdTo as string) : null
    };
  });

  const handlePaginate = (pageNumber: number) => setTransferOrderFilterRequest({ ...transferOrderFilterRequest, pageNumber });

  const handleDateChange = (range: DateRange) => {
    const update = { ...transferOrderFilterRequest };
    const { from, to } = range;
    from ? update.createdFrom = format(from, 'yyyy-MM-dd') : delete update.createdFrom;
    to ? update.createdTo = format(to, 'yyyy-MM-dd') : delete update.createdTo;
    setDateRange({ ...dateRange, ...range });
    setTransferOrderFilterRequest(update);
  }

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const result = await axios.post<PaginatedLoadingResponse>(`TransferOrder/Search`, transferOrderFilterRequest);
        const { requests, pages, currentPage } = result.data;
        setTransferOrders(requests);
        setPageInfo({ pages, currentPage });
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetch();
  }, [axios, setError, transferOrderFilterRequest]);

  const handleFilterChange = (filter: { [key: string]: number | string }) => {
    const [[key, value]] = Object.entries(filter);

    let update = { ...transferOrderFilterRequest };

    if (transferOrderFilterRequest[key] === value) {
      delete update[key];
      setTransferOrderFilterRequest(update);
    } else {
      update = { ...update, ...filter }
      setTransferOrderFilterRequest(update)
    }
  }

  React.useEffect(() => {
    const url = Object.entries(transferOrderFilterRequest).filter(([, v]) => Boolean(v)).reduce((r, [k, v]) => r ? `${r}&${k}=${v}` : `/transfer-orders/list/#/?${k}=${v}`, '');
    scrollToTop();
    if (url) {
      return window.history.replaceState(null, "LPRC", url);
    }
    return window.history.replaceState(null, "LPRC", "/transfer-orders/list");
  }, [transferOrderFilterRequest])

  const hasMultipleFilters = React.useMemo(() => Object.values(transferOrderFilterRequest).length > 1, [transferOrderFilterRequest]);
  const handleClearAll = () => {
    setTransferOrderFilterRequest({});
    setSearch('');
    setTransferOrders([]);
    setDateRange({ from: null, to: null })
  }

  const hasSearchTerm = !!search;
  const handleSearch = () => setTransferOrderFilterRequest({ ...transferOrderFilterRequest, transferOrderNumber: search })
  const handleSearchClear = () => {
    delete transferOrderFilterRequest.transferOrderNumber;
    setSearch('');
    setTransferOrderFilterRequest({ ...transferOrderFilterRequest });
  }

  return (
    <div className="container app-container">
      <div className="d-flex-jcb page-heading">
        <div className="h4 text-dark">Transfer Orders</div>
        <div className="d-flex-jce">
          {isInRole(rCodes?.R100) && hasPermission(pCodes?.P200) && <NavigationButton link="/create-transfer-order" icon={faFolderPlus} label="Create Transfer Order" />}
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      <div className="d-flex-jcs">
        <div className="filters">
          <div className="input-group mb-2">
            <input onChange={(e: any) => setSearch(e.target.value)} value={search} type="text" className="form-control" placeholder="Enter transfer order number" aria-label="Search input" aria-describedby="search" />
            {hasSearchTerm && <button onClick={handleSearchClear} type="button" className="input-group-text fw-bold" id="search"><Icon icon={faX} /></button>}
            <button disabled={!hasSearchTerm} onClick={handleSearch} type="button" className="input-group-text fw-bold bg-warning" id="search"><Icon icon={faMagnifyingGlass} /></button>
          </div>
          <div className="filter-section-title">
            <h5 className="m-0">Filter</h5>
            {hasMultipleFilters && <button aria-label="filter-collaspe-button" type="button" onClick={handleClearAll} className="btn btn-sm btn-link p-0 m-0 text-dark fw-bold">Clear All Filters</button>}
          </div>
          <div className="mb-1 shadow">
            <label htmlFor="date-range" className="form-label fw-bold border p-2 w-100 border-warning"><Icon icon={faCalendar} /> Created Date</label>
            <DatePickerComponent setDate={handleDateChange} dateRange={dateRange} />
          </div>
          {Boolean(filters) ? <div>
            <FilterSet icon={faDashboard} filterKey="stage" title="Stages" keyValues={(filters?.transferOrderFilter?.stages ?? []) as FilterKeyValue[]} value={transferOrderFilterRequest.stage} onChange={handleFilterChange} />
            <FilterSet icon={faGasPump} filterKey="productId" title="Products" keyValues={filters?.entityFilters?.products ?? []} value={transferOrderFilterRequest.productId} onChange={handleFilterChange} />
            <FilterSet icon={faShip} filterKey="importerId" title="Importers" keyValues={filters?.entityFilters?.importers ?? []} value={transferOrderFilterRequest.importerId} onChange={handleFilterChange} />
            <FilterSet icon={faTruck} filterKey="distributorId" title="Distributors" keyValues={filters?.entityFilters?.distributors ?? []} value={transferOrderFilterRequest.distributorId} onChange={handleFilterChange} />
          </div> : <Loading />}
        </div>
        <div id="resultList" className={isLoading ? "results-list pt-5" : "results-list"}>
          {isLoading && <Loading position="center" />}
          {!isLoading && results.length > 0 && results.map(order => (
            <div className={order.status === 'Delivered' ? "card p-3 mb-2 shadow border-success" : "card p-3 mb-2 shadow border-warning"} key={order.id}>
              <div className="d-flex-jcb card-top">
                <div>
                  <div className="field-heading">Transfer #</div>
                  <Link to={`/transfer-order-detail/${order.id}`} className="btn btn-link btn-md text-decoration-none fw-bold p-0 h5 mb-0">{order.transferOrderNumber}</Link>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="mb-0 field-heading">Status</div>
                  <div className="h6 mb-0">{order.status === 'Delivered' && <Icon icon={faCircleCheck} color="green" />} {order.status}</div>
                </div>
              </div>
              <div className="d-flex-jcs border-bottom border-top pt-2">
                <Field title="Quantity" col="col-md-4 col-lg-4" value={`${displayQuantity(order.quantity)} Gal.`} />
                <Field title="Created Date" col="col-md-4 col-lg-4" value={formatDate(order.createdDate)} />
                <Field title="Created By" col="col-md-4 col-lg-4" value={order.submittedBy} />
              </div>
              <div className="d-flex-jcs pt-2">
                <Field title="Product" col="col-md-4 col-lg-4" value={order.product} />
                <div className="col-md-4 col-lg-4">
                  <div className="mb-0 field-heading">Importer</div>
                  <div title={order.importer} className="h6 mb-0 text-truncate">{order.importer}</div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="mb-0 field-heading">Distributor</div>
                  <div title={order.distributor} className="h6 mb-0 text-truncate">{order.distributor}</div>
                </div>
              </div>
            </div>))}
          {(!isLoading && results.length === 0 && !errorMessage) && <NoResultsFound message="No results were found." />}
          {errorMessage && <NoResultsFound message={errorMessage} />}
          {!isLoading && <div className="d-flex justify-content-start mt-4">
            {results.length > 0 && <button onClick={() => scrollToTop()} className="btn btn-warning btn-sm fw-bold" disabled={results.length < 10}><span><FontAwesomeIcon size='sm' icon={faArrowAltCircleUp} /></span> Go to top</button>}
            <Pagination {...pageInfo} paginate={handlePaginate} />
          </div>}
        </div>
      </div>
    </div>
  );
}

interface FilterSetProps {
  icon: IconDefinition;
  title: string;
  keyValues: FilterKeyValue[];
  filterKey: string;
  value?: number | string;
  onChange: (value: any) => void
}

export function FilterSet(props: FilterSetProps) {
  const [collaspe, setCollaspe] = React.useState(false);

  return (
    <div className="mb-1 shadow">
      <div className="filter-set-header border border-warning">
        <div className="h6 mb-0"><Icon icon={props.icon} /> {props.title}</div>
        <button aria-label="filter-collaspe-button" type="button" onClick={() => setCollaspe(!collaspe)} className="btn btn-link p-0 m-0"><Icon color="black" icon={collaspe ? faChevronUp : faChevronDown} /></button>
      </div>
      <div className={collaspe ? "d-none" : "filter-set"}>
        <ul className="list-group">
          {props.keyValues.map((s: any) => (
            <li key={s.key} title={s.value} className="list-group-item text-truncate filter-set-item">
              <input
                onChange={() => props.onChange({ [props.filterKey]: s.key })}
                className="form-check-input me-1"
                type="checkbox"
                value={s.key}
                aria-label={s.value}
                checked={props.value === s.key}
              />
              {s.value}
            </li>))
          }
        </ul>
      </div>
    </div>
  );
}

export function Field({ title, value, col }: { title: string, value: string | undefined, col?: string }) {
  return (
    <div className={col ? col : "col-md-3"}>
      <div className="mb-0 field-heading">{title}</div>
      <div className="h6 mb-0">{value}</div>
    </div>
  );
}
