import React from 'react';
import HeadingElement from '../../../Shared/components/HeadingElement';
import useFilters from '../../../Shared/hooks/useFilters';
import { StorageKeys } from '../../../Shared/hooks/useLocalStorage';
import { CreateProduct } from './CreateProduct';
import { ProductList } from './ProductList';

export enum ProductMenu {
    LIST = 'Product List',
    ADD_NEW_PRODUCT = 'Add New Product',
    ADD_NEW_CATEGORY = 'Add New Product Category',
}

export function ProductsComponent() {
    const { filters, selectFilter } = useFilters(StorageKeys.AP_PRODUCTS_MENU, { [ProductMenu.LIST]: false, [ProductMenu.ADD_NEW_PRODUCT]: true });

    const isList = filters[ProductMenu.LIST];
    const isForm = filters[ProductMenu.ADD_NEW_PRODUCT];

    return (
        <div className="p-2 card shadow-sm">
            <HeadingElement text="Product Management" />
            <ul className="nav">
                <li className="nav-item">
                    <button disabled={isList} onClick={() => selectFilter(ProductMenu.LIST)} className={`btn btn-md m-1 fw-bold ${isList ? 'btn-primary' : 'btn-warning'}`} aria-current="page">{ProductMenu.LIST}</button>
                </li>
                <li className="nav-item">
                    <button disabled={isForm} onClick={() => selectFilter(ProductMenu.ADD_NEW_PRODUCT)} className={`btn btn-md m-1 fw-bold ${isForm ? 'btn-primary' : 'btn-warning'}`}>{ProductMenu.ADD_NEW_PRODUCT}</button>
                </li>
            </ul>
            {isList && <ProductList />}
            {isForm && <CreateProduct />}
        </div>
    );
}
