import { IUserDetailDto } from 'appDtos';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import Loading from '../../../Shared/components/Loading';
import useApi from '../../../Shared/hooks/useApi';

export default function UserProfile() {
  const [detail, setUserDetail] = React.useState<IUserDetailDto>();
  const axios = useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef(false);
  const { id } = useParams();

  React.useEffect(() => {
    if (!ref.current) {
      setIsLoading(true);
      const fetchUserDetail = async () => {
        if (id && !detail) {
          const result = await axios.get(`/User/${id}`);
          setUserDetail(result.data.userDetail);
        }
        setIsLoading(false);
      };
      fetchUserDetail();
    }

    return () => {
      ref.current = true;
    };

  }, [id, axios, detail]);

  return (
    <div className="container app-container">
      <div className="d-flex justify-content-between">
        <div className="h4 text-dark">Your Profile</div>
      </div>
      {isLoading ? <Loading loadingText="Loading your detail." position="center" /> :<div className="card mt-2">
        {/* <img src="..." className="card-img-top" alt="..." /> */}
        <div className="card-body">
          <h5 className="card-title">{detail?.firstName} {detail?.lastName}</h5>
          <p className="card-title">{detail?.email}</p>
          <Link className="mt-5" to="/change-password">Change your password</Link>
        </div>
      </div>}
    </div>
  )
}
