import React from "react";
import Alert, { AlertType } from "./Shared/components/Alert";

export class ErrorBoundary extends React.Component<any> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Alert type={AlertType.DANGER} message="Something went wrong." />;
    }
    return this.props.children;
  }
}
