import { CommentDto, IReviewDto } from 'appDtos';
import React from 'react';
import { format, parseISO } from 'date-fns'
import { Action } from '../constant';

export function HistoryComponent(props: { reviews: IReviewDto[] }) {

  return (
    <div className="list-group history-items-container">
      {props.reviews.length ? props.reviews.map(r => (
        <div className="m-2" aria-current="true" key={r.id}>
          <div className="d-flex-jcb">
            <div className="fst-italic">
              <strong>{r.submitter}</strong> <span>{r.reviewAction?.toLowerCase()}</span> on <span>{`${format(parseISO(r.date), 'MM/dd/yyyy')} at ${format(parseISO(r.date), 'h:mm a')}`}.</span>
            </div>
          </div>
          <small>{r.comment}</small>
        </div>
      )) : <div className="m-3">All future activities will apprear here.</div>}
    </div>
  );
}

export function CommentComponent(props: { id: number, comments: CommentDto[], handleCommentAction: (data: { comment: CommentDto, action: string }) => void }) {

  return (
    <div className="list-group history-items-container">
      {props.comments.length ? props.comments.map(r => (
        <div className="m-2" key={r.id}>
          <strong className="fst-italic">{r.submitter}</strong>
          <div>
            <div><small>{r.text}</small></div>
            <span>{`${format(parseISO(r.submittedDate), 'MM/dd/yyyy')} at ${format(parseISO(r.submittedDate), 'h:mm a')}`}</span> {r.edited && <span>Edited</span>} {r.isEditable && <span><button data-bs-toggle="modal" data-bs-target="#CommentModal" onClick={() => props.handleCommentAction({ comment: r, action: Action.EDIT_COMMENT })} className="btn btn-link btn-sm">Edit</button></span>} {r.isDeletable && <span><button data-bs-toggle="modal" data-bs-target="#CommentModal" onClick={() => props.handleCommentAction({ comment: r, action: Action.DELETE_COMMENT })} className="btn text-danger btn-link btn-sm">Delete</button></span>}
          </div>
        </div>
      )) : <div className="m-3">All future comments will apprear here.</div>}
    </div>
  );
}
