import { IReadUserDetailResponse, IUserDetailDto } from "appDtos";
import React from "react";
import useApi from "../hooks/useApi";
import { useApiHandleError } from "../hooks/useApiHandleError";


export interface UserDetailProviderType {
    userDetail?: IUserDetailDto,
    triggerRefresh: () => void,
    event:any
    errorMessage: string;
}

export const UserDetailContext = React.createContext<UserDetailProviderType>({ triggerRefresh: () => { }, event: null, errorMessage: '' });

export const UserDetailProvider = ({ children, userId }: any) => {
    const [userDetail, setUserDetail] = React.useState<IUserDetailDto>();
    const [event, setEvent] = React.useState<any>();
    const {errorMessage, setError} = useApiHandleError();
    const axios = useApi();

    React.useEffect(() => {
            const fetchUserDetail = async () => {
                try {
                    const result = await axios.get<IReadUserDetailResponse>(`/user/${userId}`);
                    setUserDetail(result.data.userDetail);
                } catch (error) {
                    setError(error);
                }
            };
            fetchUserDetail();      
    }, [userId, axios, event, setError]);
    
    const triggerRefresh = () => {
        setEvent(new Date());
    }

    return (
        <UserDetailContext.Provider value={{ triggerRefresh, userDetail, event, errorMessage }}>
            {children}
        </UserDetailContext.Provider>
    );
}
