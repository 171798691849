import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../Shared/components/Alert';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import Icon from '../../Shared/components/Icon';
import { Action } from '../../Shared/constant';
import { useNavigate } from 'react-router-dom';

interface Props {
  id?: number;
  action: string;
}

export function TransferOrderReviewModal(props: Props) {
  const { errorMessage, setError } = useApiHandleError();
  const [comment, setComments] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [successResponse, setSuccessResponse] = React.useState('');
  const axios = useApi();
  const goTo = useNavigate();

  React.useEffect(() => {
    if (errorMessage) {
      setError(undefined);
    }

  }, [props.action, errorMessage, setError]);

  const handleCommentChange = (e: any) => {
    setComments(e.target.value);
  };

  const isReviewing = props.action === Action.REVIEW;
  const isApproving = props.action === Action.APPROVE;
  const isCanceling = props.action === Action.CANCEL;

  const handleSubmit = async () => {
    setIsLoading(true);

    const map: { [key: string]: number } = {
      [Action.REVIEW]: 5,
      [Action.APPROVE]: 10,
      [Action.CANCEL]: 20,
    };

    try {
      const payload = {
        actionCode: map[props.action],
        transferOrderId: props.id,
        comment: comment?.trim(),
      }

      const result = await axios.post(`TransferOrder/Actions`, payload);
      setSuccessResponse(result.data.message);
      setIsLoading(false);
      setComments('');
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const renderHeaderText = () => {
    if (successResponse) {
      return '';
    }
    return `${props.action} Transfer Order.`;
  }

  const renderAlert = () => {
    if (successResponse) {
      return <Alert type="success" message={successResponse} />;
    } else if (errorMessage) {
      return <Alert type="danger" message={errorMessage} />;
    } else if (isApproving) {
      return <h6 className="h6">Clicking submit will <strong>APPROVE</strong> this transfer order and move it to the next stage in the processing workflow.</h6>;
    } else if (isReviewing) {
      return <h6 className="h6">Clicking submit will submit your <strong>REVIEW</strong> for this transfer order.</h6>;
    } else if (isCanceling) {
      return <h6 className="h6">Are you sure you want to <strong>CANCEL</strong> this transfer order? <br /> This action CAN NOT be reverted!</h6>;
    } else {
      return null;
    }
  }

  const handleClose = () => {
    if (successResponse) {
      if (props.action === Action.CANCEL) {
        return goTo('/transfer-orders/list');
      }
      return window.location.reload();
    }
    setComments('');
    setError(undefined);
  };

  return (
    <div className="modal fade" data-bs-backdrop="static" id="TransferOrderReviewModal" tabIndex={-2} aria-labelledby="TransferOrderReviewModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{renderHeaderText()}</h5>
            <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {renderAlert()}
            {!(successResponse || props.action === Action.CANCEL) && <>
              <label htmlFor="basic-url" className="form-label mt-2 fw-bold">Leave a comment. <span className="fst-italic text-muted">(Optional)</span></label>
              <div className="input-group mb-2">
                <textarea onChange={handleCommentChange} className="form-control" aria-label="With textaresa" value={comment}></textarea>
              </div>
            </>}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-secondary fw-bold" disabled={isLoading} data-bs-dismiss="modal">Close</button>
            {!successResponse && <button type="button" onClick={handleSubmit} disabled={isLoading || !!errorMessage} className="btn btn-warning fw-bold">
              {isLoading ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...</> :
                <><span><Icon icon={faCheck} /></span> {props.action}</>}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}
