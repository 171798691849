
export const getEndpoint = () => {
    const hostname = window.location.hostname;

    if (hostname.startsWith('localhost') || hostname.startsWith('dev.')) {
        return 'https://ppms-dev-api.salmonrock-06fc14bd.centralus.azurecontainerapps.io/api/v1/'; 
    } else if (hostname.startsWith('www.lprc')) {
        return 'https://ppms-prod-api-v2.salmonrock-06fc14bd.centralus.azurecontainerapps.io/api/v1/';
    }else{
        return 'https://localhost:7206/api/v1/'
    }
}