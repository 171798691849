import { faDashboard, faList } from '@fortawesome/free-solid-svg-icons';
import { IProductDto, IUserEntities, PostOrderDto } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import ControlInputField from '../../Shared/components/ControlInputField';
import Loading from '../../Shared/components/Loading';
import { BackButton, NavigationButton, SubmitButton } from '../../Shared/components/NavigationButton';
import RequiredText from '../../Shared/components/RequiredText';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../Shared/hooks/useFormProcessor';
import { scrollToTop } from '../../Shared/utils/windowUtil';

interface PostOrder extends PostOrderDto {}

export default function CreateOrder() {
  const goTo = useNavigate();
  const axios = useApi();
  const [results, setResult] = React.useState<IUserEntities>({ importers: [], distributors: [] });
  const ref = React.useRef(false);
  const [formErrorMessage, setErrorMesssage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();
  const {
    handleOnChange,
    handleFieldError,
    isProcessing,
    setIsProcessing,
    formData,
  } = useFormProcessor<PostOrder>({
    importerId: '',
    distributorId: '',
    transferOrderNumber: '',
    productId: '',
    quantity: '',
  });

  React.useEffect(() => {
    if (!ref.current) {
      const fetch = async () => {
        try {
          const result = await axios.post<IUserEntities>('User/Entities', {
            includeImporters: true,
            includeDistributors: true,
            ensureImporterAuthorization: true,
            ensureDistributorAuthorization: false,
          });

          if (result?.data) {
            setResult(result.data);
          }
        } catch (error) {
          setError(error);
        }
      }

      fetch();
    }

    return () => {
      ref.current = true;
    };
  }, [axios, results, setError]);

  React.useEffect(() => {
    setErrorMesssage('');
  }, [formData]);

  const handleSubmit = async () => {
    if (errorMessage) {
      setError('');
    }
    const missingValues = Object.values(formData).filter((value) => !value);

    if (missingValues.length) {
      scrollToTop();
      return setErrorMesssage('Please complete all required fields.');
    }

    setIsProcessing(true);

    try {
      await axios.post('TransferOrder/', { order: formData });
      return goTo('/transfer-orders/list');
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const handleImporterSelect = (e: any) => {
    handleOnChange({ importerId: e.target.value });
  };

  const handleProductSelect = (e: any) => {
    handleOnChange({ productId: e.target.value });
  };

  const handleQuantityChange = (e: any) => {
    handleOnChange({ quantity: e.target.value.replace(/\D/g, '') });
  };

  const handleDistributorSelect = (e: any) => {
    handleOnChange({ distributorId: e.target.value });
  };

  const products = React.useMemo(() => {
    if (!formData.importerId) return [];
    const importer = results.importers.find(i => i.id === Number(formData.importerId));
    if (!importer) return [];
    let importerProducts: IProductDto[] = [];
    for (const inventory of importer.inventories) {
      if (!importerProducts.find(i => i.id === inventory.product.id)) {
        importerProducts = [...importerProducts, inventory.product];
      }
    }
    return importerProducts;
  }, [formData.importerId, results.importers]);

  return (
    <div className="container app-container">
      <div className="d-flex-jcb">
        <div className="h4 text-dark">Create Transfer Order</div>
        <div className="d-flex-jce">
          <NavigationButton link="/transfer-orders/list" icon={faList} label="Transfer Orders" />
          <NavigationButton link="/" icon={faDashboard} label="Dashboard" />
        </div>
      </div>
      {isProcessing && <Loading loadingText="Processing..." position="center" />}
      {!!(errorMessage || formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
      {!isProcessing && <><div className="col">
        <ControlInputField
          onChange={handleOnChange}
          autoComplete="off"
          onFieldError={handleFieldError}
          label="Transfer Order Number"
          placeholder="Enter transfer order number."
          id="firstName"
          value={formData.transferOrderNumber}
          name="transferOrderNumber"
          type="text"
          required
        />
      </div>
      <div className="col">
        <div className="form-label fw-bold">Importer <RequiredText /></div>
        <select className="form-select" aria-label="Default select example" onChange={handleImporterSelect}>
          <option selected>Select importer</option>
          {results.importers.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
        </select>
      </div>
      <div className="col mb-3">
        <div className="form-label fw-bold">Product <RequiredText /></div>
        <select className="form-select" aria-label="Default select example" onChange={handleProductSelect}>
          <option selected>Select Product</option>
          {products.map(d => <option value={d.id} key={d.id} selected={d.id === Number(formData.productId)}>{d.name}</option>)}
        </select>
      </div>
      <div className="col">
        <label
          htmlFor="quantity"
          className="form-label fw-bold"
        >
          Product Quantity <RequiredText />
        </label>
        <input
          onChange={handleQuantityChange}
          type="text"
          value={formData.quantity}
          name="quantity"
          className="form-control"
          placeholder="Enter product quantity"
          id="quantity"
          aria-describedby="quantity field"
          required
        />
      </div>
      <div className="col mb-4 mt-3">
        <div className="form-label fw-bold">Distributor <RequiredText /></div>
        <select className="form-select" aria-label="Default select example" onChange={handleDistributorSelect}>
          <option selected>Select distributor</option>
          {results.distributors.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
        </select>
      </div></>}
      <div className="page-action-button-group">
        <BackButton disabled={isProcessing} />
        <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing}/>
      </div>
    </div>
  );
}

