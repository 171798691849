import { UserEmailConfirmationResponse as UserAccountActivationResponse } from 'appDtos';
import React from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';

export interface UserAccountActivationProps {
  userId: string;
}

export const UserAccountActivation = (props: UserAccountActivationProps) => {
  const axios = useApi();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const { errorMessage, setError } = useApiHandleError();

  const handleSubmit = async () => {
    setIsProcessing(true);
    setSuccessMessage('');

    try {
      const response = await axios.post<UserAccountActivationResponse>('Account/Activation', { userId: props.userId });
      if (response?.data) {
        setSuccessMessage(response?.data.message);
      }
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  return (
    <div className="card">
      <div className="card-body text-center">
        {(!!successMessage || !!errorMessage) && <Alert type={errorMessage ? AlertType.DANGER : AlertType.SUCCESS} message={successMessage || errorMessage} />}
        {successMessage ? null : <h6 className="card-title">New User Registration Successful</h6>}
        {successMessage ? null : <button onClick={handleSubmit} className="btn btn-md btn-warning m-1 fw-bold">{isProcessing ? "Sending email..." : 'Send account activitation email'}</button>}
        {successMessage ? null : <p>You can also send the account activation email anytime from this user's detail page.</p>}
      </div>
    </div>
  );
};
