import { useEffect, useState } from 'react';
import axios from '../../../Shared/Api/axios';
import { faLockOpen, faSignIn } from '@fortawesome/free-solid-svg-icons';

import ControlInputField from '../../../Shared/components/ControlInputField';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { ILoginRequest, ILoginResponse } from 'appDtos';
import { Link } from 'react-router-dom';
import Icon from '../../../Shared/components/Icon';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import { isValidEmail } from '../../../Shared/components/validators';

import './Login.css';
export interface LoginProps {
  setUpAuth: (loginResponse: ILoginResponse) => void;
}

export default function Login({ setUpAuth }: LoginProps) {
  const [formErrorMessage, setFormError] = useState('');
  const { errorMessage, setError } = useApiHandleError('login');
  const [show, setShow] = useState(false);
  const {
    handleOnChange,
    setIsProcessing,
    formData,
    isProcessing,
  } = useFormProcessor<ILoginRequest>({ email: '', password: '', code: '', is2F: false }, { email: true, password: true });

  useEffect(() => setFormError(''), [formData]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!isValidEmail(formData.email)) return setFormError('Email is invalid.');
    if (!formData.password.trim()) return setFormError('Password is required.');

    setIsProcessing(true);

    try {
      const result = await axios.post<ILoginResponse>('Account/Login', formData);
      if (result?.data) {
        if (result.data.shouldVerify) {
          handleOnChange({ is2F: true });
        } else {
          setUpAuth(result.data);
          handleOnChange({ email: '', password: '' });
        }
      }
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const handle2FSubmit = async (e: any) => {
    e.preventDefault();
    const { email, code, password } = formData;
    if (!password.trim()) return setFormError('Password is required.');
    if (!isValidEmail(email)) return setFormError('Email is invalid.');
    if (!code?.trim()) return setFormError('Code is required.');

    setIsProcessing(true);
    try {
      const result = await axios.post<ILoginResponse>('Account/Login2F', { email, code, password });
      if (result?.data) {
        setUpAuth(result.data);
        handleOnChange({ email: '', password: '', code: '', is2F: false });
      }
    } catch (error: any) {
      setError(error);
    }
    setIsProcessing(false);
  }

  const toggleShow = () => setShow(!show);

  if (formData.is2F) {
    return (
      <div className='login-form'>
        {(errorMessage || formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
        <form onSubmit={handle2FSubmit}>
          <ControlInputField
            onChange={handleOnChange}
            label="Enter code from your email"
            placeholder="Enter code"
            id="code"
            value={formData.code}
            name="code"
            type="text"
            required={true}
            focusOnMount={true}
          />
          <button type="submit" disabled={!!formErrorMessage} className="btn btn-md btn-warning fw-bold w-100">
            {!isProcessing && <span className="mr-2"><Icon icon={faLockOpen} /></span>}
            {isProcessing && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}  {isProcessing ? 'Verifying...' : 'Verify'}
          </button>
        </form>
        <button onClick={() => handleOnChange({ is2F: false })} type="button" className="mb-1 mt-3 btn btn-link text-light fw-bold p-0">Go back to login.</button>
      </div>);
  }

  return (
    <div className='login-form'>
      <div className="text-warning border-2 border-bottom border-warning fs-3">Login</div>
      {(errorMessage || formErrorMessage) && <Alert type={AlertType.DANGER} message={errorMessage || formErrorMessage} />}
      <form onSubmit={handleSubmit}>
        <ControlInputField
          onChange={handleOnChange}
          label="Email Address"
          placeholder="Enter email"
          id="email"
          value={formData.email}
          name="email"
          type="text"
          required={true}
          focusOnMount={true}
        />
        <ControlInputField
          onChange={handleOnChange}
          label="Password"
          placeholder="Enter password"
          id="password"
          autoComplete="off"
          value={formData.password}
          name="password"
          type={show ? "text" : "password"}
          required={true}
        />
        <button onClick={toggleShow} type="button" className="mb-1 mt-0 btn btn-link text-warning fw-bold p-0">{show ? 'Hide' : 'Show'}</button>
        <button type="submit" disabled={!!formErrorMessage} className="btn btn-md btn-warning fw-bold w-100">
          {!isProcessing && <span className="mr-2"><Icon icon={faSignIn} /></span>}
          {isProcessing && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}  {isProcessing ? 'Logging you in...' : 'Log in'}
        </button>
      </form>
      <Link to="/forgot-password" className="btn btn-md btn-link my-1 text-light fw-bold">Forgot password?</Link>
    </div>
  )
}
