import React, { useState } from "react";
import './ModalContext.css';

type ModalData = {
    element: JSX.Element;
};

export interface ModalContextType {
    data: ModalData,
    setModalData: (data: ModalData) => void,
}

export const ModalContext = React.createContext<ModalContextType>({ data: { element: <></> }, setModalData: (data: ModalData) => { } });

export const ModalProvider = ({ children }: any) => {
    
    const [data, setModalData] = useState<ModalData>({ element: <></> });
    
    return (
        <ModalContext.Provider value={{ data, setModalData }}>
            {children}
        </ModalContext.Provider>
    );
}
