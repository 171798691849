import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

export default function Pagination(props: { currentPage: number, pages: number, paginate: (pageNumber: number) => any }) {
    const goBack = () => props.paginate(props.currentPage - 1)
    const goForward = () => props.paginate(props.currentPage + 1);

    if (props.pages === 0) {
        return null;
    }

    return (
        <div className="pagination-buttons btn-group mx-2 text-center" role="group" aria-label="First group">
            <button title="Go back to previous page" disabled={props.currentPage === 1} type="button" onClick={goBack} className="btn btn-warning btn-sm px-3"><span><FontAwesomeIcon size='lg' icon={faAngleLeft} /></span></button>
            <button disabled type="button" className="btn btn-warning btn-sm px-3 fw-bold">Page {props.currentPage} of {props.pages}</button>
            <button title="Go to next page" disabled={props.currentPage === props.pages} type="button" onClick={goForward} className="btn btn-warning btn-sm px-3"><span><FontAwesomeIcon size='lg' icon={faAngleRight} /></span></button>
        </div>
    )
}
