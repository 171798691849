import React from 'react'
import { Link } from 'react-router-dom';
import Loading from '../../../Shared/components/Loading';
import useApi from '../../../Shared/hooks/useApi';

export default function EmailConfirmationByUser() {
  const axios = useApi();
  const ref = React.useRef(false);
  const [message, setMessage] = React.useState('');
  const [isExpired, setIsExpired] = React.useState<boolean | undefined>();

  const values = React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }, []);

  React.useEffect(() => {
    if (!ref.current) {
      const { timestamp, email, token } = values;
      const confirmEmail = async () => {
        try {
          const response = await axios.post('Account/ConfirmEmail', { email, token });
          if (response.data) {
            setMessage(response.data.message);
          }
        } catch (error) {
          console.log(error)
        }
      };

      const expired = Date.now() > Date.parse(timestamp);

      if (!expired) {
        confirmEmail();
      }

      setIsExpired(expired);
    }

    return () => { ref.current = true };

  }, [values, axios]);

  if (isExpired === undefined) {
    return <Loading loadingText="Loading..." position="center" />
  }

  return (
    <div className="container app-container">
      <div className="card">
        <div className="card-body">
          {message ? <h5 className="card-title">{message}</h5> : <Loading loadingText="Loading..." position="center" />}
          {isExpired ? <h5 className="card-title">This link has expired. Please contact administrator to send new link.</h5> : ""}
          <Link to="/" className="btn btn-md btn-md btn-warning my-3 text-dark fw-bold">Go to login</Link>
        </div>
      </div>
    </div >
  );
}