import { IUserDetailDto } from 'appDtos';
import React from 'react';
import { RolesManagement } from './UserRolesManagement';
import { UserEntities, UserPermissionComponent, UserRoles } from './UserRoles';
import { UserPermissionManagement } from './UserPermissionManagement';
import { EntitiesManagement } from './UserEntitiesManagement';


export function UserAuthorizationSection(props: { user: IUserDetailDto; userAuth: any }) {
  const defaultValues = { isRoleEdit: false, isPermissionEdit: false, isAuthorizationEdit: false };
  const [editView, setEditView] = React.useState(defaultValues);
  const [hasPermissions, setHasPermissions] = React.useState(() => {
    return props.user.roles[0]?.hasPermissions ?? false;
  });

  const handleRoleEditCancel = () => setEditView(defaultValues);
  const handlePermissionEditCancel = () => setEditView(defaultValues);
  const handleAuthEditCancel = () => setEditView(defaultValues);

  return (
    <div>
      <h5 className="card-title mt-4 border p-2 theme-dark-color text-light mx-0">Authorization Profile</h5>
      <div className="px-3">
        <p className="">Employee: {props.user.isInternal ? 'Yes' : 'No'}</p>
        <div className='row'>
          <div className="col-12 border mb-2 pb-2 shadow-sm">
            <div className="d-flex justify-content-between align-items-center">
              <div className="h5">Roles</div>
              <button disabled={editView.isRoleEdit} onClick={() => setEditView({ ...defaultValues, isRoleEdit: true })} type="button" className="btn btn-warning fw-bold btn-sm m-2">Update</button>
            </div>
            {editView.isRoleEdit ? <RolesManagement
              userId={props.user.id}
              isInternal={props.user.isInternal}
              roles={props.user.roles}
              cancel={handleRoleEditCancel}
              setHasPermissions={setHasPermissions} /> :
              <UserRoles roles={props.user.roles} />}
          </div>
          {hasPermissions && <div className="col-12 border mb-2 pb-2 shadow-sm">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="h5">User Permissions</div>
                <button disabled={editView.isPermissionEdit} onClick={() => setEditView({ ...defaultValues, isPermissionEdit: true })} type="button" className="btn btn-warning fw-bold btn-sm m-2">Update</button>
              </div>
              {editView.isPermissionEdit ? <UserPermissionManagement
                userId={props.user.id}
                permissions={props.user.permissions}
                cancel={handlePermissionEditCancel} /> :
                <UserPermissionComponent permissions={props.user.permissions} />}
            </div>
          </div>}
          {!props.user.isInternal &&
            <div className="col-12 border mb-2 pb-2 shadow-sm">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h5">Associated Entites</div>
                  <button disabled={editView.isAuthorizationEdit} onClick={() => setEditView({ ...defaultValues, isAuthorizationEdit: true })} type="button" className="btn btn-warning fw-bold btn-sm m-2">Update</button>
                </div>
                {editView.isAuthorizationEdit ? <EntitiesManagement userId={props.user.id} roles={props.user.roles} entities={props.user.authorizations} cancel={handleAuthEditCancel} /> : <UserEntities authorizations={props.user.authorizations} />}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
