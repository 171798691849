import React from 'react';


export const Title = (props: { text: string; }) => {
    return (
        <div className="h5 bg-primary p-2 text-light">
            {props.text}
        </div>
    );
};
