import React from 'react';

export default function PageNotFound() {
  return (
    <div className="text-center container app-container">
      <h5 className="card-title display-6 text-danger">404 Page not found.</h5>
      <h6 className="card-subtitle mb-5 text-muted h5">The page you were looking for does not exist.</h6>
    </div >
  );
}


