import { IImporterDto } from 'appDtos';
import React from 'react';
import { ModalContext } from '../../../Shared/contexts/ModalContext';
import useApi from '../../../Shared/hooks/useApi';
import { useLoader } from '../../../Shared/hooks/useLoader';
import { compareImporter } from '../../Entities/ImportersEntity';
import { ImporterDetail } from './ImporterDetail';

export function ImportersList() {
    const [importers, setImporters] = React.useState<IImporterDto[]>([]);
    const ref = React.useRef(false);
    const axios = useApi();
    const { setModalData } = React.useContext(ModalContext);
    const { setIsLoading, renderLoader } = useLoader();

    const setImporterDetail = (id: any) => {
        setModalData({ element: <ImporterDetail id={id} /> });
    };

    React.useEffect(() => {
        if (!ref.current) {
            const fetchImporters = async () => {
                try {
                    setIsLoading(true);
                    const result = await axios.get<IImporterDto[]>(`Importer`);
                    setImporters(result.data);
                } catch (error) {

                }
                setIsLoading(false);
            };
            fetchImporters();
        }
        return () => { ref.current = true; };
    }, [axios, setIsLoading]);


    return (
        <div className="results-container">
            {renderLoader()}
            {importers.sort(compareImporter).map(p => (
                <div className="d-flex justify-content-between align-items-center search-results-card" key={p.id}>
                    <div className="fw-bold mb-0">{p.name}</div>
                    <button onClick={() => setImporterDetail(p.id)} type="button" className="btn btn-warning fw-bold btn-sm" data-bs-toggle="modal" data-bs-target="#sharedModal">Manage</button>
                </div>
            ))}
        </div>
    );
}
