import { faBoxesStacked, faBucket, faFolderPlus, faGasPump, faHandshake, faNoteSticky, faPaperclip, faShip, faTruck, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Count } from 'appDtos';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Alert, { AlertType } from '../../Shared/components/Alert';
import Icon from '../../Shared/components/Icon';
import useApi from '../../Shared/hooks/useApi';
import { useApiHandleError } from '../../Shared/hooks/useApiHandleError';
import { useLoader } from '../../Shared/hooks/useLoader';
import { displayQuantity } from '../../Shared/utils/quantityUtil';
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import "./dashboard.css"

interface DashboardSetting {
    showStockUpdatesList: boolean;
    showStockTransfersList: boolean;
    showTranfersOrdersList: boolean;
    showDeliveryOrdersList: boolean;
    showFanList: boolean;
    canCreateStockUpdates: boolean;
    canCreateStockTransfers: boolean;
    canCreateTranfersOrders: boolean;
    canCreateDeliveryOrders: boolean;
    canCreateFan: boolean;
    isInternal: boolean;
}

interface ApplicationDashboard {
    transferOrders: { [key: string]: Count } | null;
    deliveryOrders: { [key: string]: Count } | null;
    fillingAdviceNotes: { [key: string]: Count } | null;
    stocksUpdates: { [key: string]: Count } | null;
    stocksTransfers: { [key: string]: Count } | null;
    settings: DashboardSetting;
}

export function AppDashboard() {
    const [dashboard, setDashboard] = React.useState<ApplicationDashboard>({
        transferOrders: null, deliveryOrders: null, fillingAdviceNotes: null, stocksUpdates: null, stocksTransfers: null, settings: {
            showStockUpdatesList: false,
            showStockTransfersList: false,
            showTranfersOrdersList: false,
            showDeliveryOrdersList: false,
            showFanList: false,
            canCreateStockUpdates: false,
            canCreateStockTransfers: false,
            canCreateTranfersOrders: false,
            canCreateDeliveryOrders: false,
            canCreateFan: false,
            isInternal: false,
        }
    });
    const axios = useApi();
    const { isLoading, setIsLoading, renderLoader } = useLoader();
    const ref = React.useRef(false);
    const { errorMessage, setError } = useApiHandleError();
    const goTo = useNavigate();

    React.useEffect(() => {
        const redPath = window.localStorage.getItem('redPath');
        if (redPath) {
            window.localStorage.removeItem('redPath');
            return goTo(redPath);
        }

        if (!ref.current && window.location.pathname === '/' ) {
            const fetch = async () => {
                try {
                    setIsLoading(true);
                    const response = await axios.get<ApplicationDashboard>('/Dashboard');
                    if (response.data) {
                        const { 
                            transferOrders,
                            deliveryOrders,
                            fillingAdviceNotes,
                            stocksUpdates,
                            stocksTransfers,
                            settings } = response.data;
                        setDashboard({
                            transferOrders,
                            deliveryOrders,
                            fillingAdviceNotes,
                            stocksUpdates,
                            stocksTransfers,
                            settings,
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                }
            }

            fetch();
        }

        return () => {
            ref.current = true;
        };
    }, [axios, dashboard, setError, setIsLoading, goTo]);

    const transfersDashboard = Object.values(dashboard.transferOrders ?? {});
    const doDashboard = Object.values(dashboard.deliveryOrders ?? {});
    const fanDashboard = Object.values(dashboard.fillingAdviceNotes ?? {});
    const stocksUpdateDashboard = Object.values(dashboard.stocksUpdates ?? {});
    const stocksTransferDashboard = Object.values(dashboard.stocksTransfers ?? {});
    const settings = dashboard.settings;

    return (
        <div className="container app-container">
            <h6 className="h4">Dashboard</h6>
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            <div className="dashboard">
                <div className="dashboard-menu col-md-3 col-lg-3 col-sm-12">
                    <div className="theme-dark-color section-heading">Page Menu</div>
                    <DashboardMenuItem show={settings.showStockUpdatesList} link="/stock-request/list" icon={faBucket} label="Stocks Updates" />
                    <DashboardMenuItem show={settings.showStockTransfersList} link="/stock-transfer/list" icon={faBucket} label="Stocks Transfers" />
                    <DashboardMenuItem show={settings.showTranfersOrdersList} link="/transfer-orders/list" icon={faHandshake} label="Transfer Orders" />
                    <DashboardMenuItem show={settings.showDeliveryOrdersList} link="/delivery-orders/list" icon={faNoteSticky} label="Delivery Orders" />
                    <DashboardMenuItem show={settings.showFanList} link="/filling-advice-notes/list" icon={faPaperclip} label="Filling Advice Notes" />
                    <DashboardMenuItem show={settings.isInternal} link="/importers/list" icon={faShip} label="Importers" />
                    <DashboardMenuItem show={settings.isInternal} link="/distributors/list" icon={faTruck} label="Distributors" />
                    <DashboardMenuItem show={settings.isInternal} link="/products/list" icon={faGasPump} label="Products" />
                    <DashboardMenuItem show={settings.isInternal} link="/storageTanks/list" icon={faBoxesStacked} label="Storage Tanks" />
                    <DashboardMenuItem show={settings.isInternal} link="/reporting" icon={faChartBar} label="Reporting" />
                    {(settings.canCreateDeliveryOrders || settings.canCreateFan || settings.canCreateTranfersOrders || settings.canCreateStockUpdates) &&
                        <div className="theme-dark-color section-heading">Action Menu</div>}
                    <DashboardMenuItem show={settings.canCreateStockUpdates} link="/submit-stock-update" icon={faFolderPlus} label="Update Importer Stock" />
                    <DashboardMenuItem show={settings.canCreateStockTransfers} link="/submit-stock-transfer" icon={faFolderPlus} label="Transfer Stock" />
                    <DashboardMenuItem show={settings.canCreateTranfersOrders} link="/create-transfer-order" icon={faFolderPlus} label="Create Transfer Order" />
                    <DashboardMenuItem show={settings.canCreateDeliveryOrders} link="/create-delivery-order" icon={faFolderPlus} label="Create Delivery Order" />
                    <DashboardMenuItem show={settings.canCreateFan} link="/create-filling-advice-note" icon={faFolderPlus} label="Create Batch" />
                </div>
                <div className="dashboard-contents col-md-9 col-lg-9 col-sm-12">
                    {isLoading ? renderLoader({ text: 'Loading' }) : <>
                        <DashboardSection link="/transfer-orders/list" show={settings.showTranfersOrdersList} sectionTitle="Transfer Orders" isLoading={isLoading} values={transfersDashboard} />
                        <DashboardSection link="/delivery-orders/list" show={settings.showDeliveryOrdersList} sectionTitle="Delivery Orders" isLoading={isLoading} values={doDashboard} />
                        <DashboardSection link="/filling-advice-notes/list" show={settings.showFanList} sectionTitle="Filling Advice Notes" isLoading={isLoading} values={fanDashboard} />
                        <DashboardSection link="/stock-request/list" show={settings.showStockUpdatesList} sectionTitle="Stock Updates" isLoading={isLoading} values={stocksUpdateDashboard} />
                        <DashboardSection link="/stock-transfer/list" show={settings.showStockTransfersList} sectionTitle="Stock Transfers" isLoading={isLoading} values={stocksTransferDashboard} />
                    </>}
                </div>
            </div >
        </div>
    );
}

interface DashboardMenuItemProps {
    link: string;
    icon: IconDefinition;
    label: string;
    show: boolean;
}

const DashboardMenuItem = (props: DashboardMenuItemProps) => {
    const goTo = useNavigate();
    if (!props.show) {
        return null;
    }

    return (
        <button
            type="button"
            onClick={() => goTo(props.link)}
            className="menu-button btn btn-warning btn-sm fw-bold">
            <Icon icon={props.icon} /> <span>{props.label}</span>
        </button>
    );
}

interface DashboardSectionProps {
    sectionTitle: string;
    show: boolean;
    isLoading: boolean;
    values: any[];
    link: string;
}

function DashboardSection(props: DashboardSectionProps) {
    const goTo = useNavigate();

    if (!props.show) {
        return null;
    }

    return (
        <div className="contents-section">
            <div className="theme-dark-color section-heading">{props.sectionTitle}</div>
            {props.isLoading ?
                <div className="loading">Loading...</div>
                :
                <div className="section-elements">
                    {
                        props.values.sort((a, b) => a.order - b.order).map(o =>
                            <button onClick={o.stage !== null ? () => goTo(`${props.link}/#/?stage=${o.stage}`) : () => goTo(props.link)} className="count" key={o.order}>
                                <h5 className="display-4">{displayQuantity(o.total as number)}</h5>
                                <h6 className="h6 text-uppercase mb-2">{o.status}</h6>
                            </button>)
                    }
                </div>
            }
        </div>
    );
}



