import React from 'react'
import ControlInputField from '../../../Shared/components/ControlInputField';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';
import { IPutUserDto, IUserDetailDto } from 'appDtos';
import ControlCheckboxField from '../../../Shared/components/ControlCheckboxField';
import { Mode } from '../../../Shared/constant';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import HeadingElement from '../../../Shared/components/HeadingElement';
import { SubmitButton } from '../../../Shared/components/NavigationButton';

export default function UpdateUser(props: { user: IUserDetailDto, setMode: (mode: Mode) => any }) {
  const axios = useApi();
  const { errorMessage, setError } = useApiHandleError();
  const { id, isActive, firstName, lastName, middleInitial, email, phone } = props.user;
  const {
    handleOnChange,
    handleFieldError,
    setIsProcessing,
    formData,
    isProcessing,
    hasError } = useFormProcessor<IPutUserDto>(
      {
        id,
        isActive,
        firstName,
        lastName,
        middleInitial,
        email,
        confirmedEmail: '',
        phone
      });

  const hasChangedEmail = email !== formData.email;

  const handleSubmit = async () => {
    setIsProcessing(true);

    try {
      if (!hasChangedEmail) {
        delete formData.email;
        delete formData.confirmedEmail;
      }
      await axios.put('User', formData);
      props.setMode(Mode.DETAIL);
    } catch (error: any) {
      setError(error)
    }
    setIsProcessing(false);
  }

  return (
    <>
      <HeadingElement text="Update User Information" />
      {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
      <form className="p-3">
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="First Name"
          placeholder="Enter user's first name."
          id="firstName"
          value={formData.firstName}
          name="firstName"
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Middle Initial"
          placeholder="Enter user's middle initial."
          id="middleInitial"
          value={formData.middleInitial?.toUpperCase()}
          name="middleInitial"
          type="text"
          validationFunction={(v: any) => v ? v.length <= 1 && !Number(v) : true}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Last Name"
          placeholder="Enter user's last name."
          id="lastName"
          value={formData.lastName}
          name="lastName"
          type="text"
          required={true}
          validationFunction={hasValue}
        />
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Email Address"
          placeholder="Enter user's email address."
          id="email"
          value={formData.email}
          name="email"
          type="text"
          required={true}
          validationFunction={isValidEmail}
        />
        {hasChangedEmail && <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Confirm Address"
          placeholder="Re-enter user's email address."
          id="confirmedEmail"
          value={hasChangedEmail ? formData.confirmedEmail: ''}
          name="confirmedEmail"
          type="text"
          required={true}
          validationFunction={(value: any) => hasValue(value) && value === formData.email}
        />}
        <ControlInputField
          onChange={handleOnChange}
          onFieldError={handleFieldError}
          label="Primary Phone"
          placeholder="Enter user's primary phone if provided."
          id="phone"
          value={formData.phone}
          name="phone"
          type="text"
        />
        <ControlCheckboxField
          onChange={handleOnChange}
          label="Is Active"
          id="isActive"
          checked={formData.isActive}
          name="isActive"
        />
        <div className="page-action-button-group">
          <button onClick={() => props.setMode(Mode.DETAIL)} className="btn btn-secondary fw-bold m-1">Cancel</button>
          <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing || hasError} />
        </div>
      </form>
    </>
  );
}
