import React from 'react'
import "./headingElement.css";

export default function HeadingElement({ text }: { text: string }) {
    return (
        <>
            <div className="heading-styles theme-dark-color noPrint">
                {text}
            </div>
            <h5 className="noScreen p-2 mt-3 border border-1">
                {text}
            </h5>
        </>
    )
}
