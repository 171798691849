import React, { useState } from 'react'
import Loading from '../components/Loading';

export function useLoader() {
    const [isLoading, setIsLoading] = useState(false);

    const renderLoader = (options?: any) => isLoading ? <Loading loadingText={options?.text ?? "Processing..."} position={options?.position ?? "center"} />: null;

    return {isLoading, setIsLoading, renderLoader};
}
