import { AppFilterContextProvider } from '../../Shared/contexts/AppFilterContextProvider';
import { DailyLoadingAnalysis } from './DailyLoadingAnalysis';
import { Importation } from './Importation';
import { ImporterStockBalance } from './ImporterStockBalance';
import { LoadingDifferencesReport } from './LoadingDifferencesReport';
import { OvernightDifferencesReport } from './OvernightDifferencesReport';
import { ProductMovementReport } from './ProductMovementReport';
import { SalesByImporter } from './SalesByImporter';
import { SalesByTank } from './SalesByTank';

export const reportMapping: { [key: string]: JSX.Element } = {
    '0': <div>No report type Selected</div>,
    '1': <ImporterStockBalance/>,
    '2': <DailyLoadingAnalysis/>,
    '3': <Importation/>,
    '4': <SalesByImporter/>,
    '5': <SalesByTank/>,
    '6': <OvernightDifferencesReport/>,
    '7': <LoadingDifferencesReport/>,
    '8': <AppFilterContextProvider><ProductMovementReport/></AppFilterContextProvider>,
};
