import { IDistributorDetailDto, IUpdateDistributorRequest } from 'appDtos';
import React from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlCheckboxField from '../../../Shared/components/ControlCheckboxField';
import ControlInputField from '../../../Shared/components/ControlInputField';
import { SubmitButton } from '../../../Shared/components/NavigationButton';
import { hasValue, isValidEmail } from '../../../Shared/components/validators';
import { Mode } from '../../../Shared/constant';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

export function EditDistributor(props: { distributor: IDistributorDetailDto, setMode: (mode: Mode) => any }) {
    const axios = useApi();
    const { setError, errorMessage } = useApiHandleError();
    const {
        id,
        isActive,
        address,
        name,
        primaryEmail,
        primaryPhone,
        secondaryEmail,
        secondaryPhone,
        contactPerson,
        taxIdentificationNumber
    } = props.distributor;
    const { handleOnChange, handleFieldError, setIsProcessing, formData, isProcessing } = useFormProcessor<IUpdateDistributorRequest>({
        id,
        address,
        isActive,
        name,
        primaryEmail,
        primaryPhone,
        secondaryEmail,
        secondaryPhone,
        contactPerson,
        taxIdentificationNumber
    });

    React.useEffect(() => {
        setError(undefined);
    }, [formData, setError]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            await axios.put('Distributor', formData);
            props.setMode(Mode.DETAIL);
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    return (
        <div className="">
            <div className="card-title border p-2 theme-dark-color text-light mx-0">Update Distributor</div>
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            <form className="p-3">
            <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Distributor Name"
                    placeholder="Enter name."
                    id="name"
                    value={formData.name}
                    name="name"
                    focusOnMount={true}
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Tax Identification Number"
                    placeholder="Enter Tax Identification Number."
                    id="taxIdentificationNumber"
                    value={formData.taxIdentificationNumber}
                    name="taxIdentificationNumber"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Full Address"
                    placeholder="Enter full address."
                    id="address"
                    value={formData.address}
                    name="address"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Primary Phone Number"
                    placeholder="Enter primary phone number."
                    id="primaryPhone"
                    value={formData.primaryPhone}
                    name="primaryPhone"
                    type="text"
                    required={true}
                    validationFunction={hasValue}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Secondary Phone Number"
                    placeholder="Enter secondary phone number."
                    id="primaryPhone"
                    value={formData.secondaryPhone}
                    name="secondaryPhone"
                    type="text"
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Primary Email"
                    placeholder="Enter primary email."
                    id="primaryEmail"
                    value={formData.primaryEmail?.trim()}
                    name="primaryEmail"
                    type="text"
                    required={true}
                    validationFunction={isValidEmail}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Secondary Email"
                    placeholder="Enter secondary email."
                    id="secondaryEmail"
                    value={formData.secondaryEmail?.trim()}
                    name="secondaryEmail"
                    type="text"
                    validationFunction={(v: any) => v ? isValidEmail(v) : true}
                />
                <ControlInputField
                    onChange={handleOnChange}
                    
                    onFieldError={handleFieldError}
                    label="Contact Person"
                    placeholder="Enter contact person."
                    id="contactPerson"
                    value={formData.contactPerson}
                    name="contactPerson"
                    type="text"
                />
                <ControlCheckboxField
                    onChange={handleOnChange}
                    label="Is Active"
                    id="isActive"
                    checked={formData.isActive}
                    name="isActive"
                />
                <div className="page-action-button-group">
                    <button onClick={() => props.setMode(Mode.DETAIL)} className="btn btn-secondary fw-bold m-1">Cancel</button>
                    <SubmitButton handleSubmit={handleSubmit} disabled={isProcessing} />
                </div>
            </form>
        </div>
    );
}
