import { CreateStorageTankRequest, IProductDto } from 'appDtos';
import React, { useState } from 'react';
import Alert, { AlertType } from '../../../Shared/components/Alert';
import ControlInputField from '../../../Shared/components/ControlInputField';
import RequiredText from '../../../Shared/components/RequiredText';
import { hasValue } from '../../../Shared/components/validators';
import useApi from '../../../Shared/hooks/useApi';
import { useApiHandleError } from '../../../Shared/hooks/useApiHandleError';
import useFormProcessor from '../../../Shared/hooks/useFormProcessor';

export function CreateStorageTank() {
    const axios = useApi();
    const [success, setSuccess] = useState(false);
    const { setError, errorMessage } = useApiHandleError();
    const [products, setProducts] = React.useState<IProductDto[]>([]);
    const ref = React.useRef(false);
    const {
        handleOnChange, handleFieldError, setIsProcessing, reset, formData, isProcessing, hasError } = useFormProcessor<CreateStorageTankRequest>({ number: 0, productId: 0 }, { number: false, productId: false });

    React.useEffect(() => {
        if (!ref.current) {
            const fetch = async () => {
                const result = await axios.get<IProductDto[]>('Product');
                if (result?.data) {
                    setProducts(result?.data);
                }
            }

            fetch();
        }

        return () => {
            ref.current = true;
        };
    }, [axios, products]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            const response = await axios.post('StorageTank', formData);
            if (response?.data) {
                reset();
                setSuccess(true);
            }
        } catch (error: any) {
            setError(error);
        }
        setIsProcessing(false);
    };

    const handleProductChange = (product: IProductDto) => {
        handleOnChange({ productId: product.id });
    }
    
    return (
        <div className="">
            {!!errorMessage && <Alert type={AlertType.DANGER} message={errorMessage} />}
            {success && <Alert type={AlertType.SUCCESS} message="Storage Tank successfully added." />}
            <form className="p-3">
                <ControlInputField
                    onChange={handleOnChange}
                    autoComplete="off"
                    onFieldError={handleFieldError}
                    label="Storage Tank Number"
                    placeholder="Enter storage tank number."
                    id="number"
                    value={formData.number}
                    name="number"
                    focusOnMount={true}
                    type="text"
                    required={true}
                    validationFunction={hasValue} />
                <div className="fw-bold">Select Product <RequiredText /></div>
                <div className="list-group mb-3">
                    {products?.map(product => (
                        <label className="list-group-item mr-2" key={product.name}>
                            <input onChange={() => handleProductChange(product)} className="form-check-input me-1 mr-3" type="checkbox" checked={product.id === Number(formData.productId)} value="" />
                            {product.name}
                        </label>
                    ))}
                </div>
                <button onClick={handleSubmit} type="button" disabled={isProcessing || hasError} className="btn btn-warning fw-bold">Submit</button>
            </form>
        </div>
    );
}


