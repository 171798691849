import React from 'react';
import { faPaperPlane, faRotateBack, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Icon from './Icon';

export interface NavigationButtonProps {
    link: string;
    icon: IconDefinition;
    label: string;
}

export function NavigationButton(props: NavigationButtonProps) {
    const goTo = useNavigate();
    return (
        <button
            onClick={() => goTo(props.link)}
            className="btn btn-warning fw-bold navigation-button">
            <Icon icon={props.icon} /> {props.label}
        </button>
    );
}

export function BackButton({ disabled }: { disabled: boolean }) {
    const goTo = useNavigate();
    return (
        <button
            disabled={disabled}
            onClick={() => goTo(-1)}
            className="btn btn-secondary fw-bold m-1">
            <Icon icon={faRotateBack} color="white" /> Go Back
        </button>
    );
}

export function SubmitButton({ handleSubmit, disabled }: { disabled?: boolean, handleSubmit: () => void }) {
    return (
        <button
            disabled={disabled ?? false}
            type="submit"
            onClick={handleSubmit}
            className="btn btn-warning fw-bold m-1">
            <Icon icon={faPaperPlane} /> Submit
        </button>
    );
}
